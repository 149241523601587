import React from "react";
import * as ACTIONS from "../store/actions/actions";
import { connect } from "react-redux";
import ModalLay from "./ModalLay/ModalLay";
import { Crud_Catalogos, Crud_error } from "../herramientas/Crud_Catalogos";
import {
  Form,
  Grid,
  Card,
  Table,
  Alert,
  Icon,
  Dimmer,
  Button,
} from "tabler-react";
import { ImportarN,ImportarNJson, Exportar, Plantilla, Manual } from "../herramientas/Excel";
import Select from "react-select";
import { NotificationManager} from 'react-notifications';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import MonitoreoMensajeria from "../socket/MonitoreoMensajeria.react";
import EtaCalculada from "../Monitoristas/Monitoreo/panelForms/EtaCalculada";
import IncompletosTerminados from "./IncompletosTerminados";
import MovimientosToExcel from "./MovimientosToExcel";
import { timeToMilisegundos, timeString } from "../herramientas/DateFormat";
import classes from "./Especifico.module.css";
let colum = [];
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}
let movsArray = {};
let avisosEstatusNav= localStorage.getItem("avisosEstatusNav")? {...JSON.parse(localStorage.getItem("avisosEstatusNav"))}: {};
class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: [],
      tableheaderItems: [],
      test: this.categorias_object,
      hiddenModals: false,
      readyData: false,
      message: "",
      ErrorMessage: [],
      showErrormessage: false,
      selectedRow: -1,
      alertasN: {},
      gpsN: {},
      movsGpsEstatus: {},
      plataforma: { value: 0, label: "Todas ...", search: "" },
      funcionalidadesCol: [],
      search: "",
      textBuscar: "",
      movimientoAcumuladores: {},
      colToFilter: "po", //columna a filtrar
      AscDesc: true,
      metodo: "get",
      topics: [],
      excelRender: false,
      movActToExpor: [],
      Navigator: false,
    };
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 1,
    };
    this.colToFilter = "po";
    this.AscDesc = true;
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    //this.colum = this.props.colum | [];
    this.registro = [];
    this.columna = [];
    this.fila = [];
    this.campo = [];
    this.get_Store_Elements = null; //this.props.plataformas_object;//update_Store_Elements
    this.update_Store_Elements = null; //this.props.plataformas_update;
    this.getMensage = false;
    this.plataformas = [];
    this.movimientoAcumuladores = {};
    this.mensaje = {
      mensaje: "",
      plataforma: "",
      corredor: "",
      vista: "",
      data: null,
      accion: "get",
    };
    this.usuariosCorredores = [];
    this.movPatio = [];
  }

  hideNav = () => {
    this.setState((prev) => ({
      Navigator: !prev.Navigator,
    }));
  };

  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };

  estiloRenglon(elemento, index) {
    if (this.state) {
      if (this.state.selectedRow === index) {
        //si el movimiento esta seleccionado lo pone verde
        return { "background-color": "rgba(95, 231, 50, 0.30)" };
      }
    }
    try {
      var tieneGPS;
      this.state.gpsN[elemento.id] ? (tieneGPS = true) : (tieneGPS = false);
    } catch (e) {
      //console.log("e-gpsN");
    }
    try {
      if (!tieneGPS) {
        elemento.movimientoGps.length ? (tieneGPS = true) : (tieneGPS = false);
      }
      if (!tieneGPS) {
        this.props.movPatio[elemento.id].esFull
          ? (tieneGPS = true)
          : (tieneGPS = false);
      }
    } catch (e) {
      // return { "background-color": "rgba(245, 231, 50, 0.50)" };
    }
    var json = JSON.stringify(elemento.movimientoGps);
    if (
      json.search('"nombre":"PENDIENTE"') >
      0 /*&& !elemento.movimientoGps.length*/
    ) {
      elemento.movimientoGps.length >= 2
        ? (tieneGPS = true)
        : (tieneGPS = false);
    }

    var dt = new Date(elemento.citaProgramada);
    if (dt.getTime()) {
      //si tiene cita programada
      if (dt < Date.now()) {
        //si la cita programada ya pasó
        if (!tieneGPS) {
          //si no tiene gps
          return { "background-color": "rgba(255, 20, 20, 0.35)" };
        } else {
          //si sí tiene GPS  lo pone azul
          try {
            if (
              elemento.arriboTerminalVacio == null &&
              elemento.arriboClienteLleno == null &&
              elemento.arriboDirecionCarga == null &&
              elemento.arriboClienteVacio == null &&
              elemento.arriboTerminalCargado == null
            ) {
              return { "background-color": "rgba(100, 233, 231, 0.20)" };
            }
            //
          } catch (e) {
            console.log("e314");
          }
        }
      }
      if (!tieneGPS) {
        if (
          dt.setHours(dt.getHours() - 3) > Date.now() &&
          dt.setHours(dt.getHours() - 6) < Date.now()
        ) {
          //si faltan de 3 a 6 horas para que se cumpla la cita programada y no tiene gps
          return { "background-color": "rgba(255, 20, 20, 0.15)" };
        }
        if (dt.setHours(dt.getHours() - 3) < Date.now()) {
          //si falta una hora para que se cumpla la cita programada y no tiene gps
          return { "background-color": "rgba(255, 20, 20, 0.25)" };
        }
        if(elemento.probabilidadReaccion){
          return { "background-color": "rgba(244, 77, 0, 0.95)" };
        }
      }
    }

    if (elemento.reaccion) {
      //El movimiento requiere !!!REACCION
      return { "background-color": "rgba(255, 20, 20, 0.80)" };
    }

    return;
  }

  componentDidUpdate = () => {
    if (this.props.idToFilter) {
      this.sortElements("id");
      this.setState({ search: this.props.idToFilter.toString() });
      this.props.changeColToFilter(0);
    }
    if (this.state.elementos !== this.props.movimientos_naviera_object) {
      //this.props.setMovimientosNavieros(this.state.elementos);
      let topics = JSON.parse(JSON.stringify(this.state.topics));
      for (let m of this.props.movimientos_naviera_object) {
        if (m.movimientoGps) {
          for (let g of m.movimientoGps) {
            let exist = false;
            for (let t of this.state.topics) {
              if (t === "/topic/gps" + "-" + g.gps.idGg) {
                exist = true;
              }
            }
            if (!exist) {
              topics.push("/topic/gps" + "-" + g.gps.idGg);
            }
          }
        }
      }
    }
    if (this.props.alertasN !== this.state.alertasN) {
      this.setState({ alertasN: this.props.alertasN });
    }
    if (this.props.gpsNavieroReciente !== this.state.gpsN) {
      this.setState({ gpsN: this.props.gpsNavieroReciente });
    }
    if (this.props.movsGpsEstatus !== this.state.movsGpsEstatus) {
      this.setState({ movsGpsEstatus: this.props.movsGpsEstatus });
    }
    if (this.state.funcionalidadesCol !== this.props.columnas) {
      this.setState({ funcionalidadesCol: this.props.columnas });
      this.table_headers(true);
    }

    if (this.usuariosCorredores !== this.props.usuariosCorredores) {
      this.usuariosCorredores = this.props.usuariosCorredores;
      let topics = JSON.parse(JSON.stringify(this.state.topics));
      for (let item of this.props.usuariosCorredores) {
        topics.push("/topic/" + item.idPlataforma + "-" + item.idCorredor);
      }
      this.setState({ topics: topics });
    }
    if (this.movPatio !== this.props.movPatio) {
      this.movPatio = this.props.movPatio;

      var dataTemp = [];
      if (this.state.elementos) {
        this.state.elementos.map((filas, index) => {
          Object.keys(filas).forEach((key) => {
            if (!dataTemp[index]) {
              dataTemp[index] = {};
            }
            if (key !== "movimientoGps" && key !== "incidencia") {
              dataTemp[index][key] = [];
            }
            if (filas[key] !== null) {
              if (typeof filas[key] === "object") {
                switch (key) {
                  case "persona":
                    dataTemp[index][key] =
                      "" +
                      filas[key].nombre +
                      " " +
                      filas[key].aPaterno +
                      " " +
                      filas[key].aMaterno;
                    break;
                  case "movimientoGps":
                    filas[key].map((gps, indice) =>{
                        dataTemp[index]["gps-" + gps.gpsTipo.nombre] = gps.gps.nombre;
                        dataTemp[index]["gps(Username)-" + gps.gpsTipo.nombre] = gps.gps.nombreUsuario;
                    });
                    break;
                  case "incidencias":
                    break;
                  case "idCliente":
                    dataTemp[index][key] = filas[key].nombre;
                    dataTemp[index]["Dirección cliente"] = filas[key].domicilio;
                    break;
                  default:
                    if (filas[key].nombre !== "" && filas[key].nombre) {
                      dataTemp[index][key] = filas[key].nombre;
                    } else {
                      if (filas[key].id !== "" && filas[key].id) {
                        dataTemp[index][key] = filas[key].id;
                      } else {
                        dataTemp[index][key] = "Vari(@)s ";
                      }
                    }
                }
              } else {
                dataTemp[index][key] = filas[key];
              }
            } else {
              dataTemp[index][key] = "";
            }
          });
        });
      }
      if (this.state.elementos) {
        this.state.elementos.map((mov, index) => {
          if (movsArray[mov.id]) {
            if (movsArray[mov.id]["movimientoGps"]) {
              if (movsArray[mov.id]["movimientoGps"].length === 0) {
                if (this.props.movPatio[mov.id]) {
                  if (
                    movsArray[parseInt(this.props.movPatio[mov.id].esFull, 10)]
                  ) {
                    movsArray[parseInt(this.props.movPatio[mov.id].esFull, 10)][
                      "movimientoGps"
                    ].map((gps, indice) =>{
                      dataTemp[index]["gps-" + gps.gpsTipo.nombre] = gps.gps.nombre + ".";
                      dataTemp[index]["gps(Username)-" + gps.gpsTipo.nombre] = gps.gps.nombreUsuario + ".";
                    });
                  }
                }
              } else {
                //si el gps es full, buscar en el otro mov un gps distinto de full
                if (movsArray[mov.id]["movimientoGps"].length === 1) {
                  //primero validamos que solo tenga un gps
                  if (
                    movsArray[mov.id]["movimientoGps"].filter((word) =>
                      word.gps.nombre.toUpperCase().includes("FULL")
                    ).length === 1
                  ) {
                    //detectamos si hay full
                    if (this.props.movPatio[mov.id]) {
                      if (
                        movsArray[
                          parseInt(this.props.movPatio[mov.id].esFull, 10)
                        ]
                      ) {
                        movsArray[
                          parseInt(this.props.movPatio[mov.id].esFull, 10)
                        ]["movimientoGps"].map((gps, indice) =>{
                          dataTemp[index]["gps-" + gps.gpsTipo.nombre] = gps.gps.nombre + ".";
                          dataTemp[index]["gps(Username)-" + gps.gpsTipo.nombre] = gps.gps.nombreUsuario + ".";
                      });
                      }
                    }
                  }
                }
              }
            }
          }
          if (this.props.movPatio[mov.id]) {
            dataTemp[index]["full"] = this.props.movPatio[mov.id].esFull;
            dataTemp[index]["operador"] =
              this.props.movPatio[mov.id].operadorNombre;
            dataTemp[index]["telefono"] =
              this.props.movPatio[mov.id].operadorContacto;
            dataTemp[index]["Placa tractor"] =
              this.props.movPatio[mov.id].tractorPlaca;
            dataTemp[index]["Placa remolque"] =
              this.props.movPatio[mov.id].remolquePlaca;
            dataTemp[index]["economicor"] =
              this.props.movPatio[mov.id].economicor;
            dataTemp[index]["economicot"] =
              this.props.movPatio[mov.id].economicot;
          }
        });
      }
      this.setState({ movActToExpor: dataTemp });
    }
  };

  updateMessage = (sms) => {
    this.setState({ message: sms });
  };

  componentDidMount = () => {
    if (this.catalogo !== "" || this.catalogo !== null) {
      Object.keys(this.props.catalogos).forEach((key) => {
        if (this.catalogo === this.props.catalogos[key]) {
        }
        switch (this.props.catalogos[key]) {
          case "navieraMovimientos": {
            this.update_Store_Elements = this.props.movimientos_naviera_update;
            if (this.props.mtipo === 0) {
              this.getfuntion(
                "get",
                [],
                "",
                [],
                this.props.catalogos[key] + "/usuario/" + user.id,
                this.update_Store_Elements
              );
            }
            if (this.props.mtipo > 0) {
              this.getfuntion(
                "get",
                [],
                "",
                [],
                this.props.catalogos[key] +
                  "/usuario/" +
                  user.id +
                  "/" +
                  this.props.mtipo,
                this.update_Store_Elements
              );
            }
            /*
                switch (this.props.mtipo) {
                  case 0:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                    break;
                  case 1:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id+"/"+this.props.mtipo, this.update_Store_Elements);
                    break;
                  case 2:
                    this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id+"/"+this.props.mtipo, this.update_Store_Elements);
                    break;
                  default:
                    break;
                }*/
            //this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
            break;
          }
          case "movimientosTipo": {
            this.update_Store_Elements = this.props.movimientosTipo_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "clientes": {
            this.update_Store_Elements = this.props.clientes_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              "catalogos/" + this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "navieras": {
            this.update_Store_Elements = this.props.navieras_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "localidades": {
            this.update_Store_Elements = this.props.localidades_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "otd": {
            this.update_Store_Elements = this.props.otd_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "plataformas": {
            this.update_Store_Elements = this.props.plataformas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "rutasEstatus": {
            this.update_Store_Elements = this.props.rutasEstatus_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "transportistas": {
            this.update_Store_Elements = this.props.transportistas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              "catalogos/" + this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "etiquetas":
            //if (!this.estaPrivilegioActivo("etiquetas_listar")) break;
            this.update_Store_Elements = this.props.etiquetas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          case "funcionalidades": {
            this.update_Store_Elements = this.props.funcionalidades_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "privilegios": {
            this.update_Store_Elements = this.props.privilegios_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesEstatus": {
            this.update_Store_Elements = this.props.operacionesEstatus_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesTipo": {
            this.update_Store_Elements = this.props.operacionesTipo_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operadores": {
            this.update_Store_Elements = this.props.operadores_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "incidencias": {
            if (!this.estaPrivilegioActivo("incidencias_listar")) break;
            this.update_Store_Elements = this.props.incidencias_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }

          default:
            return;
        }
      });
    }
    this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
    currentUser.map((usr, index) => {
      if (
        usr.plataforma.plataformaTipo.nombre.toUpperCase().includes("NAVIERA")
      ) {
        this.plataformas.push({
          value: usr.plataforma.id,
          label: usr.plataforma.nombre,
          search: usr.plataforma.nombre,
        });
      }
    });
  };

  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };

  getfuntion = (
    metodo = "get",
    params = [],
    id = "",
    arraytoUpdate = [],
    catalogoTem = this.catalogo,
    storeFunction = this.update_Store_Elements
  ) => {
    this.usuariosLogObject.idMovimiento = params;
    let objTemp = params;
    if (catalogoTem !== "") {
      Crud_Catalogos(
        catalogoTem,
        this.catalogo,
        metodo,
        id,
        params,
        storeFunction,
        this.state.elementos,
        this.hiddenmodal,
        arraytoUpdate
      )
        .then((returnVal) => {
          this.mensaje.accion = metodo;
          this.mensaje.vista = catalogoTem;
          this.setState({ metodo: metodo });
          switch (metodo) {
            case "get":
              {
                if (
                  catalogoTem.includes(this.catalogo + "/usuario/" + user.id)
                ) {
                  this.updateStatus_elementos();
                  this.setState({ readyData: true });
                  setTimeout(() =>{
                    this.mostrarAlertas5min();
                    this.setState({ excelRender: true });
                  }, 1000 * 1 );
                }
              }
              break;
            case "post":
              {
                this.mensaje.data = returnVal;
                this.mensaje.corredor = returnVal.idCorredor.id;
                this.mensaje.plataforma = returnVal.idPlataforma.id;
                this.mensaje.mensaje =
                  "Nuevo movimiento (" +
                  returnVal.id +
                  ", plataforma: " +
                  returnVal.idPlataforma.nombre +
                  ")";
                this.props.updateStatusMensaje(true);
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion = "Nuevo Movimiento";
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject
                );
              }
              break;
            case "put":
              {
                this.mensaje.data = returnVal.data;
                this.mensaje.corredor = returnVal.data.idCorredor.id;
                this.mensaje.plataforma = returnVal.data.idPlataforma.id;
                this.mensaje.mensaje =
                  "Actualización (" +
                  returnVal.data.id +
                  ", plataforma: " +
                  returnVal.data.idPlataforma.nombre +
                  ")";
                this.props.updateStatusMensaje(true);
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = objTemp.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  "Actualización de Movimiento";
                /*
              if (objTemp.idOperacionEstatus.id === 1) {
                this.usuariosLogObject.idBitacoraMonitoreo = 5;
                this.usuariosLogObject.descripcion = "Se finaliza movimiento";
              }*/
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject
                );
              }
              break;
            case "delete":
              {
                this.usuariosLogObject.idBitacoraMonitoreo = 10;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.descripcion =
                  "Delete: id:" + returnVal.id;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject
                );
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log("Error: " + err);
          if (err.response) {
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          } else {
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(1, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
        });
    }
  };
  updateStatus_elementos = () => {
    this.setState({ elementos: this.props.movimientos_naviera_object });
    this.props.setMovimientosNavieros(this.props.movimientos_naviera_object);
    movsArray = {};
    this.props.movimientos_naviera_object.map((m, inx) => {
      if (!movsArray[m.id]) {
        movsArray[m.id] = m;
      }
    });
  };
  table_headers = (asc) => {
    this.content = [];
    Object.keys(this.props.columnas).forEach((key, index) => {
      this.content.push({
        content: (
          <div
            onClick={() => {
              this.sortElements(this.props.columnas[index].nombre);
            }}
          >
            {this.props.columnas[index].label}
            {this.colToFilter === this.props.columnas[index].nombre ? (
              <Icon name={asc ? "chevron-up" : "chevron-down"} />
            ) : null}
          </div>
        ),
      });
    });
    this.setState({ tableheaderItems: this.content });
  };
  sortElements = (columna) => {
    let oldCol = this.colToFilter;
    if (this.colToFilter == columna) {
      this.AscDesc = !this.AscDesc;
    } else {
      this.AscDesc = true;
    }
    this.colToFilter = columna;
    this.setState({ colToFilter: columna });
    if (oldCol == columna) {
      this.table_headers(this.AscDesc);
    } else {
      this.table_headers(true);
    }

    var originalArray = this.state.elementos;
    if (!originalArray[0]) {
      return;
    }
    if (this.AscDesc) {
      originalArray = originalArray.sort(function (a, b) {
        if (
          typeof a[columna] === "object" &&
          typeof b[columna] === "object" &&
          a[columna] !== null &&
          b[columna] !== null
        ) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre.trim() > b[columna].nombre.trim()) {
            return 1;
          }
          if (a[columna].nombre.trim() < b[columna].nombre.trim()) {
            return -1;
          }
          return 0;
        } else {
          if (columna === "incidencia") {
            //ordena las incidencias por fecha
            if (a[columna] === null && b[columna] !== null) {
              return 1;
            } // si es nulo lo ordena al final
            if (a[columna] !== null && b[columna] === null) {
              return -1;
            } // si es nulo lo ordena al final
            if (a[columna] === null && b[columna] === null) {
              return 0;
            } // si es nulo lo ordena al final
            if (
              new Date(a[columna].trim().substr(0, 19)).getTime() >
              new Date(b[columna].trim().substr(0, 19)).getTime()
            ) {
              return 1;
            }
            if (
              new Date(a[columna].trim().substr(0, 19)).getTime() <
              new Date(b[columna].trim().substr(0, 19)).getTime()
            ) {
              return -1;
            }
            return 0;
          } else {
            if (
              a[columna] === null ||
              b[columna] === null ||
              (typeof a[columna] === "undefined" &&
                typeof b[columna] === "undefined")
            ) {
              return -1;
            }
            if (a[columna].toString().trim() > b[columna].toString().trim()) {
              return 1;
            }
            if (a[columna].toString().trim() < b[columna].toString().trim()) {
              return -1;
            }
            return 0;
          }
        }
      });
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function (a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function (a, b) {
          if (columna === "incidencia") {
            //ordena las incidencias por fecha
            if (a[columna] === null && b[columna] !== null) {
              return 1;
            } // si es nulo lo ordena al final
            if (a[columna] !== null && b[columna] === null) {
              return -1;
            } // si es nulo lo ordena al final
            if (a[columna] === null && b[columna] === null) {
              return 0;
            } // si es nulo lo ordena al final
            if (
              new Date(a[columna].trim().substr(0, 19)).getTime() <
              new Date(b[columna].trim().substr(0, 19)).getTime()
            ) {
              return 1;
            }
            if (
              new Date(a[columna].trim().substr(0, 19)).getTime() >
              new Date(b[columna].trim().substr(0, 19)).getTime()
            ) {
              return -1;
            }
            return 0;
          } else {
            if (a[columna] === null) {
              return -1;
            }
            if (a[columna] < b[columna]) {
              return 1;
            }
            if (a[columna] > b[columna]) {
              return -1;
            }
            return 0;
          }
        });
      }
      //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ elementos: originalArray });
  };
  isobject = () => {
    if (typeof this.columna === "object") {
      if (this.columna.length > 1) {
        switch (
          this.campo /*
            case "incidencias":
              let ultimaIncidencia= this.columna.sort(function (a, b) {
                if (a.id > b.id) {
                  return 1;
                }
                if (a.id < b.id) {
                  return -1;
                }
                return 0;
              })[this.columna.length-1];
              this.colum.push(<Table.Col><span>{ ultimaIncidencia.incidencia["nombre"]+ " ..." }</span><span>{ ultimaIncidencia.fechaCreado }</span></Table.Col>);
              break;*/
        ) {
          default:
            this.colum.push(
              <Table.Col>
                {this.columna[this.columna.length - 1]["nombre"] + " ..."}
              </Table.Col>
            );
            break;
        }
      } else {
        switch (this.campo) {
          case "username":
            this.colum.push(<Table.Col>{this.columna["username"]}</Table.Col>);
            break;
          case "idOtd":
            this.colum.push(
              <Table.Col>
                {timeToMilisegundos(this.registro.delay) > 0 ? (
                  <div
                    className="badge bg-secondary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={this.columna["nombre"]}
                  >
                    <Icon name="message-square" />
                  </div>
                ) : (
                  ""
                )}
              </Table.Col>
            );
            break;

          default:
            this.colum.push(<Table.Col>{this.columna["nombre"]}</Table.Col>);
            break;
        }
      }
    }
  };

  searching = (event) => {
    this.setState({ search: event.target.value });
  };

  changePlataformas = (event) => {
    this.setState({ plataforma: event });
  };
  objectFilter = (columna) => {
    if (columna) {
      if (columna.nombre) {
        return columna.nombre;
      } else {
        if (typeof columna === "object") {
          if (columna.length) {
            switch (this.colToFilter) {
              default:
                if (this.colToFilter) {
                  return columna[columna.length - 1]["nombre"];
                } else {
                  return "";
                }
            }
          } else {
            return "";
          }
        } else {
          return columna;
        }
      }
    } else {
      return "";
    }
  };
  getFuncionalidad = (elemento) => {
    if (this.state.elementos.length > 0) {
      let movActivo = this.state.elementos
        .filter(
          (word) =>
            word.idPlataforma.nombre.includes(this.state.plataforma.search) &&
            (!Number.isInteger(word[this.colToFilter]) //Si se filtra texto
              ? this.objectFilter(word[this.colToFilter])
                  .toUpperCase()
                  .includes(this.state.search.toUpperCase())
              : this.state.search != ""
              ? parseInt(word[this.colToFilter], 10) ===
                parseInt(this.state.search, 10)
                ? true
                : false //Si se filtra numero o boolean
              : true)
        )
        .sort()[this.state.selectedRow];
      if (movActivo) {
        if (movActivo.idPlataforma) {
          if (this.props.PlataformasPerfiles[movActivo.idPlataforma.id]) {
            let funcionalidadesTemp =
              this.props.PlataformasPerfiles[movActivo.idPlataforma.id]
                .funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };

  getFuncionalidadGeneral = (elemento) => {
    var BreakException = {};
    var resultado = false;

    try {
      Object.keys(this.props.PlataformasPerfiles).forEach((key, indice) => {
        if (this.props.PlataformasPerfiles[key].funcionalidades) {
          for (let item of this.props.PlataformasPerfiles[key]
            .funcionalidades) {
            if (item.nombre == elemento) {
              resultado = true;
              throw BreakException;
            }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  };

  mostrarAlertas5min =()=>{
    if( this.state.elementos && this.state.elementos.length){
      avisosEstatusNav= localStorage.getItem("avisosEstatusNav")? {...JSON.parse(localStorage.getItem("avisosEstatusNav"))}: {};
      if( Object.keys(avisosEstatusNav).length ){
        Object.keys(avisosEstatusNav).forEach((m) => {
          const movimientos= [...this.state.elementos];
          if( movimientos.filter(m=> m.id === Number(m.id)).length === 0 ){
            delete avisosEstatusNav[m];
          }
          if( avisosEstatusNav[m] ){
            Object.keys(avisosEstatusNav[m]).forEach((a) => {
              if( !avisosEstatusNav[m][a].acuse ){
                this.crearAlerta(avisosEstatusNav[m][a]);
              }
            });
          }
        });
        localStorage.setItem("avisosEstatusNav", JSON.stringify(avisosEstatusNav));
      }
    }
    setTimeout(() => {
      this.mostrarAlertas5min();
    }, (1000*60*5));//cada 5 min
  }

  crearAlerta=( aviso )=>{
    if(!aviso.acuse)
      NotificationManager.warning(
        <div>
          <p> { aviso.texto } </p>
          <span className="text-primary btn"
            onClick={()=>{
              this.colToFilter= "id";
              this.setState({colToFilter: "id", search : aviso.idMov});
              avisosEstatusNav[aviso.idMov][aviso.aviso].acuse= true;
              localStorage.setItem("avisosEstatusNav", JSON.stringify(avisosEstatusNav));
            }}>
            Atender
          </span>
          <span className="text-primary btn" onClick={()=>{ 
            confirmAlert({
              title: "Atención",
              message:
                "Estoy enterado y ya se seguimiento al mov " + aviso.idMov+" " +aviso.texto ,
              buttons: [
                {
                  label: "Confirmar",
                  onClick: () => {
                    avisosEstatusNav[aviso.idMov][aviso.aviso].acuse= true;
                    localStorage.setItem("avisosEstatusNav", JSON.stringify(avisosEstatusNav));
                  },
                },
                {
                  label: "Cancelar",
                  onClick: () => {
                    return false;
                  },
                },
              ],
            });
            }}>
            Atendida
          </span>
          <span className="text-primary btn" //onClick={()=>{ alert("Estoy ignorando la alerta")}}
          >
            Ignorar
          </span>
        </div>,
        aviso.titulo,
        (1000*60*3));
  }
  getAvisos=(m)=>{
    let avisos= [];
    let avisosNuevos= false;
    const idMovTemp= m.id.toString();
    if( [1316].includes(m.idPlataforma.id) && m.citaProgramada ){
      let nuevoTemp= false;
      const tiempoTemp= new Date(m.citaProgramada).getTime() - new Date().getTime();
      if( tiempoTemp < (1000*60*60)+10000 ){//Se agregan 10 segndos para que no haya retraso al mostrar la alerta                        
        if(!avisosEstatusNav[idMovTemp]){//si no existe avisos para el mov se crea y se lanza la alerta
          avisosEstatusNav[idMovTemp]= {};
          avisosEstatusNav[idMovTemp]["citaProgramada"]= { idMov: m.id, aviso: "citaProgramada", acuse: false, titulo: "Cita Programada mov: " + m.id, texto: "Falta menos de una hora para que se cumpla Cita Programada: " + m.citaProgramada };
          avisosNuevos= true;
          nuevoTemp= true;
        }else{
          if(!avisosEstatusNav[idMovTemp]["citaProgramada"]){//si no existe aviso para cita programada se crea
            avisosEstatusNav[idMovTemp]["citaProgramada"]= { idMov: m.id, aviso: "citaProgramada", acuse: false, titulo: "Cita Programada mov: " + m.id, texto: "Falta menos de una hora para que se cumpla Cita Programada: " + m.citaProgramada };
            avisosNuevos= true;
            nuevoTemp= true;
          }//si existe aviso para citaProgramada  ya se notifico cuando se creo o se volvera a mostrar cuando se recargue pantalla
        }
        if(nuevoTemp){
          avisos.push(
            <span className="fa fa-calendar text-danger"
              data-toggle="tooltip"
              data-placement="top"
              title={ "Falta " + timeString( tiempoTemp) + "para que se cumpla Cita Programada)" }
              style={{ "font-size": "200%", }}
            ></span>); 
          this.crearAlerta(avisosEstatusNav[idMovTemp]["citaProgramada"]);
        }
      }
    }
    if([1316].includes(m.idPlataforma.id)){
      let nuevoTemp= false;
      try {
        const variables= JSON.parse(m.variables);
        if(variables && variables["inicio_de_carga"] && variables["inicio_de_carga"] !== ""){
          const tiempoTemp= new Date(variables["inicio_de_carga"]).getTime() - new Date().getTime();
          if( tiempoTemp < (1000*60*60)+10000 ){//Se agregan 10 segndos para que no haya retraso al mostrar la alerta            
            if(!avisosEstatusNav[idMovTemp]){//si no existe avisos para el mov se crea y se lanza la alerta
              avisosEstatusNav[idMovTemp]= {};
              avisosEstatusNav[idMovTemp]["inicio_de_carga"]= { idMov: m.id, aviso: "inicio_de_carga", acuse: false, titulo: "Cita de carga mov: " + m.id, texto: "Falta menos de una hora para que se cumpla Cita de carga: " + variables["inicio_de_carga"] };
              avisosNuevos= true;
              nuevoTemp= true;
              
            }else{
              if(!avisosEstatusNav[idMovTemp]["inicio_de_carga"]){//si no existe aviso para cita programada se crea
                avisosEstatusNav[idMovTemp]["inicio_de_carga"]= { idMov: m.id, aviso: "inicio_de_carga", acuse: false, titulo: "Cita de carga mov: " + m.id, texto: "Falta menos de una hora para que se cumpla Cita de carga: " + variables["inicio_de_carga"]  };
                avisosNuevos= true;
                nuevoTemp= true;
              }//si existe aviso para citaProgramada  ya se notifico cuando se creo o se volvera a mostrar cuando se recargue pantalla
            }
            if(nuevoTemp){
              avisos.push(
                <span className="fa fa-calendar text-warning"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={ "Falta " + timeString( tiempoTemp) + "para que se cumpla Inicio de carga)" }
                  style={{ "font-size": "200%", }}
                ></span>);
              this.crearAlerta(avisosEstatusNav[idMovTemp]["inicio_de_carga"]);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    if( m.idMovimientoTipo.id === 1
      && m.arriboClienteLleno
      && m.salidaClienteVacio
    ){
      const tiempoTemp= new Date(m.salidaClienteVacio).getTime() - new Date(m.arriboClienteLleno).getTime();
      if( tiempoTemp < 1000 * 60 * 30 )
        avisos.push(
          <span
            className="fa fa-certificate text-danger"
            data-toggle="tooltip"
            data-placement="top"
            title={ "Estadia Corto(" + timeString( tiempoTemp ) + ")"}
            style={{ "font-size": "200%", }}
          ></span>
        );
      
    }
    if( m.idPlataforma.id !== 530
        &&  m.idMovimientoTipo.id === 2
        &&  m.arriboClienteVacio
        &&  m.salidaClienteLleno
    ){
      const tiempoTemp= new Date( m.salidaClienteLleno ).getTime() - new Date( m.arriboClienteVacio ).getTime();
      if( tiempoTemp < 1000 * 60 * 30  )
        avisos.push(
          <span
            className="fa fa-certificate text-danger"
            data-toggle="tooltip"
            data-placement="top"
            title={ "Estadia Corto(" + timeString( tiempoTemp ) + ")" }
            style={{ "font-size": "200%", }}
          ></span>
        );
    }
    if( avisosNuevos ){
      localStorage.setItem("avisosEstatusNav", JSON.stringify(avisosEstatusNav));
    }
    return avisos;
  }
  render() {
    return (
      <Grid.Col lg={12}>
        <div className="m-3 collapse">
          {this.state.elementos.length > 0 && this.state.topics.length > 0 && (
            <MonitoreoMensajeria
              topic={this.state.topics}
              mtipo={this.props.mtipo}
              mensaje={this.mensaje}
              elementosN={this.state.elementos}
              storeFunction={this.update_Store_Elements}
              update={this.updateStatus_elementos}
              openmodal={this.state.hiddenModals}
              metodo={this.state.metodo}
              stor={this.props}
            />
          )}
        </div>
        <i
          className={`${classes.icon} ${classes.static} ${
            this.state.Navigator ? "fa fa-angle-down" : "fa fa-angle-up"
          }`}
          style={{ cursor: "pointer" }}
          onClick={this.hideNav}
        />
        { [6,21].includes(user.id) ?
              <li
                className="badge"
                onClick={() => window.open(
                  "https://app.powerbi.com/view?r=eyJrIjoiN2E2OTMxYjctZjk1OC00MTQyLWIyYjAtMDU4ODAxNjdlM2U5IiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9",
                  "sharer",
                  "width=900,height=600,scrollbars=NO"
                )
              }
                data-toggle="tooltip"
                data-placement="top"
                title={"Powerbi Navieros"}
              >
                <img src="images/powerbi.png" width="30px" />
              </li>
          : null}
        {!this.state.Navigator && (
          <div
            className={`form-row align-items-center ${classes.jumb}`}
            style={{ marginRight: "0px", marginLeft: "0px" }}
          >
            <div
              class="col-2"
              hidden={this.plataformas.length && this.plataformas.length <= 2}
            >
              <Select
                value={this.state.plataforma}
                onChange={this.changePlataformas}
                options={this.plataformas}
              />
            </div>
            <div class="col-auto">
              <Form.Input
                className="lg-12"
                icon="search"
                value={this.state.search}
                placeholder={this.state.colToFilter}
                position="append"
                onChange={this.searching}
              />
            </div>
            {this.estaPrivilegioActivo("CargaMasiva") &&
              this.getFuncionalidadGeneral("Importar") &&
              this.state.excelRender && (
                <div class="col-auto">
                  <ImportarN crud={this.getfuntion} catalogo={this.catalogo} />
                </div>
              )}
            {this.estaPrivilegioActivo("CargaMasiva") &&
              this.getFuncionalidadGeneral("Importar") &&
              this.state.excelRender && (
                <div class="col-auto">
                  <ImportarNJson crud={this.getfuntion} catalogo={this.catalogo} />
                </div>
              )}
            {this.getFuncionalidadGeneral("Importar") &&
              this.state.excelRender && (
                <div class="col-auto">
                  <Plantilla crud={this.getfuntion} catalogo={this.catalogo} />
                </div>
              )}
            {this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender && (
                <div class="col-auto">
                  <Exportar
                    crud={this.getfuntion}
                    catalogo={this.catalogo}
                    data={this.state.movActToExpor}
                  />
                </div>
              )}
            {this.getFuncionalidadGeneral("Importar") &&
              this.state.excelRender && (
                <a>
                  {this.catalogo === "navieraMovimientos" && (
                    <div class="col-auto">
                      <Manual crud={this.getfuntion} catalogo={this.catalogo} />
                    </div>
                  )}
                </a>
              )}
            {false && this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender && (
                <li
                  className="nav-item active"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Exportar movimientos a excel"
                >
                  <a>
                    <div className="float-right" style={{ width: "45px" }}>
                      <ModalLay
                        tamanio={"85%"}
                        title="Terminados"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <div
                              class="fa fa-file-excel-o"
                              aria-hidden="true"
                            ></div>
                          </Button>
                        }
                        formulario={MovimientosToExcel}
                        datosForm={{
                          mtipo: this.props.mtipo,
                          columnas: this.props.columnas,
                          elementos: this.state.elementos,
                          plataformaTipo: "Naviera",
                          stor: this.props,
                          incompletos: true,
                        }}
                      />
                    </div>
                  </a>
                </li>
              )}
            {this.getFuncionalidadGeneral("Movimientos Completos") && (
              <li
                className="nav-item active "
                data-toggle="tooltip"
                data-placement="top"
                title="Movimientos terminados"
              >
                <a>
                  <ModalLay
                    tamanio={"85%"}
                    title="Terminados"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <Icon name="file-plus" />
                      </Button>
                    }
                    formulario={IncompletosTerminados}
                    datosForm={{
                      mtipo: this.props.mtipo,
                      columnas: this.props.columnas,
                      elementos: this.state.elementos,
                      closeModal: this.hiddenmodal,
                      plataformaTipo: "Naviera",
                      stor: this.props,
                      incompletos: false,
                    }}
                  />
                </a>
              </li>
            )}
            {this.getFuncionalidadGeneral("Movimientos Incompletos") && (
              <li
                className="nav-item active"
                data-toggle="tooltip"
                data-placement="top"
                title="Movimientos terminados con datos incompletos"
              >
                <a>
                  <ModalLay
                    tamanio={"85%"}
                    title="Terminados con datos incompletos"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <Icon name="file-minus" />
                      </Button>
                    }
                    formulario={IncompletosTerminados}
                    datosForm={{
                      mtipo: this.props.mtipo,
                      columnas: this.props.columnas,
                      elementos: this.state.elementos,
                      closeModal: this.hiddenmodal,
                      plataformaTipo: "Naviera",
                      stor: this.props,
                      incompletos: true,
                    }}
                  />
                </a>
              </li>
            )}
            {this.estaPrivilegioActivo("movimientos_agregar") &&
              this.getFuncionalidadGeneral("Agregar Movimiento") && (
                <li className="nav-item active">
                  <a>
                    <ModalLay
                      resumirModal={this.props.resumeModal}
                      funcionReanudar={this.props.functionReanudarModal}
                      title="Nuevo"
                      name={
                        <Button pill size="sm" outline color="primary">
                          <Icon name="plus" />
                        </Button>
                      }
                      tamanio={this.tamanio}
                      formulario={this.Catalogo_form}
                      datosForm={{
                        accion: "nuevo",
                        getfuntion: this.getfuntion,
                        stor: this.props,
                        Fclose: this.hiddenmodal,
                      }}
                    />
                  </a>
                </li>
              )}
          </div>
        )}

        <Card>
          <Card.Body>
            <Dimmer active={!this.state.readyData} loader>
              {this.state.tableheaderItems ? (
                <Table
                  cards={false}
                  striped={false}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={this.state.tableheaderItems}
                  style={{ color: "black", "font-size": "80%" }}
                >
                  <Table.Header></Table.Header>
                  <Table.Body>
                    {this.state.elementos ? ( //.filter(word => word.po.toUpperCase().includes(this.state.search.toUpperCase()) || word.booking.toUpperCase().includes(this.state.search.toUpperCase()) || word.contenedor.toUpperCase().includes(this.state.search.toUpperCase())).sort()
                      this.state.elementos
                        .filter(
                          (word) =>
                            word.idPlataforma.nombre.includes(
                              this.state.plataforma.search
                            ) &&
                            (!Number.isInteger(word[this.colToFilter]) //Si se filtra texto
                              ? this.objectFilter(word[this.colToFilter])
                                  .toUpperCase()
                                  .includes(this.state.search.toUpperCase())
                              : this.state.search != ""
                              ? parseInt(word[this.colToFilter], 10) ===
                                parseInt(this.state.search, 10)
                                ? true
                                : false //Si se filtra numero o boolean
                              : true)
                        )
                        .sort()
                        .map((elemento, index) => (
                          <Table.Row
                            style={this.estiloRenglon(elemento, index)}
                            onClick={() => {
                              this.props.setMovimiento(elemento);
                              this.setState({ selectedRow: index });
                            }}
                          >
                            {(this.colum = [])}
                            {this.state.funcionalidadesCol.map(
                              (columna, index) => {
                                if (
                                  typeof elemento[columna.columna] ===
                                    "object" &&
                                  elemento[columna.columna] !== null
                                ) {
                                  this.registro = elemento;
                                  this.campo = columna.columna;
                                  this.columna = elemento[columna.columna];
                                  this.fila = elemento;
                                  this.isobject();
                                } else {
                                  switch (columna.columna) {
                                    case "estatus":
                                      this.colum.push(
                                        <Table.Col>
                                          {
                                            <Form.Switch
                                              type="Checkbox"
                                              checked={
                                                elemento[columna.columna]
                                              }
                                            />
                                          }
                                        </Table.Col>
                                      );
                                      break;
                                    case "vip":
                                      this.colum.push(
                                        <Table.Col>
                                          {
                                            <Form.Switch
                                              type="Checkbox"
                                              checked={
                                                elemento[columna.columna]
                                              }
                                            />
                                          }
                                        </Table.Col>
                                      );
                                      break;
                                    case "incidencia":
                                      let diferencia = 0;

                                      if (
                                        elemento["incidencia"] &&
                                        new Date().getTime() >
                                          new Date(
                                            elemento["incidencia"]
                                              .trim()
                                              .substr(0, 19)
                                          ).getTime()
                                      ) {
                                        diferencia =
                                          (new Date().getTime() -
                                            new Date(
                                              elemento["incidencia"]
                                                .trim()
                                                .substr(0, 19)
                                            ).getTime()) /
                                          1000 /
                                          60 /
                                          60;
                                      }
                                      let colorTem= "";
                                      if(diferencia > 1){
                                        colorTem= "rgba(241, 111, 111, 0.67)";
                                      }else{
                                        colorTem= "rgba(73, 223, 67, 0.29)";
                                      }
                                      if(elemento.incidenciaUsuario === 0){
                                        colorTem= "rgba(107, 194, 245, 0.56)";
                                      }
                                      if(elemento.id === 560771){
                                        console.log(elemento);
                                      }
                                      this.colum.push(
                                        <Table.Col>
                                          <div
                                            className="row"
                                            style={{
                                              "font-size": "80%",
                                              "background-color": colorTem,
                                            }}
                                          >
                                            {elemento["incidencia"]}
                                          </div>
                                        </Table.Col>
                                      );
                                      break;
                                    case "wo":
                                      this.colum.push(
                                        <Table.Col>{elemento["po"]}</Table.Col>
                                      );
                                      break;
                                    case "ontime":
                                      {
                                        this.colum.push(
                                          <Table.Col>
                                            <div
                                              className="row"
                                              style={
                                                timeToMilisegundos(
                                                  elemento[columna.columna]
                                                ) > 0
                                                  ? {
                                                      "background-color":
                                                        "green",
                                                    }
                                                  : {}
                                              }
                                            >
                                              {elemento[columna.columna]}
                                            </div>
                                          </Table.Col>
                                        );
                                      }
                                      break;
                                    case "delay":
                                      {
                                        this.colum.push(
                                          <Table.Col>
                                            <div
                                              className="row"
                                              style={
                                                timeToMilisegundos(
                                                  elemento[columna.columna]
                                                ) > 0
                                                  ? {
                                                      "background-color": "red",
                                                    }
                                                  : {}
                                              }
                                            >
                                              {elemento[columna.columna]}
                                            </div>
                                          </Table.Col>
                                        );
                                      }
                                      break;
                                    case "origen":
                                      {
                                        this.colum.push(
                                          <Table.Col>
                                            {elemento.idMovimientoTipo.nombre
                                              .toUpperCase()
                                              .includes("IMPORTACIÓN") ||
                                            elemento.idMovimientoTipo.nombre
                                              .toUpperCase()
                                              .includes("IMPORTACION") || 
                                              [1678].includes(elemento.idPlataforma.id)
                                              ? elemento.idCorredor.nombre
                                              : elemento.destino}
                                          </Table.Col>
                                        );
                                      }
                                      break;
                                    case "destino":
                                      {
                                        this.colum.push(
                                          <Table.Col>
                                            {elemento.idMovimientoTipo.nombre
                                              .toUpperCase()
                                              .includes("IMPORTACIÓN") ||
                                            elemento.idMovimientoTipo.nombre
                                              .toUpperCase()
                                              .includes("IMPORTACION") || 
                                              [1678].includes(elemento.idPlataforma.id)
                                              ? elemento.destino
                                              : elemento.idCorredor.nombre}
                                          </Table.Col>
                                        );
                                      }
                                      break;
                                    case "password":
                                      this.colum.push(
                                        <Table.Col> {"*******"} </Table.Col>
                                      );
                                      break;
                                    case "gps":
                                      {
                                        if (this.state.movsGpsEstatus) {
                                          let mPatioTemp;
                                          if (
                                            this.props.movPatio[elemento.id]
                                          ) {
                                            mPatioTemp = movsArray[
                                              parseInt(
                                                this.props.movPatio[elemento.id]
                                                  .esFull,
                                                10
                                              )
                                            ]
                                              ? movsArray[
                                                  parseInt(
                                                    this.props.movPatio[
                                                      elemento.id
                                                    ].esFull,
                                                    10
                                                  )
                                                ]
                                              : [];
                                          }
                                          if (elemento.movimientoGps) {
                                              if (
                                                elemento.movimientoGps.length
                                              ) {
                                                this.colum.push(
                                                  <Table.Col>
                                                    {elemento.movimientoGps.map(
                                                      (gpsTem, inc001) => (
                                                        this.state.movsGpsEstatus[
                                                          gpsTem.gps.id
                                                        ]?
                                                          <div>
                                                            <div
                                                              className="badge"
                                                              style={{
                                                                color:
                                                                  this.state.movsGpsEstatus[
                                                                    gpsTem.gps.id
                                                                  ].iconBattery.color,
                                                                "font-size": "80%",
                                                              }}
                                                            >
                                                              {
                                                                this.state.movsGpsEstatus[
                                                                  gpsTem.gps.id
                                                                ].nombre
                                                              }
                                                            </div>
                                                            <div
                                                              className="badge"
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title={
                                                                this.state.movsGpsEstatus[
                                                                  gpsTem.gps.id
                                                                ].nombre +
                                                                ", " +
                                                                this.state.movsGpsEstatus[
                                                                  gpsTem.gps.id
                                                                ].nivel_bateria +
                                                                " %"
                                                              }
                                                              style={{
                                                                color:
                                                                  this.state.movsGpsEstatus[
                                                                    gpsTem.gps.id
                                                                  ].iconBattery.color,
                                                                "font-size": "70%",
                                                              }}
                                                            >
                                                              <span
                                                                className={
                                                                  this.state.movsGpsEstatus[
                                                                    gpsTem.gps.id
                                                                  ].iconBattery.icono
                                                                }
                                                              ></span>
                                                            </div>
                                                            <div
                                                              className="badge"
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title={
                                                                this.state.movsGpsEstatus[
                                                                  gpsTem.gps.id
                                                                ].ultimo_reporte
                                                              }
                                                              style={{
                                                                color:
                                                                  this.state.movsGpsEstatus[
                                                                    gpsTem.gps.id
                                                                  ].iconTime.color,
                                                                "font-size": "70%",
                                                              }}
                                                            >
                                                              <span
                                                                className={
                                                                  this.state.movsGpsEstatus[
                                                                    gpsTem.gps.id
                                                                  ].iconTime.icono
                                                                }
                                                              ></span>
                                                            </div>
                                                          </div>
                                                        :
                                                          <div>
                                                            {
                                                              gpsTem.gps
                                                                .nombre
                                                            }
                                                          </div>
                                                      )
                                                    )}
                                                  </Table.Col>
                                                );
                                              } else {
                                                mPatioTemp
                                                  ? mPatioTemp.movimientoGps
                                                    ? this.colum.push(
                                                        <Table.Col>
                                                          {mPatioTemp.movimientoGps.map(
                                                            (
                                                              gpsTem,
                                                              inc001
                                                            ) => (
                                                              <div>
                                                                {
                                                                  gpsTem.gps
                                                                    .nombre
                                                                }
                                                              </div>
                                                            )
                                                          )}
                                                        </Table.Col>
                                                      )
                                                    : this.colum.push(
                                                        <Table.Col></Table.Col>
                                                      )
                                                  : this.colum.push(
                                                      <Table.Col></Table.Col>
                                                    );
                                              }
                                            } else {
                                              this.colum.push(
                                                <Table.Col></Table.Col>
                                              );
                                            }
                                        } else {
                                          this.colum.push(
                                            <Table.Col>
                                              {elemento.movimientoGps
                                                ? elemento.movimientoGps.map(
                                                    (gpsTem, inc001) => (
                                                      <div>
                                                        {" "}
                                                        {gpsTem.gps.nombre}
                                                      </div>
                                                    )
                                                  )
                                                : ""}
                                            </Table.Col>
                                          );
                                        }
                                      }
                                      break;
                                    case "alertas":
                                      var nticacion = [];
                                      if (this.state.alertasN) {
                                        if (this.state.alertasN[elemento.id]) {
                                          let semaforo = 0; //0,1,
                                          Object.keys(
                                            this.state.alertasN[elemento.id]
                                          ).forEach((key, indice) => {
                                            if (
                                              this.state.alertasN[elemento.id][
                                                key
                                              ][0]
                                            ) {
                                              if (
                                                this.state.alertasN[
                                                  elemento.id
                                                ][key][0].tiempo
                                              ) {
                                                let timemil =
                                                  timeToMilisegundos(
                                                    this.state.alertasN[
                                                      elemento.id
                                                    ][key][0].tiempo
                                                  );
                                                if (timemil > 0) {
                                                  for (let alertas of this.state
                                                    .alertasN[elemento.id][
                                                    key
                                                  ]) {
                                                    if (
                                                      new Date(
                                                        alertas.alert.fechaCreacion
                                                      ).getTime() +
                                                        timemil <
                                                      new Date().getTime()
                                                    ) {
                                                      semaforo = 2;
                                                    } else {
                                                      let diferencia =
                                                        new Date(
                                                          alertas.alert.fechaCreacion
                                                        ).getTime() -
                                                        new Date().getTime();
                                                      if (
                                                        timemil - diferencia <
                                                          2 &&
                                                        diferencia > 0 &&
                                                        semaforo < 2
                                                      ) {
                                                        semaforo = 1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            if (
                                              this.state.alertasN[elemento.id][
                                                key
                                              ].filter(
                                                (word) => !word.alert.atendida
                                              ).length
                                            ) {
                                              nticacion.push(
                                                <div
                                                  className="badge"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={
                                                    this.state.alertasN[
                                                      elemento.id
                                                    ][key][0].tipo +
                                                    ", Tiempo de atención: " +
                                                    timeToMilisegundos(
                                                      this.state.alertasN[
                                                        elemento.id
                                                      ][key][0].tiempo
                                                    ) /
                                                      1000 /
                                                      60 +
                                                    " min"
                                                  }
                                                  style={{
                                                    color:
                                                      this.state.alertasN[
                                                        elemento.id
                                                      ][key][0].color,
                                                    "font-size": "140%",
                                                  }}
                                                >
                                                  <span className={"fa " + key}>
                                                    {
                                                      this.state.alertasN[
                                                        elemento.id
                                                      ][key].filter(
                                                        (word) =>
                                                          !word.alert.atendida
                                                      ).length
                                                    }
                                                  </span>
                                                </div>
                                              );
                                            }
                                          });
                                          if (nticacion.length > 0) {
                                            this.colum.push(
                                              <Table.Col>
                                                <div
                                                  className="row"
                                                  style={{
                                                    "background-color":
                                                      semaforo === 1
                                                        ? "rgba(228, 228, 98, 0.7)"
                                                        : semaforo === 2
                                                        ? "rgba(241, 111, 111, 0.67)"
                                                        : "rgba(73, 223, 67, 0.29)",
                                                  }}
                                                >
                                                  {
                                                    <div className="badge col">
                                                      {" "}
                                                      {nticacion}
                                                    </div>
                                                  }
                                                </div>
                                              </Table.Col>
                                            );
                                          } else {
                                            this.colum.push(
                                              <Table.Col> </Table.Col>
                                            );
                                          }
                                        } else {
                                          this.colum.push(
                                            <Table.Col> </Table.Col>
                                          );
                                        }
                                      } else {
                                        this.colum.push(
                                          <Table.Col> </Table.Col>
                                        );
                                      }
                                      break;
                                    case "edit":
                                      this.colum.push(
                                        <Table.Col>
                                          <p style={{ width: "80px" }}>
                                            <span className="float-left">
                                              <ModalLay
                                                resumirModal={
                                                  this.props.resumeModal
                                                }
                                                funcionReanudar={
                                                  this.props
                                                    .functionReanudarModal
                                                }
                                                title="Modificar"
                                                name={
                                                  <div
                                                    className="badge"
                                                    style={{
                                                      color: "green",
                                                      "font-size": "120%",
                                                    }}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Editar movimiento"
                                                  >
                                                    {!this.props
                                                      .resumeModal && (
                                                      <span className="fa fa-edit"></span>
                                                    )}
                                                  </div>
                                                }
                                                formulario={this.Catalogo_form}
                                                datosForm={{
                                                  accion: "modificar",
                                                  elementos: elemento,
                                                  getfuntion: this.getfuntion,
                                                  stor: this.props,
                                                  Fclose: this.hiddenmodal,
                                                }}
                                              />
                                            </span>{" "}
                                            <spam>{/* className="float-left ml-1"> */}
                                             {/* {this.getAvisos(elemento)} */}
                                            </spam>
                                          </p>
                                        </Table.Col>
                                      );
                                      break;
                                    case "eta":
                                      this.props.etasN[elemento.id]
                                        ? this.props.etasN[elemento.id][2]
                                          ? this.colum.push(
                                              <Table.Col>
                                                <ModalLay
                                                  tamanio={"90%"}
                                                  title="Etas calculadas"
                                                  name={
                                                    <div className="badge bg-secondary">
                                                      <Icon name="message-square" />{" "}
                                                      <span>
                                                        {
                                                          this.props.etasN[
                                                            elemento.id
                                                          ][2].total
                                                        }
                                                      </span>{" "}
                                                    </div>
                                                  }
                                                  formulario={EtaCalculada}
                                                  datosForm={{
                                                    idMovimento: elemento["id"],
                                                  }}
                                                />
                                              </Table.Col>
                                            )
                                          : this.colum.push(
                                              <Table.Col></Table.Col>
                                            )
                                        : this.colum.push(
                                            <Table.Col></Table.Col>
                                          );
                                      break;
                                    case "economicor":
                                      this.props.movPatio[elemento.id]
                                        ? this.colum.push(
                                            <Table.Col>
                                              {this.props.movPatio[elemento.id]
                                                ? this.props.movPatio[
                                                    elemento.id
                                                  ].economicor
                                                : ""}
                                            </Table.Col>
                                          )
                                        : this.colum.push(
                                            <Table.Col></Table.Col>
                                          );
                                      break;
                                    case "economicot":
                                      this.props.movPatio[elemento.id]
                                        ? this.colum.push(
                                            <Table.Col>
                                              {this.props.movPatio[elemento.id]
                                                ? this.props.movPatio[
                                                    elemento.id
                                                  ].economicot
                                                : ""}
                                            </Table.Col>
                                          )
                                        : this.colum.push(
                                            <Table.Col></Table.Col>
                                          );
                                      break;
                                    case "remolquePlaca":
                                      this.props.movPatio[elemento.id]
                                        ? this.colum.push(
                                            <Table.Col>
                                              {this.props.movPatio[elemento.id]
                                                ? this.props.movPatio[
                                                    elemento.id
                                                  ].remolquePlaca
                                                : ""}
                                            </Table.Col>
                                          )
                                        : this.colum.push(
                                            <Table.Col></Table.Col>
                                          );
                                      break;
                                    case "tractorPlaca":
                                      this.props.movPatio[elemento.id]
                                        ? this.colum.push(
                                            <Table.Col>
                                              {this.props.movPatio[elemento.id]
                                                ? this.props.movPatio[
                                                    elemento.id
                                                  ].tractorPlaca
                                                : ""}
                                            </Table.Col>
                                          )
                                        : this.colum.push(
                                            <Table.Col></Table.Col>
                                          );
                                      break;
                                    case "referencia_facturacion":
                                      let variables= JSON.parse(elemento.variables);
                                      if(variables){
                                        if( variables["referencia_facturacion"] ){
                                          this.colum.push( <Table.Col>{ variables["referencia_facturacion"] }</Table.Col> );
                                        }else{
                                          this.colum.push(<Table.Col></Table.Col>);
                                        }
                                      }
                                      break;                                      
                                    default:
                                      this.colum.push(
                                        <Table.Col>
                                          {elemento[columna.columna]}
                                        </Table.Col>
                                      );
                                  }
                                }
                              }
                            )}
                          </Table.Row>
                        ))
                    ) : (
                      <Table.Row>
                        <Table.Col> No hay Movimientos ..! </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              ) : null}
            </Dimmer>
          </Card.Body>
        </Card>
        <div hidden={!this.state.showErrormessage}>
          {this.state.ErrorMessage}
        </div>
      </Grid.Col>
    );
  }
}
function mapStateToProps(state) {
  return {
    movimientos_naviera_object:
      state.catalogos_reducer.movimientos_naviera_object,
    //movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    localidades_object: state.catalogos_reducer.localidades_object,
    operacionesEstatus_object:
      state.catalogos_reducer.operacionesEstatus_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object: state.catalogos_reducer.update_mensaje,
    etiquetas_object: state.catalogos_reducer.etiquetas_object,
  };
}
function mapDispachToProps(dispach) {
  return {
    incidencias_update: (elementos) =>
      dispach(ACTIONS.elementos_incidencias_imput(elementos)),
    movimientos_naviera_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    movimientosTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) =>
      dispach(ACTIONS.elementos_clientes_imput(elementos)),
    navieras_update: (elementos) =>
      dispach(ACTIONS.elementos_navieras_imput(elementos)),
    localidades_update: (elementos) =>
      dispach(ACTIONS.elementos_localidades_imput(elementos)),
    operacionesEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) =>
      dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) =>
      dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) =>
      dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) =>
      dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    privilegios_update: (elementos) =>
      dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    operadores_update: (elementos) =>
      dispach(ACTIONS.elementos_operadores_imput(elementos)),
    etiquetas_update: (elementos) =>
      dispach(ACTIONS.elementos_etiquetas_imput(elementos)),
  };
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo);