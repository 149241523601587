import React, { Component, useState } from 'react';
import { Button, Form, Icon, Grid, Table, Card, Dimmer } from 'tabler-react';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import ModalLay from '../../components/ModalLay/ModalLay';
import TabsLayout from '../../components/TabsLayout.react';
import { Link } from 'react-router-dom';
/** Arrobas */
import { Arroba, ArrobaFull, ArrobaTerrestre } from './Arroba';
import Reaccion from './panelForms/Reaccion';
/** alertas */
import { NotificationManager } from 'react-notifications';
/** Formularios */
import MovimientoIncidencias from './MovimientoIncidencias';
import {
  Transportista,
  Cliente,
  ContactosMov,
  ContactosMovTerrestre,
  Cita,
  Actividad,
  Entregas,
  EntregasNavieras,
  Cartas,
  AlertaNForm,
  AlertaTForm,
  AlertaNListar,
  IncidenciasNListar,
  ActualizarHorariosNavieros,
  ActualizarHorariosTerrestres,
  ActualizarHorariosNMP,
  BitacoraMov,
  MovimientoUnidad,
  MovimientoOperador,
  BitacoraPdf,
  ReactivarMovimientos,
  ReactivarMovimientosTerrestre,
  BitacoraPdfTerrestre,
  GpsEspejo,
} from './panelForms';

import { SolicitudesNavieras } from './panelForms/SolicitudesNavieras';
import MovimientoIncidenciasTerrestre from './MovimientoIncidenciasTerrestre';
import IncidenciasTraxion from './IncidenciasTraxion';
import Exportar from '../../herramientas/Excel/Exportar';
import IncidenciasTraxionExportar from './IncidenciasTraxionExportar';
import IncidenciasTerrestresExportarActYFin from './IncidenciasTerrestresExportarActYFin';
import ReaccionForm from './panelForms/Reaccion/ReaccionForm';
import Temporizador from '../../components/Temporizador/Temporizador';
import { NavieraServiceFailure } from './NavieraServiceFailure';
import IncidenciasMovimientoView from './IncidenciasMovimientoView';
import * as conf from '../../config/config';
import EntregasClientes from './panelForms/Entregas/EntregasClientes';
import axios from 'axios';
import { useEffect } from 'react';
import { MovimientoGpsEvidenciaModal } from '../../components/MovimientoGpsEvidenciaModal/MovimientoGpsEvidenciaModal';
import { ContactosMovTerr } from './panelForms/ContactosMovTerrestre';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
let upImage = null;
let comentarioArroba = null;
const rutaImagen = conf.api_raiz + 'evidencia/';
//Formato de Fecha
function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}
function myFormato(date) {
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    ' ' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds())
  );
}

/**
 * usuario
 */
var user = JSON.parse(sessionStorage.getItem('usuario'));
//import axios from 'axios';
const perfilUsuario = JSON.parse(sessionStorage.getItem('perfil'));
let usuariosLogObject = {
  idBitacoraMonitoreo: 0,
  idUsuario: 0,
  idMovimiento: 0,
  accionFecha: '',
  descripcion: '',
  idPlataformaTipo: '',
};
let alertT = [];
let columna = [];
let privilegio = [];
let currentMovimientoN = {};
let currentMovimientoT = {};
let movimientosReativados = [];
let casetas = '';
let catalogo = '';
const Panel = ({
  alertasN,
  alertasT,
  alertasTSinAtender,
  getMovimiento,
  plataformaTipo,
  movimiento,
  stor,
  gpsEstatusNavieros,
  MovimientosFull,
  gpsEstatusTerrestres,
  privilegios_object,
  setAlerta,
  gpsReciente,
  incidencias,
  PlataformasPerfiles,
  rutasEstatus,
  operacionesEstatus,
  clientes_object,
  otd_object,
  tiempos,
  addToArray,
  usuario,
  getplataformaTipo,
  getMovimientoNavieroById,
}) => {
  const [detalles, setDetalles] = useState(
    <div>
      <h4 className="mt-2">No hay detalles ..</h4>{' '}
    </div>
  );
  const [incidenciasNavieras, setIncidenciasNavieras] = useState([]);
  const [hiddenModals, setHiddenModals] = useState(false);
  const [movimientoGps, setMovimientoGps] = useState([]);
  const [currentTap, setCurrentTap] = useState(0);
  const [searchAlertas, setSearchAlertas] = useState('');
  const [searchIncidencias, setSearchIncidencias] = useState('');
  const [detallesCollase, setDetallesCollase] = useState(false);
  const [alertasCollase, setAlertasCollase] = useState(true);
  const [incidenciasCollase, setIncidenciasCollase] = useState(true);
  const [entregas, setEntregas] = useState([]);
  const [unidad, setUnidad] = useState([]);

  const [incidenciasTerrestre, setIncidenciasTerrestre] = useState([]);
  const [incidenTerrestre, setIncidenTerrestre] = useState([]);
  const [idEntrega, setIdEntrega] = useState(null);
  const [idEntregaActiva, setIdEntregaActiva] = useState(0);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [destinatarios, setDestinatarios] = useState({
    destinatarios3: '',
    destinatarios4: '',
    destinatarios5: '',
    destinatarios6: '',
  });
  const [movimientoFull, setMovimientoFull] = useState(null);
  const [incidenciasTraxionExcel, setIncidenciasTraxionExcel] = useState([]);
  const [camposLogisticosNav, setCamposLogisticosNav] = useState([]);
  const [acumuladores, setAcumuladores] = useState([]);
  const [imgEvidencias, setImgEvidencias] = useState([]);
  const [solicitudesNavierasContar, setSolicitudesNavierasContar] = useState(0);
  const [videoDispositivoEstatus, setVideoDispositivoEstatus] = useState({});

  useEffect(() => {
    if (plataformaTipo === 'Naviera') {
      SolicitudesNavierasContar();
      if (movimiento) {
        setMovimientoGps(getMovimiento.movimientoGps);
      }
    }
    if (plataformaTipo === 'Terrestre') {
      if (movimiento) {
        setMovimientoGps(getMovimiento.movimientoGps);
      }
    }
  }, []);
  useEffect(() => {
    if (plataformaTipo === 'Naviera') {
      if (
        getMovimiento.id &&
        currentMovimientoN !== getMovimiento &&
        plataformaTipo === 'Naviera'
      ) {
        //CargaCamposLogisticos
        let movTipoTemp = getMovimiento.idMovimientoTipo.id;
        if (movTipoTemp === 3) {
          movTipoTemp = 1;
        }
        if (movTipoTemp === 4) {
          movTipoTemp = 2;
        }
        if (estaPrivilegioActivo('campos_logisticos_listar')) {
          getfuntion(
            'get',
            '',
            '',
            'camposLogisticos/plataforma/' +
              getMovimiento.idPlataforma.id +
              '/mtipo/' +
              movTipoTemp,
            'camposLogisticos'
          ).then((returnVal) => {
            setCamposLogisticosNav(returnVal);
            cargarDetalles();
          });
        } else {
          cargarDetalles();
        }
        if (estaPrivilegioActivo('movimiento_acumuladores_listar'))
          getfuntion(
            'get',
            '',
            '',
            'movimientoAcumuladores/mov/' + getMovimiento.id,
            'acumuladores'
          );
        currentMovimientoN = getMovimiento;
        setDestinatarios({
          destinatarios3: '',
          destinatarios4: '',
          destinatarios5: '',
          destinatarios6: '',
        });
        setDetalles(
          <div>
            <h4 className="mt-2">Cargando ..</h4>{' '}
            <Dimmer active={true} loader></Dimmer>{' '}
          </div>
        );
        setMovimientoGps(getMovimiento.movimientoGps);

        getIncidencias();

        if (estaPrivilegioActivo('arroba_azul')) {
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_clientes/' + getMovimiento.id + '/3',
            'destinatarios3'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_transportistas/' + getMovimiento.id + '/3',
            'destinatarios3'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_sky_naviera/' + getMovimiento.id + '/3',
            'destinatarios3'
          );
        }
        if (estaPrivilegioActivo('arroba_roja')) {
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_clientes/' + getMovimiento.id + '/4',
            'destinatarios4'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_transportistas/' + getMovimiento.id + '/4',
            'destinatarios4'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_sky_naviera/' + getMovimiento.id + '/4',
            'destinatarios4'
          );
        }
        if (estaPrivilegioActivo('arroba_negra')) {
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_clientes/' + getMovimiento.id + '/5',
            'destinatarios5'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_transportistas/' + getMovimiento.id + '/5',
            'destinatarios5'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_sky_naviera/' + getMovimiento.id + '/5',
            'destinatarios5'
          );
        }
        if (estaPrivilegioActivo('arroba_amarilla')) {
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_clientes/' + getMovimiento.id + '/6',
            'destinatarios6'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_transportistas/' + getMovimiento.id + '/6',
            'destinatarios6'
          );
          getfuntion(
            'get',
            '',
            '',
            'personas/destinatarios_sky_naviera/' + getMovimiento.id + '/6',
            'destinatarios6'
          );
        }

        setMovimientoFull(null);

        Object.keys(MovimientosFull).forEach((key, index) => {
          // key == mov full
          if (getMovimiento.id == key) {
            setMovimientoFull(MovimientosFull[key].full);
          }
          if (MovimientosFull[key].full == getMovimiento.id) {
            setMovimientoFull(key);
          }
        });
      }
    }

    if (plataformaTipo === 'Terrestre') {
      if (getMovimiento.id && currentMovimientoT !== getMovimiento) {
        upImage = null;
        comentarioArroba = null;
        currentMovimientoT = getMovimiento;
        setMovimientoGps(getMovimiento.movimientoGps);
        setIncidenciasTerrestre([]);
        if ([193].includes(getMovimiento.plataforma.id)) {
          //193 Es traxion
          getfuntion(
            'get',
            '',
            '',
            'incidencias_traxion/movimiento/' + getMovimiento.id,
            'incidencias_traxion'
          );
        } else {
          getfuntion(
            'get',
            '',
            '',
            'terrestreEntregasIncidencias/movimiento/' + getMovimiento.id,
            'incidenciasTerrestre'
          );
        }
        if (getMovimiento.id) {
          setDestinatarios({
            destinatarios3: '',
            destinatarios4: '',
            destinatarios5: '',
            destinatarios6: '',
          });
          if (plataformaTipo === 'Terrestre') {
            let detalles = [];
            setDetalles(<div>Cargando ··</div>);
            if (getFuncionalidad('Transportista') && perfilUsuario.id !== 318) {
              detalles.push({
                title: 'Trans',
                icon: 'truck',
                panel: (
                  <Transportista
                    obj={getMovimiento.transportista}
                    mov={getMovimiento}
                  />
                ),
                close: false,
              });
            }
            if (getFuncionalidad('Transportista')) {
              detalles.push({
                title: 'Mov. Notif',
                icon: 'mail',
                panel: (
                  <ContactosMovTerr
                    obj={getMovimiento.transportista}
                    mov={getMovimiento}
                    stor={stor}
                  />
                ),
                close: false,
              });
            }
            getfuntion(
              'get',
              '',
              '',
              'entregas/movimiento/' + getMovimiento.id,
              'entregas'
            ).then((returnVal) => {
              if (getFuncionalidad('Entregas')) {
                detalles.push({
                  title: 'Entregas',
                  icon: 'watch',
                  panel:
                    perfilUsuario.id !== 318 ? (
                      <Entregas
                        obj={getMovimiento}
                        setIdEntregaActiva={setIdEntregaActiva}
                        entregas={returnVal}
                      />
                    ) : (
                      <EntregasClientes
                        obj={getMovimiento}
                        setIdEntregaActiva={setIdEntregaActiva}
                        entregas={returnVal}
                      />
                    ),
                  close: false,
                });
              }
              if (getFuncionalidad('Entregas') && perfilUsuario.id !== 318) {
                detalles.push({
                  title: "Carta Porte's",
                  icon: 'truck',
                  panel: (
                    <Cartas
                      obj={getMovimiento}
                      setIdEntregaActiva={setIdEntregaActiva}
                      entregas={returnVal}
                    />
                  ),
                  close: false,
                });
              }

              setDetalles(
                <TabsLayout
                  tabs={detalles}
                  selectedTab={currentTap}
                  selectTab={setCurrentTap}
                />
              );
            });
            //unidad ter
            getfuntion(
              'get',
              '',
              '',
              'terrestreMovimientoUnidad/movimiento/' + getMovimiento.id,
              'terrestreMovimientoUnidad'
            ).then((returnVal) => {
              setUnidad(returnVal);
            });
            if (getFuncionalidad('Actividad') && perfilUsuario.id !== 318) {
              detalles.push({
                title: 'Actividad',
                icon: 'activity',
                panel: (
                  <Actividad
                    obj={getMovimiento}
                    gpsEstatusTerrestres={gpsEstatusTerrestres}
                    plataformaTipo={plataformaTipo}
                    gps={
                      getMovimiento.movimientoGps
                        ? getMovimiento.movimientoGps[0]
                          ? getMovimiento.movimientoGps
                          : []
                        : []
                    }
                    abrirmodalGps={
                      getFuncionalidad('Rastreame') ? abrirmodalGps : null
                    }
                  />
                ),
                close: false,
              });
            }

            setIdEntregaActiva(null);
            //Se obtienen  destinatarios por id mov
            if (estaPrivilegioActivo('arroba_azul')) {
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_transportistas_ter/' +
                  getMovimiento.id +
                  '/3',
                'destinatarios3'
              );
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_sky_terrestre/' +
                  getMovimiento.id +
                  '/3',
                'destinatarios3'
              );
            }
            if (estaPrivilegioActivo('arroba_roja')) {
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_transportistas_ter/' +
                  getMovimiento.id +
                  '/4',
                'destinatarios4'
              );
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_sky_terrestre/' +
                  getMovimiento.id +
                  '/4',
                'destinatarios4'
              );
            }
            if (estaPrivilegioActivo('arroba_negra')) {
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_transportistas_ter/' +
                  getMovimiento.id +
                  '/5',
                'destinatarios5'
              );
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_sky_terrestre/' +
                  getMovimiento.id +
                  '/5',
                'destinatarios5'
              );
            }
            if (estaPrivilegioActivo('arroba_amarilla')) {
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_transportistas_ter/' +
                  getMovimiento.id +
                  '/6',
                'destinatarios6'
              );
              getfuntion(
                'get',
                '',
                '',
                'personas/destinatarios_sky_terrestre/' +
                  getMovimiento.id +
                  '/6',
                'destinatarios6'
              );
            }
            //img evidencias
            setImgEvidencias([]);

            getfuntion(
              'get',
              '',
              '',
              'imagenMovimiento/movimiento/' + getMovimiento.id,
              'imagenMovimiento'
            ).then((returnVal) => {
              setImgEvidencias(returnVal ? returnVal : []);
            });
          }
        }

        let casetas = '';
        if (getMovimiento.id) {
          if (getMovimiento.personalizados) {
            try {
              let personalizadosObj = JSON.parse(getMovimiento.personalizados);
              casetas = personalizadosObj.casetas;
            } catch (error) {
              console.log('error', error);
            }
          }
        }
      }
    }
  }, [getMovimiento]);
  const SolicitudesNavierasContar = () => {
    getfuntion(
      'get',
      '',
      '',
      'solicitudes_cliente/usuario_patios_check_pendiente_contar',
      'solicitudesNavierasContar'
    ).then((resp) => {
      setSolicitudesNavierasContar(resp);
    });
    setTimeout(() => {
      SolicitudesNavierasContar();
    }, 1000 * 60 * 1);
  };
  const cargarDetalles = () => {
    let detalles = [];
    if (getFuncionalidad('Transportista')) {
      detalles.push({
        title: 'Trans',
        icon: 'truck',
        panel: (
          <Transportista
            obj={getMovimiento.idTransportista}
            mov={getMovimiento}
            stor={stor}
          />
        ),
        close: false,
      });
    }
    if (getFuncionalidad('Cliente')) {
      detalles.push({
        title: 'Clien',
        icon: 'user',
        panel: (
          <Cliente
            obj={getMovimiento.idCliente}
            mov={getMovimiento}
            stor={stor}
          />
        ),
        close: false,
      });
    }
    if (getFuncionalidad('Cliente')) {
      detalles.push({
        title: 'Mov. Notif',
        icon: 'mail',
        panel: (
          <ContactosMov
            obj={getMovimiento.idCliente}
            mov={getMovimiento}
            stor={stor}
          />
        ),
        close: false,
      });
    }
    if (getFuncionalidad('Detalles')) {
      detalles.push({
        title: 'Detalles',
        icon: 'watch',
        panel: (
          <Cita obj={getMovimiento} camposLogisticosNav={camposLogisticosNav} />
        ),
        close: false,
      });
    }
    if (getFuncionalidad('Actividad')) {
      detalles.push({
        title: 'Act',
        icon: 'activity',
        panel: (
          <Actividad
            obj={getMovimiento}
            gpsEstatusNavieros={gpsEstatusNavieros}
            plataformaTipo={plataformaTipo}
            estaPrivilegioActivo={estaPrivilegioActivo}
            gps={
              getMovimiento.movimientoGps[0]
                ? getMovimiento.movimientoGps
                : MovimientosFull[getMovimiento.id]
                ? getMovimientoNavieroById(
                    MovimientosFull[getMovimiento.id].full
                  )
                  ? getMovimientoNavieroById(
                      MovimientosFull[getMovimiento.id].full
                    ).movimientoGps
                  : []
                : []
            }
            abrirmodalGps={getFuncionalidad('Rastreame') ? abrirmodalGps : null}
          />
        ),
        close: false,
      });
    }
    if (getMovimiento.idPlataforma.plataformaTipo.id === 3) {
      //Entregas navieras para plataforma tipo 3 terrestre naviera
      detalles.push({
        title: 'Entregas',
        icon: 'watch',
        panel: <EntregasNavieras obj={getMovimiento} />,
        close: false,
      });
    }
    setDetalles(
      <TabsLayout
        tabs={detalles}
        selectedTab={currentTap}
        selectTab={setCurrentTap}
      />
    );
    getfuntion(
      'get',
      '',
      '',
      'unidadTerNav/idMovimiento/' + getMovimiento.id,
      'movimientoUnidadNaviera'
    );
  };

  /**
   * Privilegio activo
   */
  const estaPrivilegioActivo = (clave) => {
    var privilegios = privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem('perfil'));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  const privilegioDescripcion = (clave) => {
    var privilegios = privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem('perfil'));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return privilegios[i].descripcion;
        } else {
          return 'No identificado ..!';
        }
        break;
      }
    }
  };

  const hiddenmodal = () => {
    setHiddenModals(!hiddenModals);
  };
  const getIncidencias = () => {
    if (estaPrivilegioActivo('campos_logisticos_listar'))
      Crud_Catalogos(
        'movimientoIncidencias/movimiento/' + getMovimiento.id,
        'movimientos',
        'get',
        '',
        [],
        '',
        incidencias,
        '',
        []
      )
        .then((returnVal) => {
          setIncidenciasNavieras(returnVal);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const setImagen = (img) => (upImage = img);
  const setComentario = (c) => (comentarioArroba = c);

  //stateVar: es el nombre de la variable del State que se va actualizar.
  const getfuntion = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = '',
    stateVar = '',
    hiddenModl = '',
    enviar
  ) => {
    return Crud_Catalogos(
      catalogo,
      'movimientos',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case 'get':
            {
              if (stateVar === 'camposLogisticos') {
                return returnVal;
              }
              if (stateVar === 'solicitudesNavierasContar') {
                return returnVal;
              }
              if (stateVar === 'imagenMovimiento') {
                return returnVal;
              }
              if (stateVar === 'terrestreMovimientoUnidad') {
                return returnVal;
              }
              if (stateVar === 'movimientoUnidadNaviera') {
                let videoDispositivoEstatusTemp = {
                  ...videoDispositivoEstatus,
                };
                for (let u of returnVal) {
                  if (u.id) {
                    if (!videoDispositivoEstatusTemp[u.id])
                      videoDispositivoEstatusTemp[u.id] = {};
                    if (u.idVideo) {
                      delete axios.defaults.headers.common["Authorization"];
                      axios
                        .get(
                          conf.cctv +
                            ':8443/video_back/' +
                            'dispositivos/live/unidades/estatus/' +
                            u.idVideo
                        )
                        .then((res) => {
                          //:8443/video_back/
                          if (res.data)
                            videoDispositivoEstatusTemp[u.id] = res.data;
                          setVideoDispositivoEstatus(
                            videoDispositivoEstatusTemp
                          );
                        });
                    }
                  }
                }

                setUnidad(returnVal);
              }
              if (stateVar === 'acumuladores' && returnVal) {
                let acumuladoresTemp = {};
                returnVal.map(
                  (a, index) => (acumuladoresTemp[a.idAcumulador.id] = a)
                );
                setAcumuladores(acumuladoresTemp);
              }
              if (stateVar === 'entregas') {
                if (returnVal) {
                  setEntregas(returnVal);
                }
                returnVal.map((entre, index) => {
                  if (entre.estatus == 1) {
                    //si quiero la EntregaActiva
                    setIdEntregaActiva(entre);
                  } //Si Quiero Todas las Entregas
                });
                return returnVal;
              }
              if (stateVar === 'incidenciasTerrestre') {
                setIncidenciasTerrestre(returnVal);
              }
              if (stateVar === 'incidencias_traxion') {
                setIncidenciasTerrestre(returnVal);
                let dataTemp = [];
                returnVal.map((filas, index) => {
                  Object.keys(filas).forEach((key) => {
                    if (!dataTemp[index]) {
                      dataTemp[index] = {};
                    }
                    if (filas[key] !== null) {
                      if (typeof filas[key] === 'object') {
                        switch (key) {
                          case 'monitorista':
                            dataTemp[index][key] =
                              '' +
                              filas[key].nombre +
                              ' ' +
                              filas[key].aPaterno +
                              ' ' +
                              filas[key].aMaterno;
                            break;
                          case 'movimientoGps':
                            //filas[key].map((gps, indice)=> dataTemp[index]["gps"+indice+1]= gps.gps.nombre);
                            break;
                          default:
                            if (
                              filas[key].nombre != '' &&
                              filas[key].nombre != undefined
                            ) {
                              dataTemp[index][key] = filas[key].nombre;
                            } else {
                              if (
                                filas[key].id != '' &&
                                filas[key].id != undefined
                              ) {
                                dataTemp[index][key] = filas[key].id;
                              } else {
                                dataTemp[index][key] = 'Vari(@)s ';
                              }
                            }
                        }
                      } else {
                        dataTemp[index][key] = filas[key];
                      }
                    } else {
                      if (key !== 'movimientoGps') {
                        dataTemp[index][key] = '';
                      }
                    }
                  });
                });
                setIncidenciasTraxionExcel(dataTemp);
              }
              if (stateVar === 'urlGps') {
                window.open(
                  returnVal.url,
                  obj.idGg,
                  'width=900,height=600,scrollbars=NO'
                );
                usuariosLogObject.idBitacoraMonitoreo = 5;
                usuariosLogObject.idUsuario = user.id;
                usuariosLogObject.idMovimiento = getMovimiento.id;
                usuariosLogObject.idPlataformaTipo =
                  plataformaTipo === 'Naviera' ? 1 : 2;
                usuariosLogObject.accionFecha = myFormato(new Date());
                usuariosLogObject.descripcion =
                  'Rastreo de GPS: ' + obj.nombre + '(' + obj.idGg + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  usuariosLogObject,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
              }
              if (stateVar === 'destinatarios3' && returnVal) {
                let dest = { ...destinatarios };
                returnVal.map((per, index) => {
                  dest.destinatarios3 = dest.destinatarios3 + per.correo + ', ';
                });
                setDestinatarios(dest);
              }
              if (stateVar === 'destinatarios4' && returnVal) {
                let dest = { ...destinatarios };
                returnVal.map((per, index) => {
                  dest.destinatarios4 = dest.destinatarios4 + per.correo + ', ';
                });
                setDestinatarios(dest);
              }
              if (stateVar === 'destinatarios5' && returnVal) {
                let dest = { ...destinatarios };
                returnVal.map((per, index) => {
                  dest.destinatarios5 = dest.destinatarios5 + per.correo + ', ';
                });
                setDestinatarios(dest);
              }
              if (stateVar === 'destinatarios6' && returnVal) {
                let dest = { ...destinatarios };
                returnVal.map((per, index) => {
                  dest.destinatarios6 = dest.destinatarios6 + per.correo + ', ';
                });
                setDestinatarios(dest);
              }
              if (stateVar === 'movPatio') {
                return returnVal;
              }
            }
            if (stateVar === 'movimientosReactivados' && returnVal) {
            }
            //return returnVal;
            break;
          case 'put':
            {
              switch (catalogo) {
                case 'navierasAlertas':
                  usuariosLogObject.idBitacoraMonitoreo = 4;
                  usuariosLogObject.idUsuario = user.id;
                  usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  usuariosLogObject.descripcion = returnVal.causa;
                  usuariosLogObject.idPlataformaTipo = 1;
                  usuariosLogObject.accionFecha = myFormato(new Date());
                  setAlerta(returnVal, catalogo);
                  Crud_Catalogos(
                    'usuariosLog',
                    catalogo,
                    'post',
                    '',
                    usuariosLogObject,
                    '',
                    '',
                    hiddenmodal,
                    ''
                  );
                  break;
                case 'terrestresAlertas':
                  usuariosLogObject.idBitacoraMonitoreo = 4;
                  usuariosLogObject.idUsuario = user.id;
                  usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  usuariosLogObject.idPlataformaTipo = 2;
                  usuariosLogObject.accionFecha = myFormato(new Date());
                  usuariosLogObject.descripcion =
                    'Alerta Atendida id: ' +
                    returnVal.id +
                    ' de Tipo: ' +
                    returnVal.triggerName +
                    ' del Movimiento id: ' +
                    returnVal.idMovimiento;
                  setAlerta(returnVal, 'terrestresAlertas');

                  Crud_Catalogos(
                    'usuariosLog',
                    catalogo,
                    'post',
                    '',
                    usuariosLogObject,
                    '',
                    '',
                    hiddenmodal,
                    ''
                  );
                  break;
                default:
                  break;
              }

              switch (stateVar) {
                case 'updateFechas':
                  {
                    hiddenmodal();
                    NotificationManager.info(
                      'Se han registrado horarios en campos logisticos',
                      'Actualizacion, mov (' + returnVal.id + ')'
                    );
                    let cambios = getCambiosNav(id, returnVal); //id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
                    if (obj.actualizarFull) {
                      let mFull = MovimientosFull[getMovimiento.id]
                        ? getMovimientoNavieroById(movimientoFull)
                        : MovimientosFull[movimientoFull].movimiento;
                      if (mFull.id) {
                        let campLogistObj = {
                          idOperacionEstatus: obj.idOperacionEstatus,
                          idRuta: obj.idRuta,
                          citaProgramada: obj.citaProgramada,
                          sello: mFull.sello,
                          po: mFull.po,
                          booking: mFull.booking,
                          contenedor: mFull.contenedor,
                          idOtd: obj.idOtd,
                          idCliente: obj.idCliente,
                          actualizarFull: obj.actualizarFull,
                          idMovimientoTipo: obj.idMovimientoTipo,
                          idOperacionTipo: obj.idOperacionTipo,
                          idRuta2: obj.idRuta2,
                          estatusActual: obj.estatusActual,
                          /*Exportacion*/
                          retiroContenedor: obj.retiroContenedor,
                          arriboClienteVacio: obj.arriboClienteVacio,
                          salidaClienteLleno: obj.salidaClienteLleno,
                          arriboTerminalCargado:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboTerminalCargado
                              : mFull.arriboTerminalCargado, //
                          salidaPredioVacio: obj.salidaPredioVacio,
                          citaFechaDespacho: obj.citaFechaDespacho,
                          entradaCiudadPortuaria: obj.entradaCiudadPortuaria,
                          arriboPatioLinea:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboPatioLinea
                              : mFull.arriboPatioLinea, //
                          salidaTerminalVacio:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.salidaTerminalVacio
                              : mFull.salidaTerminalVacio, //
                          /**Importacion */
                          arriboTerminalVacio:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.arriboTerminalVacio
                              : mFull.arriboTerminalVacio, //
                          salidaTerminalLleno:
                            mFull.idPlataforma.plataformaTipo.id === 1
                              ? obj.salidaTerminalLleno
                              : mFull.salidaTerminalLleno, //
                          arriboClienteLleno: obj.arriboClienteLleno,
                          salidaClienteVacio: obj.salidaClienteVacio,
                          arriboDirecionCarga: obj.arriboDirecionCarga,
                          arriboTerminalVacio2: obj.arriboTerminalVacio2,
                          asignacionPiloto: obj.asignacionPiloto,
                          inicioRuta: obj.inicioRuta,
                          inicioRutaVacio: obj.inicioRutaVacio,
                          ingresoCliente: obj.ingresoCliente,
                          ingresoBodegaCarga: obj.ingresoBodegaCarga,
                          despachoPredioCliente: obj.despachoPredioCliente,
                          llegadaFrontera: obj.llegadaFrontera,
                          salidaFrontera: obj.salidaFrontera,
                          arriboAduana: obj.arriboAduana,
                          salidaAduana: obj.salidaAduana,
                        };
                        getfuntion(
                          'put',
                          campLogistObj,
                          mFull,
                          'navieraMovimientos/updateFechas/' + mFull.id,
                          'updateFechasFull'
                        );
                      }
                    }
                    usuariosLogObject.idBitacoraMonitoreo = cambios.bitacora;
                    usuariosLogObject.idUsuario = user.id;
                    usuariosLogObject.idMovimiento = returnVal.id;
                    usuariosLogObject.idPlataformaTipo = 1;
                    usuariosLogObject.accionFecha = myFormato(new Date());
                    //usuariosLogObject.descripcion = id;//en id vienen los campos modificados
                    //Crud_Catalogos("usuariosLog", catalogo, "post", "", usuariosLogObject);
                    usuariosLogObject.descripcion = cambios.camposmodificados; //en id vienen los campos modificados
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      usuariosLogObject
                    );
                    if (enviar && returnVal.id) {
                      EnviarArroba(3);
                    }
                    upImage = null;
                    comentarioArroba = null;
                  }
                  break;
                case 'terrestreMovimientos':
                  {
                    NotificationManager.info(
                      'Se Terminara el movimiento',
                      'Actualizacion, mov (' +
                        returnVal.id +
                        ') por Usuario: ' +
                        user.id
                    );
                    usuariosLogObject.idBitacoraMonitoreo = 5;
                    usuariosLogObject.idUsuario = user.id;
                    usuariosLogObject.idMovimiento = returnVal.id;
                    usuariosLogObject.idPlataformaTipo = 2;
                    usuariosLogObject.accionFecha = myFormato(new Date());
                    usuariosLogObject.descripcion =
                      'Finalizado Movimiento id:' +
                      returnVal.id +
                      ' por Usuario id: ' +
                      user.id;
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      usuariosLogObject,
                      '',
                      '',
                      hiddenmodal,
                      ''
                    );
                  }
                  break;
                case 'entregas':
                  {
                    let entregasTem = entregas;
                    entregasTem.map((entre, index) => {
                      if (returnVal.idEntrega == entre.idEntrega) {
                        usuariosLogObject.idBitacoraMonitoreo = 3;
                        usuariosLogObject.idUsuario = user.id;
                        usuariosLogObject.idMovimiento = returnVal.idMovimiento; //obj.id;//returnVal.id;
                        usuariosLogObject.idPlataformaTipo = 2;
                        usuariosLogObject.accionFecha = myFormato(new Date());
                        usuariosLogObject.descripcion =
                          'Actualizacion Entrega: ' +
                          entre.idEntrega +
                          ' Movimiento id:' +
                          returnVal.idMovimiento +
                          ' Campo: ' +
                          entre.campo; //returnVal.id;
                        entre = returnVal;
                        if (returnVal.estatus == 1) {
                          setIdEntregaActiva(returnVal);
                        }
                        setEntregas(entregasTem);
                        NotificationManager.info(
                          'Se modifica entreg@',
                          'Actualizacion, mov (' + returnVal.idMovimiento + ')'
                        );
                      }
                    });
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      usuariosLogObject,
                      '',
                      '',
                      hiddenmodal,
                      ''
                    );
                  }
                  break;
                case 'updateFechasFull':
                  {
                    NotificationManager.info(
                      'Se ha actualizado movimiento en full',
                      'Actualizacion, mov (' + returnVal.id + ')'
                    );
                    let cambios = getCambiosNav(id, returnVal); //{bitacora: bitacora , camposmodificados: camposmodificados }
                    usuariosLogObject.idBitacoraMonitoreo = cambios.bitacora;
                    usuariosLogObject.idUsuario = user.id;
                    usuariosLogObject.idMovimiento = returnVal.id;
                    usuariosLogObject.idPlataformaTipo = 1;
                    usuariosLogObject.accionFecha = myFormato(new Date());
                    usuariosLogObject.descripcion = cambios.camposmodificados;
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      usuariosLogObject
                    );
                  }
                  break;
                default:
                  break;
              }
            }
            break;
          case 'delete':
            {
              switch (stateVar) {
                case 'navieraMovimientos':
                  {
                    if (returnVal) {
                      NotificationManager.error(
                        'Se ha eliminado el mov: ' +
                          id +
                          ', imposible recuperar',
                        'Borrado'
                      );
                    } else {
                      NotificationManager.error(
                        'No se ha eliminado el mov: ' + id + ', error',
                        'Error'
                      );
                    }
                    usuariosLogObject.idBitacoraMonitoreo = 1;
                    usuariosLogObject.idUsuario = user.id;
                    usuariosLogObject.idMovimiento = id;
                    usuariosLogObject.idPlataformaTipo = 1;
                    usuariosLogObject.accionFecha = myFormato(new Date());
                    usuariosLogObject.descripcion =
                      'Movimiento ' + id + ', eliminado(' + returnVal + ')'; //en id vienen los campos modificados
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      usuariosLogObject
                    );
                  }
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log(err);
        }
      });
  };

  const getCambiosNav = (movimientoOld, MovimientoUpdated) => {
    //1	Agregar movimiento *
    ///2incidencias        *
    //3	Actualizar horario *
    //4	Alertas            *
    ///5rastreame          *
    //6	Envío Arroba       *
    //7	Envío Reacción     *
    ///8ruta               *
    ///9 sello             *
    ///10contenedor        *
    ///11 otd              *
    ///12 cliente          *
    ///13 Actualizar Full  *
    ///14 movimiento tipo **
    ///15 OperacionTipo **
    ///16 Ruta2 **

    let camposmodificados = '';
    let getCampoMod = '';
    let bitacora = 3;
    if (3 === MovimientoUpdated.idOperacionEstatus.id) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      camposmodificados = camposmodificados + 'Se finaliza Movimiento )';
    } //Finalizado
    if (4 === MovimientoUpdated.idOperacionEstatus.id) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      camposmodificados = camposmodificados + 'Se Elimina movimiento )';
    } //Finalizado

    if (MovimientoUpdated.idRuta) {
      if (movimientoOld.idRuta.id) {
        if (movimientoOld.idRuta.id != MovimientoUpdated.idRuta.id) {
          bitacora = 8;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ',  ';
          }
          camposmodificados = camposmodificados + '( ';
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta.nombre +
            ' a ' +
            MovimientoUpdated.idRuta.nombre +
            ')';
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta.nombre + ')';
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOtd) {
      if (movimientoOld.idOtd.id != MovimientoUpdated.idOtd.id) {
        bitacora = 11;
        if (camposmodificados.lenght > 0) {
          camposmodificados = camposmodificados + ',  ';
        }
        camposmodificados = camposmodificados + '( ';
        camposmodificados =
          camposmodificados +
          movimientoOld.idOtd.nombre +
          ' a ' +
          MovimientoUpdated.idOtd.nombre +
          ')';
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idCliente) {
      if (movimientoOld.idCliente.id) {
        if (movimientoOld.idCliente.id != MovimientoUpdated.idCliente.id) {
          bitacora = 12;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ',  ';
          }
          camposmodificados = camposmodificados + '( ';
          camposmodificados =
            camposmodificados +
            movimientoOld.idCliente.nombre +
            ' a ' +
            MovimientoUpdated.idCliente.nombre +
            ')';
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idCliente.nombre + ')';
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idMovimientoTipo) {
      if (movimientoOld.idMovimientoTipo.id) {
        if (
          movimientoOld.idMovimientoTipo.id !=
          MovimientoUpdated.idMovimientoTipo.id
        ) {
          bitacora = 14;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ',  ';
          }
          camposmodificados = camposmodificados + '( ';
          camposmodificados =
            camposmodificados +
            movimientoOld.idMovimientoTipo.nombre +
            ' a ' +
            MovimientoUpdated.idMovimientoTipo.nombre +
            ')';
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idMovimientoTipo.nombre + ')';
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOperacionTipo) {
      if (movimientoOld.idOperacionTipo.id) {
        if (
          movimientoOld.idOperacionTipo.id !=
          MovimientoUpdated.idOperacionTipo.id
        ) {
          bitacora = 15;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ',  ';
          }
          camposmodificados = camposmodificados + '( ';
          camposmodificados =
            camposmodificados +
            movimientoOld.idOperacionTipo.nombre +
            ' a ' +
            MovimientoUpdated.idOperacionTipo.nombre +
            ')';
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idOperacionTipo.nombre + ')';
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idRuta2) {
      if (movimientoOld.idRuta2.id) {
        if (movimientoOld.idRuta2.id != MovimientoUpdated.idRuta2.id) {
          bitacora = 16;
          if (camposmodificados.lenght > 0) {
            camposmodificados = camposmodificados + ',  ';
          }
          camposmodificados = camposmodificados + '( ';
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta2.nombre +
            ' a ' +
            MovimientoUpdated.idRuta2.nombre +
            ')';
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ')';
      }
    }

    if (movimientoOld.citaProgramada != MovimientoUpdated.citaProgramada) {
      bitacora = 3;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.citaProgramada) {
        camposmodificados =
          camposmodificados +
          movimientoOld.citaProgramada +
          ' a ' +
          MovimientoUpdated.citaProgramada +
          ')';
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.citaProgramada + ')';
      }
    }
    if (movimientoOld.sello != MovimientoUpdated.sello) {
      bitacora = 9;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.sello) {
        camposmodificados =
          camposmodificados +
          movimientoOld.sello +
          ' a ' +
          MovimientoUpdated.sello +
          ')';
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.sello + ')';
      }
    } //No aplica a full
    if (movimientoOld.po != MovimientoUpdated.po) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.po) {
        camposmodificados =
          camposmodificados +
          movimientoOld.po +
          ' a ' +
          MovimientoUpdated.po +
          ')';
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.po + ')';
      }
    } //No aplica a full
    if (movimientoOld.booking != MovimientoUpdated.booking) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.booking) {
        camposmodificados =
          camposmodificados +
          movimientoOld.booking +
          ' a ' +
          MovimientoUpdated.booking +
          ')';
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.booking + ')';
      }
    } //No aplica a full
    if (movimientoOld.estatusActual != MovimientoUpdated.estatusActual) {
      bitacora = 1;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.estatusActual) {
        camposmodificados =
          camposmodificados +
          movimientoOld.estatusActual +
          ' a ' +
          MovimientoUpdated.estatusActual +
          ')';
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.estatusActual + ')';
      }
    } //No aplica a full

    if (movimientoOld.contenedor != MovimientoUpdated.contenedor) {
      bitacora = 10;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.contenedor) {
        camposmodificados =
          camposmodificados +
          movimientoOld.contenedor +
          ' a ' +
          MovimientoUpdated.contenedor +
          ')';
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.contenedor + ')';
      }
    } //No aplica afull
    if (movimientoOld.actualizarFull != MovimientoUpdated.actualizarFull) {
      bitacora = 13;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      camposmodificados =
        camposmodificados +
        movimientoOld.actualizarFull +
        ' a ' +
        MovimientoUpdated.actualizarFull +
        ')';
    }

    if (movimientoOld.idRuta2 != MovimientoUpdated.idRuta2) {
      bitacora = 16;
      if (camposmodificados.lenght > 0) {
        camposmodificados = camposmodificados + ',  ';
      }
      camposmodificados = camposmodificados + '( ';
      if (movimientoOld.idRuta2) {
        camposmodificados =
          camposmodificados +
          movimientoOld.idRuta2.nombre +
          ' a ' +
          MovimientoUpdated.idRuta2.nombre +
          ')';
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ')';
      }
    }
    camposLogisticosNav.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case 'arribo_terminal_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboTerminalVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_terminal_lleno':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaTerminalLleno',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_cliente_lleno':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboClienteLleno',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_cliente_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaClienteVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_terminal_vacio_2':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboTerminalVacio2',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'asignacion_piloto':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'asignacionPiloto',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'inicio_ruta':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'inicioRuta',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;

        case 'inicio_ruta_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'inicioRutaVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;

        case 'ingreso_cliente':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'ingresoCliente',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;

        case 'ingreso_bodega_carga':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'ingresoBodegaCarga',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;

        case 'despacho_predio_cliente':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'despachoPredioCliente',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'llegada_frontera':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'llegadaFrontera',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_frontera':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaFrontera',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_aduana':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboAduana',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_aduana':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaAduana',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        /**
         * Exportacion
         */
        case 'retiro_contenedor':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'retiroContenedor',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_cliente_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboClienteVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_cliente_lleno':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaClienteLleno',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_terminal_cargado':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboTerminalCargado',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_predio_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaPredioVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'cita_fecha_despacho':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'citaFechaDespacho',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'entrada_ciudad_portuaria':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'entradaCiudadPortuaria',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'arribo_patio_linea':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'arriboPatioLinea',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        case 'salida_terminal_vacio':
          {
            getCampoMod = validarLogisTicos(
              movimientoOld,
              MovimientoUpdated,
              camposmodificados,
              'salidaTerminalVacio',
              cLog.nombre
            );
            if (getCampoMod !== camposmodificados) {
              bitacora = 3;
              camposmodificados = getCampoMod;
            }
          }
          break;
        default:
          break;
      }
    });

    return { bitacora: bitacora, camposmodificados: camposmodificados };
  };
  const validarLogisTicos = (
    movimientoOld,
    MovimientoUpdated,
    camposmodificados,
    campo,
    labelLog
  ) => {
    let camposmodificadosTemp = camposmodificados;
    if (movimientoOld[campo] !== MovimientoUpdated[campo]) {
      if (camposmodificadosTemp.lenght > 0) {
        camposmodificadosTemp = camposmodificadosTemp + ',  ';
      }
      camposmodificadosTemp = camposmodificadosTemp + '(  ' + labelLog + ': ';
      if (movimientoOld[campo]) {
        camposmodificadosTemp =
          camposmodificadosTemp +
          movimientoOld[campo] +
          ' a ' +
          MovimientoUpdated[campo] +
          ' )';
      } else {
        camposmodificadosTemp =
          camposmodificadosTemp + MovimientoUpdated[campo] + ' )';
      }
    }
    return camposmodificadosTemp;
  };

  const EnviarArroba = (acumulador) => {
    var objetoLog = {
      id: '',
      idBitacoraMonitoreo: 0,
      idUsuario: user.id,
      idMovimiento: 0,
      accionFecha: '',
      descripcion: '',
      idPlataformaTipo: 0,
    };
    objetoLog.idBitacoraMonitoreo = 6;
    objetoLog.accionFecha = myFormato(new Date());
    switch (acumulador) {
      case 3:
        {
          if (plataformaTipo === 'Terrestre') {
            ArrobaTerrestre(getMovimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_azul')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 2;
              objetoLog.descripcion =
                'Arroba Azul (' + destinatarios.destinatarios3 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
          } else {
            //Si es AAAPumac, es full y actualiza en full(Entonses se envian los 2 mov en un solo correo9)
            if (
              [61, 754].includes(getMovimiento.idPlataforma.id) &&
              getMovimiento.actualizarFull &&
              movimientoFull
            ) {
              ArrobaFull(
                getMovimiento.idPlataforma.id,
                [getMovimiento.id, movimientoFull],
                acumulador,
                1,
                upImage,
                comentarioArroba
              ).then((returnVal) => {
                getfuntion(
                  'get',
                  '',
                  '',
                  'movimientoAcumuladores/mov/' + getMovimiento.id,
                  'acumuladores'
                );
                NotificationManager.info(returnVal, 'arroba_azul');
                objetoLog.idMovimiento = getMovimiento.id;
                objetoLog.idPlataformaTipo = 1;
                objetoLog.descripcion =
                  'Arroba Azul (' + destinatarios.destinatarios3 + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  objetoLog,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
                let logFull = { ...objetoLog };
                logFull.idMovimiento = getMovimiento.id;
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  logFull,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
              });
            } else {
              //Envio normal
              Arroba(
                getMovimiento.idPlataforma.id,
                getMovimiento.id,
                acumulador,
                1,
                upImage,
                comentarioArroba
              ).then((returnVal) => {
                getfuntion(
                  'get',
                  '',
                  '',
                  'movimientoAcumuladores/mov/' + getMovimiento.id,
                  'acumuladores'
                );
                NotificationManager.info(
                  returnVal,
                  privilegioDescripcion('arroba_azul')
                );
                objetoLog.idMovimiento = getMovimiento.id;
                objetoLog.idPlataformaTipo = 1;
                objetoLog.descripcion =
                  'Arroba Azul (' + destinatarios.destinatarios3 + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  objetoLog,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
                if (getMovimiento.actualizarFull && movimientoFull) {
                  Arroba(
                    getMovimiento.idPlataforma.id,
                    movimientoFull,
                    acumulador,
                    1,
                    upImage,
                    comentarioArroba
                  ).then((returnVal) => {
                    NotificationManager.info(
                      'movimiento full(' + movimientoFull + ') ' + returnVal,
                      privilegioDescripcion('arroba_azul')
                    );
                    objetoLog.idMovimiento = movimientoFull;
                    objetoLog.idPlataformaTipo = 1;
                    objetoLog.descripcion =
                      'Arroba Azul (' + destinatarios.destinatarios3 + ')';
                    Crud_Catalogos(
                      'usuariosLog',
                      catalogo,
                      'post',
                      '',
                      objetoLog,
                      '',
                      '',
                      hiddenmodal,
                      ''
                    );
                  });
                }
              });
            }
          }
        }
        break;
      case 4:
        {
          if (plataformaTipo === 'Terrestre') {
            ArrobaTerrestre(getMovimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_roja')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 2;
              objetoLog.descripcion =
                'Arroba roja (' + destinatarios.destinatarios3 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
          } else {
            Arroba(
              getMovimiento.idPlataforma.id,
              getMovimiento.id,
              acumulador
            ).then((returnVal) => {
              getfuntion(
                'get',
                '',
                '',
                'movimientoAcumuladores/mov/' + getMovimiento.id,
                'acumuladores'
              );
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_roja')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                'Arroba Roja (' + destinatarios.destinatarios4 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
            if (movimiento.actualizarFull && movimientoFull) {
              Arroba(
                getMovimiento.idPlataforma.id,
                movimientoFull,
                acumulador
              ).then((returnVal) => {
                NotificationManager.info(
                  'movimiento full(' + movimientoFull + ') ' + returnVal,
                  privilegioDescripcion('arroba_roja')
                );
                objetoLog.idMovimiento = movimientoFull;
                objetoLog.idPlataformaTipo = 1;
                objetoLog.descripcion =
                  'Arroba Roja (' + destinatarios.destinatarios4 + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  objetoLog,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
              });
            }
          }
        }
        break;
      case 5:
        {
          if (plataformaTipo === 'Terrestre') {
            ArrobaTerrestre(getMovimiento.id, acumulador).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_negra')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 2;
              objetoLog.descripcion =
                'Arroba negra (' + destinatarios.destinatarios3 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
          } else {
            Arroba(
              getMovimiento.idPlataforma.id,
              getMovimiento.id,
              acumulador
            ).then((returnVal) => {
              getfuntion(
                'get',
                '',
                '',
                'movimientoAcumuladores/mov/' + getMovimiento.id,
                'acumuladores'
              );
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_negra')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                'Arroba Negra (' + destinatarios.destinatarios5 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
            if (getMovimiento.actualizarFull && movimientoFull) {
              Arroba(
                getMovimiento.idPlataforma.id,
                movimientoFull,
                acumulador
              ).then((returnVal) => {
                NotificationManager.info(
                  'movimiento full(' + movimientoFull + ') ' + returnVal,
                  privilegioDescripcion('arroba_negra')
                );
                objetoLog.idMovimiento = movimientoFull;
                objetoLog.idPlataformaTipo = 1;
                objetoLog.descripcion =
                  'Arroba Negra (' + destinatarios.destinatarios5 + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  objetoLog,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
              });
            }
          }
        }
        break;
      case 6:
        {
          if (plataformaTipo !== 'Terrestre') {
            Arroba(
              getMovimiento.idPlataforma.id,
              getMovimiento.id,
              acumulador
            ).then((returnVal) => {
              NotificationManager.info(
                returnVal,
                privilegioDescripcion('arroba_amarilla')
              );
              objetoLog.idMovimiento = getMovimiento.id;
              objetoLog.idPlataformaTipo = 1;
              objetoLog.descripcion =
                'Arroba Amarilla (' + destinatarios.destinatarios6 + ')';
              Crud_Catalogos(
                'usuariosLog',
                catalogo,
                'post',
                '',
                objetoLog,
                '',
                '',
                hiddenmodal,
                ''
              );
            });
            if (getMovimiento.actualizarFull && movimientoFull) {
              Arroba(
                getMovimiento.idPlataforma.id,
                movimientoFull,
                acumulador
              ).then((returnVal) => {
                getfuntion(
                  'get',
                  '',
                  '',
                  'movimientoAcumuladores/mov/' + getMovimiento.id,
                  'acumuladores'
                );
                NotificationManager.info(
                  'movimiento full(' + movimientoFull + ') ' + returnVal,
                  privilegioDescripcion('arroba_amarilla')
                );
                objetoLog.idMovimiento = movimientoFull;
                objetoLog.idPlataformaTipo = 1;
                objetoLog.descripcion =
                  'Arroba Amarilla (' + destinatarios.destinatarios6 + ')';
                Crud_Catalogos(
                  'usuariosLog',
                  catalogo,
                  'post',
                  '',
                  objetoLog,
                  '',
                  '',
                  hiddenmodal,
                  ''
                );
              });
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const abrirmodalGps = (gps = '') => {
    let gpsRecienteTemp = { ...gpsReciente[getMovimiento.id] };

    if (gps) {
      gpsRecienteTemp = gps;
    }
    let plataformaIdGg = null;
    if (plataformaTipo === 'Naviera') {
      if (getMovimiento.idPlataforma) {
        if (getMovimiento.idPlataforma.idGg) {
          plataformaIdGg = getMovimiento.idPlataforma.idGg;
        }
      }
    } else {
      if (getMovimiento.plataforma) {
        if (getMovimiento.plataforma.idGg) {
          plataformaIdGg = getMovimiento.plataforma.idGg;
        }
      }
    }
    if (plataformaTipo === 'Naviera') {
      if (plataformaIdGg && gpsRecienteTemp) {
        getfuntion(
          'get',
          gpsRecienteTemp,
          '',
          'navieraMovimientos/plataforma/' +
            plataformaIdGg +
            '/gps/' +
            gpsRecienteTemp.idGg,
          'urlGps'
        );
      }
    } else {
      getfuntion(
        'get',
        gpsRecienteTemp,
        '',
        'navieraMovimientos/plataforma/' +
          plataformaIdGg +
          '/gps/' +
          gpsRecienteTemp.idGg,
        'urlGps'
      );
    }
  };
  const getIncidenciaNombre = (id) => {
    for (let item of incidencias) {
      if (id === item.id) {
        return item.nombre;
      }
    }
    return '';
  };
  const getFuncionalidad = (elemento) => {
    let movActivo = getMovimiento;
    if (movActivo) {
      if (movActivo.idPlataforma) {
        if (PlataformasPerfiles) {
          if (PlataformasPerfiles[movActivo.idPlataforma.id]) {
            let funcionalidadesTemp =
              PlataformasPerfiles[movActivo.idPlataforma.id].funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
      if (movActivo.plataforma) {
        if (PlataformasPerfiles) {
          if (PlataformasPerfiles[movActivo.plataforma.id]) {
            let funcionalidadesTemp =
              PlataformasPerfiles[movActivo.plataforma.id].funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };
  return (
    <div className="m-0">
      <Card>
        <Card.Header>
          <Card.Title>Panel de Información</Card.Title>
          <Card.Options>
            {getMovimiento.id ? (
              <div className="mt-2">
                <Grid.Row>
                  <Grid.Col md={12}>
                    <span className="badge float-right text-dark">
                      {movimientoFull
                        ? getMovimiento.actualizarFull
                          ? 'Actualizara al mov: ' + movimientoFull
                          : 'En full con: ' + movimientoFull
                        : null}
                    </span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col md={12}>
                    {getFuncionalidad('Arroba Azul') ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Bitacora movimineto'}
                        hidden={false}
                      >
                        <ModalLay
                          title={'Bitacora del Movimiento: ' + getMovimiento.id}
                          name={
                            <label
                              style={{ color: 'green', 'font-size': '200%' }}
                            >
                              <Icon name="list" />
                            </label>
                          }
                          formulario={BitacoraMov}
                          datosForm={{
                            Tipo: plataformaTipo,
                            Movimiento: getMovimiento,
                          }}
                        />
                      </span>
                    ) : null}
                    {plataformaTipo === 'Naviera' &&
                    [1, 3].includes(
                      getMovimiento.idPlataforma.plataformaTipo.id
                    )
                      ? unidad.map((u, index) => (
                          <span
                            className="badge"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              'Video de unidad (' +
                              u.nombre +
                              '), ' +
                              (videoDispositivoEstatus[u.id] &&
                                videoDispositivoEstatus[u.id].estatus +
                                  'En linea',
                              'Fuera de linea')
                            }
                            hidden={!u.idVideo}
                          >
                            <i
                              className="fa fa-video-camera"
                              style={{
                                color:
                                  videoDispositivoEstatus[u.id] &&
                                  videoDispositivoEstatus[u.id].estatus
                                    ? 'green'
                                    : 'red',
                                'font-size': '200%',
                              }}
                              onClick={() =>
                                window.open(
                                  conf.cctv +
                                    '/live?token=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=&dispositivo=' +
                                    u.idVideo, //+u.unidad.idVideo,
                                  u.nombre,
                                  'width=1200,height=800,scrollbars=NO'
                                )
                              }
                            ></i>
                          </span>
                        ))
                      : null}
                    {plataformaTipo === 'Terrestre' &&
                    [2].includes(getMovimiento.plataforma.plataformaTipo.id)
                      ? unidad.map((u, index) => (
                          <span
                            className="badge"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={'Video de unidad (' + u.unidad.nombre + ')'}
                            hidden={!u.unidad.idVideo}
                          >
                            <i
                              className="fa fa-video-camera"
                              style={{ color: 'green', 'font-size': '200%' }}
                              onClick={() =>
                                window.open(
                                  conf.cctv +
                                    '/live?token=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=&dispositivo=' +
                                    u.unidad.idVideo, //+u.unidad.idVideo,
                                  u.unidad.nombre,
                                  'width=1200,height=800,scrollbars=NO'
                                )
                              }
                            ></i>
                          </span>
                        ))
                      : null}
                    {getFuncionalidad('solicitudes') &&
                    getMovimiento.idPlataforma &&
                    [4].includes(getMovimiento.idPlataforma.id) ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Solicitudes'}
                        hidden={false}
                      >
                        <ModalLay
                          title={'Solicitudes'}
                          name={
                            <label
                              style={{ color: 'green', 'font-size': '200%' }}
                            >
                              <i className="fa fa-envelope">
                                {solicitudesNavierasContar}
                              </i>
                            </label>
                          }
                          formulario={SolicitudesNavieras}
                          datosForm={{
                            SolicitudesNavierasContar:
                              SolicitudesNavierasContar,
                          }}
                        />
                      </span>
                    ) : null}
                    {getFuncionalidad('Arroba Azul') &&
                    estaPrivilegioActivo('arroba_azul') ? (
                      <span
                        className="badge"
                        onClick={() => {
                          EnviarArroba(3);
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={
                          privilegio.descripcion +
                          ': ' +
                          destinatarios.destinatarios3
                        }
                      >
                        <label style={{ color: 'blue', 'font-size': '200%' }}>
                          <Icon name={'at-sign'} />
                          {acumuladores[3] ? acumuladores[3].total : 0}
                        </label>
                      </span>
                    ) : null}
                    {getFuncionalidad('Arroba Roja') &&
                    estaPrivilegioActivo('arroba_roja') ? (
                      <span
                        className="badge"
                        onClick={() => {
                          EnviarArroba(4);
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={
                          privilegio.descripcion +
                          ': ' +
                          destinatarios.destinatarios4
                        }
                      >
                        <label style={{ color: 'red', 'font-size': '200%' }}>
                          <Icon name={'at-sign'} />
                          {acumuladores[4] ? acumuladores[4].total : 0}
                        </label>
                      </span>
                    ) : null}
                    {getFuncionalidad('Arroba Negra') &&
                    estaPrivilegioActivo('arroba_negra') ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={
                          privilegio.descripcion +
                          ': ' +
                          destinatarios.destinatarios5
                        }
                      >
                        <label style={{ color: 'black', 'font-size': '200%' }}>
                          <Icon name={'at-sign'} />
                          {acumuladores[5] ? acumuladores[5].total : 0}
                        </label>
                      </span>
                    ) : null}
                    {getFuncionalidad('Arroba Amarilla') &&
                    estaPrivilegioActivo('arroba_amarilla') ? (
                      <span
                        className="badge"
                        onClick={() => {
                          EnviarArroba(6);
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={
                          privilegio.descripcion +
                          ': ' +
                          destinatarios.destinatarios6
                        }
                      >
                        <label
                          style={{ color: '#c1c804', 'font-size': '200%' }}
                        >
                          <Icon name={'at-sign'} />
                          {acumuladores[6] ? acumuladores[6].total : 0}
                        </label>
                      </span>
                    ) : null}

                    {plataformaTipo === 'Naviera' &&
                    [3].includes(
                      getMovimiento.idPlataforma.plataformaTipo.id
                    ) ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Gps espejo'}
                      >
                        <ModalLay
                          title={
                            'Gps espejo(el GPS ya debe estar asignado en otra plataforma) '
                          }
                          name={
                            <label>
                              <i
                                className="fa fa-compass"
                                style={{ color: 'orange', 'font-size': '200%' }}
                              ></i>
                            </label>
                          }
                          formulario={GpsEspejo}
                          datosForm={{
                            movimiento: getMovimiento,
                          }}
                        />{' '}
                      </span>
                    ) : null}

                    {getFuncionalidad('Rastreame') ? ( //&& estaPrivilegioActivo("rastreame")
                      <span
                        className="badge"
                        onClick={() => abrirmodalGps()}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Ver tracking'}
                      >
                        <label style={{ color: 'black', 'font-size': '200%' }}>
                          <Icon name="map-pin" />
                        </label>
                      </span>
                    ) : null}
                    {getFuncionalidad('BitacoraPdf') && (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Bitacora a PDF'}
                      >
                        <ModalLay
                          title={'Bitacora del viaje electrónica: '}
                          name={
                            <label
                              style={{ color: 'orange', 'font-size': '200%' }}
                            >
                              <Icon name="list" />
                            </label>
                          }
                          formulario={BitacoraPdf}
                          datosForm={{
                            Movimiento: getMovimiento,
                          }}
                        />{' '}
                      </span>
                    )}

                    {getMovimiento.idPlataforma?.plataformaTipo?.id === 1 ||
                    getMovimiento.idPlataforma?.plataformaTipo?.id === 3 ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Evidencias colocación'}
                      >
                        <ModalLay
                          tamanio={1200}
                          title="Evidencias colocación"
                          name={
                            <span
                              style={{
                                color: '#63e67b',
                                'font-size': '200%',
                              }}
                            >
                              <i className="fa fa-picture-o" />
                            </span>
                          }
                          formulario={MovimientoGpsEvidenciaModal}
                          datosForm={{movimiento_gps_id: getMovimiento?.movimientoGps[0]?.id}}
                        />
                      </span>
                    ) : null}

                    {getFuncionalidad('Reaccion') &&
                    estaPrivilegioActivo('reaccion') &&
                    [61, 9, 12].includes(
                      getMovimiento.idPlataforma
                        ? getMovimiento.idPlataforma.id
                        : getMovimiento.plataforma.id
                    ) ? (
                      <Link
                        className="mt-n1"
                        to={{
                          pathname: `https://reaccion.skyangel.com.mx/reaccion?${new URLSearchParams(
                            plataformaTipo === 'Naviera'
                              ? {
                                  latitud: getMovimiento?.movimientoGps[0]?.gps
                                    ?.id
                                    ? gpsEstatusNavieros[
                                        getMovimiento?.movimientoGps[0]?.gps?.id
                                      ]?.latitud
                                    : '',
                                  longitud: getMovimiento?.movimientoGps[0]?.gps
                                    ?.id
                                    ? gpsEstatusNavieros[
                                        getMovimiento?.movimientoGps[0]?.gps?.id
                                      ]?.longitud
                                    : '',
                                  marca: '',
                                  placa: '',
                                  modelo: '',
                                  color: '',
                                  economico: getMovimiento?.eco ?? '',
                                  caja: '',
                                  operador:
                                    getMovimiento?.idTransportista
                                      ?.contactoNombre ?? '',
                                  medio:
                                    getMovimiento?.idTransportista?.telefono1 ??
                                    '',
                                  origen:
                                    getMovimiento?.idMovimientoTipo === 1 ||
                                    getMovimiento?.idMovimientoTipo === 3
                                      ? getMovimiento?.idCorredor?.nombre
                                      : getMovimiento?.idCliente?.domicilio,
                                  destino:
                                    getMovimiento?.idMovimientoTipo === 2 ||
                                    getMovimiento?.idMovimientoTipo === 4
                                      ? getMovimiento?.idCorredor?.nombre
                                      : getMovimiento?.idCliente?.domicilio,
                                  transportista:
                                    getMovimiento?.idTransportista?.nombre ??
                                    '',
                                  plataforma:
                                    getMovimiento?.idPlataforma?.nombre ?? '',
                                  movimiento_externo_id:
                                    getMovimiento?.id ?? '',
                                  estatus_carga: '',
                                  usuario_externo_id: user.id,
                                  usuario_externo_nombre: [
                                    user.persona.nombre,
                                    user.persona.aPaterno,
                                    user.persona.aMaterno,
                                  ].join(' '),
                                  sistema_id: 2,
                                }
                              : {
                                  latitud: getMovimiento?.movimientoGps[0]?.gps
                                    ?.id
                                    ? gpsEstatusTerrestres[
                                        getMovimiento?.movimientoGps[0]?.gps?.id
                                      ]?.latitud
                                    : '',
                                  longitud: getMovimiento?.movimientoGps[0]?.gps
                                    ?.id
                                    ? gpsEstatusTerrestres[
                                        getMovimiento?.movimientoGps[0]?.gps?.id
                                      ]?.longitud
                                    : '',
                                  marca: unidad?.tractoMarca ?? '',
                                  placa: unidad?.tractoPlaca ?? '',
                                  modelo: unidad?.tractoModelo ?? '',
                                  color: unidad?.tractoColor ?? '',
                                  economico: getMovimiento?.economico ?? '',
                                  caja: getMovimiento?.caja ?? '',
                                  operador:
                                    getMovimiento?.operador !== undefined
                                      ? getMovimiento?.operador?.nombre +
                                        ' ' +
                                        getMovimiento?.operador?.paterno +
                                        ' ' +
                                        getMovimiento?.operador?.materno
                                      : '',
                                  medio:
                                    getMovimiento?.operador !== undefined
                                      ? getMovimiento?.operador
                                          .medioComunicacion
                                      : '',
                                  origen: getMovimiento?.origen ?? '',
                                  destino:
                                    entregas.find((entrega) => entrega.estatus)
                                      ?.direccion ?? '',
                                  transportista:
                                    getMovimiento?.transportista?.nombre ?? '',
                                  plataforma:
                                    getMovimiento?.plataforma?.nombre ?? '',
                                  movimiento_externo_id:
                                    getMovimiento?.id ?? '',
                                  estatus_carga: getMovimiento?.deRegreso
                                    ? 'Con carga'
                                    : 'Sin carga',
                                  usuario_externo_id: user.id,
                                  usuario_externo_nombre: [
                                    user.persona.nombre,
                                    user.persona.aPaterno,
                                    user.persona.aMaterno,
                                  ].join(' '),
                                  sistema_id: 3,
                                }
                          ).toString()}`,
                        }}
                        target="_blank"
                      >
                        <label style={{ color: 'red', 'font-size': '200%' }}>
                          <Icon prefix="fa" name="warning" />
                        </label>
                      </Link>
                    ) : (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={privilegio.descripcion}
                      >
                        <ModalLay
                          tamanio={'95%'}
                          title="Agregar Reacción"
                          name={
                            <label
                              style={{
                                color: 'black',
                                'font-size': '200%',
                              }}
                            >
                              <Icon prefix="fa" name="warning" />
                            </label>
                          }
                          datosForm={{
                            movimiento: getMovimiento.id,
                            plataforma:
                              plataformaTipo === 'Naviera'
                                ? getMovimiento.idPlataforma.id
                                : getMovimiento.plataforma.id,
                            movimiento: getMovimiento.id,
                            plataformaTipo: plataformaTipo,
                            getMovimiento: getMovimiento,
                            incidenciasTotales: incidencias,
                            entregas: entregas,
                            unidad: unidad,
                          }}
                          formulario={
                            plataformaTipo === 'Naviera'
                              ? ReaccionForm
                              : Reaccion
                          }
                        />
                      </span>
                    )}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Naviera' ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Actualizar horarios"
                      >
                        <ModalLay
                          tamanio={1200}
                          title="Actualizar horario"
                          name={
                            <span
                              style={{ color: 'blue', 'font-size': '200%' }}
                            >
                              <Icon name="upload" />
                            </span>
                          }
                          formulario={ActualizarHorariosNavieros}
                          datosForm={{
                            accion: 'modificar',
                            idusuario: user.id,
                            movimiento: getMovimiento,
                            getfuntion: getfuntion,
                            stor: { privilegios_object, operacionesEstatus },
                            camposLogisticosNav: camposLogisticosNav,
                            rutasEstatusObject: rutasEstatus,
                            operacionesEstatus: operacionesEstatus,
                            clientes_object: clientes_object,
                            otd_object: otd_object,
                            full: movimientoFull,
                            destinatarios: destinatarios.destinatarios3,
                            setImagen: setImagen,
                            setComentario: setComentario,
                          }}
                        />
                      </span>
                    ) : null}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Naviera' ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Nueva incidencia"
                      >
                        <ModalLay
                          tamanio={800}
                          title="Nueva incidencia"
                          name={
                            <span
                              style={{ color: 'blue', 'font-size': '200%' }}
                            >
                              <Icon name="alert-circle" />
                            </span>
                          }
                          formulario={MovimientoIncidencias}
                          datosForm={{
                            accion: 'nuevo',
                            idusuario: user.id,
                            idmovimiento: getMovimiento.id,
                            movimiento: getMovimiento,
                            getfuntion: getfuntion,
                            stor: { privilegios_object },
                            plataformaTipo: plataformaTipo,
                            closeModal: hiddenmodal,
                            movimientoFull: movimientoFull
                              ? MovimientosFull[movimientoFull]
                                ? MovimientosFull[movimientoFull].movimiento
                                : null
                              : null,
                            gpsReciente: gpsReciente
                              ? gpsReciente[getMovimiento.id]
                              : null,
                            latitud: null,
                            longitud: null,
                          }}
                        />
                      </span>
                    ) : null}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Terrestre' ? ( //820 NMP
                      getMovimiento.plataforma &&
                      [25, 820].includes(getMovimiento.plataforma.id) ? (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Actualizar horarios"
                        >
                          <ModalLay
                            tamanio={1200}
                            title="Actualizar horarios"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="upload" />
                              </span>
                            }
                            formulario={ActualizarHorariosNMP}
                            datosForm={{
                              accion: 'modificar',
                              idusuario: user.id,
                              movimiento: getMovimiento,
                              getfuntion: getfuntion,
                              stor: { privilegios_object, operacionesEstatus },
                              rutasEstatusObject: rutasEstatus,
                              operacionesEstatus: operacionesEstatus,
                            }}
                          />
                        </span>
                      ) : null
                    ) : null}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Terrestre' ? ( //&&
                      idEntregaActiva ? (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Actualizar horarios"
                        >
                          <ModalLay
                            tamanio={1200}
                            title="Actualizar horario"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="upload" />
                              </span>
                            }
                            formulario={ActualizarHorariosTerrestres}
                            datosForm={{
                              accion: 'modificar',
                              idusuario: user.id,
                              movimiento: getMovimiento,
                              idEntregaActiva: idEntregaActiva,
                              idEntrega: idEntrega,
                              getfuntion: getfuntion,
                              stor: { privilegios_object, operacionesEstatus },
                              rutasEstatusObject: rutasEstatus,
                              operacionesEstatus: operacionesEstatus,
                            }}
                          />
                        </span>
                      ) : null
                    ) : null}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Naviera' &&
                    getMovimiento.idPlataforma ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Operador"
                      >
                        {getMovimiento.idPlataforma.plataformaTipo.id === 3 ? (
                          <ModalLay
                            tamanio={1200}
                            title="Operador"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="user" />
                              </span>
                            }
                            formulario={MovimientoOperador}
                            datosForm={{
                              accion: 'modificar',
                              idusuario: user.id,
                              movimiento: getMovimiento,
                              getfuntion: getfuntion,
                              camposLogisticosNav: camposLogisticosNav,
                              rutasEstatusObject: rutasEstatus,
                              operacionesEstatus: operacionesEstatus,
                              clientes_object: clientes_object,
                              otd_object: otd_object,
                              full: movimientoFull,
                            }}
                          />
                        ) : null}
                      </span>
                    ) : null}
                    {getFuncionalidad('Actualizar') &&
                    plataformaTipo === 'Naviera' &&
                    getMovimiento.idPlataforma ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Unidad"
                      >
                        {getMovimiento.idPlataforma.plataformaTipo.id === 3 ? (
                          <ModalLay
                            tamanio={1200}
                            title="Unidades"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="truck" />
                              </span>
                            }
                            formulario={MovimientoUnidad}
                            datosForm={{
                              accion: 'modificar',
                              idusuario: user.id,
                              movimiento: getMovimiento,
                              getfuntion: getfuntion,
                              stor: { getMovimientoNavieroById },
                              camposLogisticosNav: camposLogisticosNav,
                              rutasEstatusObject: rutasEstatus,
                              operacionesEstatus: operacionesEstatus,
                              clientes_object: clientes_object,
                              otd_object: otd_object,
                              full: movimientoFull,
                            }}
                          />
                        ) : null}
                      </span>
                    ) : null}
                    {getFuncionalidad('Arroba Azul') ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Agregar temporizador"
                      >
                        <ModalLay
                          tamanio={'85%'}
                          title="Agregar temporizador"
                          name={
                            <span
                              style={{
                                color: '#63e6be',
                                'font-size': '200%',
                              }}
                            >
                              <i className="fa fa-hourglass" />
                            </span>
                          }
                          formulario={Temporizador}
                          datosForm={{
                            movimiento: getMovimiento,
                            tiempos: tiempos,
                            addToArray: addToArray,
                            usuario: usuario,
                          }}
                        />
                      </span>
                    ) : null}
                    {getplataformaTipo('Naviera') &&
                      estaPrivilegioActivo('movimientos_reactivar') && (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Reactivar movimiento Naviero"
                        >
                          <ModalLay
                            tamanio={'85%'}
                            title="Reactivar movimiento"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="clock" />
                              </span>
                            }
                            formulario={ReactivarMovimientos}
                            datosForm={{}}
                          />
                        </span>
                      )}

                    {/* {getFuncionalidad("BitacoraPdf")  */}
                    {getFuncionalidad('BitacoraPdf') ? (
                      <span
                        className="badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Bitacora a PDF'}
                      >
                        <ModalLay
                          title={'Bitacora del viaje electrónica: '}
                          name={
                            <label
                              style={{ color: 'orange', 'font-size': '200%' }}
                            >
                              <Icon name="list" />
                            </label>
                          }
                          formulario={BitacoraPdfTerrestre}
                          datosForm={{
                            Movimiento: getMovimiento,
                          }}
                        />{' '}
                      </span>
                    ) : null}

                    {getplataformaTipo('Terrestre') &&
                      estaPrivilegioActivo('movimientos_reactivar') && (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Reactivar movimiento Terrestre"
                        >
                          <ModalLay
                            tamanio={'85%'}
                            title="Reactivar movimiento"
                            name={
                              <span
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon name="clock" />
                              </span>
                            }
                            formulario={ReactivarMovimientosTerrestre}
                            datosForm={{}}
                          />
                        </span>
                      )}

                    {/* Boton para capturar service failure del movimiento */}
                    {getFuncionalidad('service failure') &&
                      plataformaTipo === 'Naviera' && (
                        <span
                          className="badge"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Service Failure"
                        >
                          <ModalLay
                            tamanio={1200}
                            title="Service Failure"
                            name={
                              <label
                                style={{ color: 'blue', 'font-size': '200%' }}
                              >
                                <Icon prefix="fa" name="paste" />
                              </label>
                            }
                            formulario={NavieraServiceFailure}
                            datosForm={{
                              getMovimiento: getMovimiento,
                              idMovimiento: getMovimiento.id,
                            }}
                          />
                        </span>
                      )}
                  </Grid.Col>
                </Grid.Row>
              </div>
            ) : null}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {/**
           * Detalles
           */}
          {/* <h4>Detalles</h4>  */}
          <div className="col-12 bg-primary text-light">
            <h4>
              Detalles
              <div
                className="float-right col-1 right"
                style={{ backgroundColor: 'rgba(6, 0, 0, 0.2)' }}
                onClick={() => setDetallesCollase(!detallesCollase)}
              >
                <Icon
                  name={detallesCollase ? 'chevron-down' : 'chevron-up'}
                  className="float-right"
                />
              </div>
            </h4>
          </div>
          <div className="panel panel-default" hidden={detallesCollase}>
            <div className="col-12 panel-resizable" style={{ height: '75vh' }}>
              {detalles ? detalles : 'No hay detalles'}
            </div>
          </div>
          {/*
           * Alertas
           */}
          <div>
            {plataformaTipo === 'Naviera' ? (
              <div>
                <div className="col-12 bg-primary text-light">
                  <h4>
                    Alertas Navieras
                    <div
                      className="float-right col-1 right"
                      style={{ backgroundColor: 'rgba(6, 0, 0, 0.2)' }}
                      onClick={() => setAlertasCollase(!alertasCollase)}
                    >
                      <Icon
                        name={alertasCollase ? 'chevron-down' : 'chevron-up'}
                        className="float-right"
                      />
                    </div>
                  </h4>
                </div>
                <div className="panel panel-default" hidden={alertasCollase}>
                  <div
                    className="col-12 panel-resizable"
                    style={{ height: '75vh' }}
                  >
                    <Grid.Row>
                      <Grid.Col sm={8} lg={12}>
                        {getMovimiento.id ? (
                          <div>
                            <Grid.Row>
                              <Grid.Col sm={7} lg={7}>
                                {alertasN.length > 0 && (
                                  <Form.Input
                                    className="mb-3"
                                    icon="search"
                                    placeholder="Buscar Alertas..."
                                    position="append"
                                    onChange={(e) =>
                                      setSearchAlertas(e.target.value)
                                    }
                                  />
                                )}
                              </Grid.Col>

                              {plataformaTipo === 'Naviera' &&
                                getFuncionalidad('Alertas Atendidas') && (
                                  <Grid.Col sm={2} lg={2}>
                                    <ModalLay
                                      tamanio={'99%'}
                                      title={
                                        'Movimiento ' +
                                        getMovimiento.id +
                                        ' - Alertas'
                                      }
                                      name={
                                        <Button
                                          pill
                                          size="sm"
                                          outline
                                          color="primary"
                                        >
                                          <Icon name="paperclip" />
                                        </Button>
                                      }
                                      formulario={AlertaNListar}
                                      datosForm={{
                                        Movimiento: getMovimiento,
                                      }}
                                    />
                                  </Grid.Col>
                                )}
                            </Grid.Row>
                          </div>
                        ) : null}
                      </Grid.Col>
                    </Grid.Row>
                    {getMovimiento.id &&
                    alertasN &&
                    alertasN[getMovimiento.id] &&
                    getFuncionalidad('Alertas No Atendidas') ? (
                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter"
                        headerItems={[
                          { content: 'ID' },
                          { content: 'Tiggername' },
                          { content: 'Atendido' },
                        ]}
                      >
                        <Table.Body>
                          {(columna = [])}
                          {alertasN[getMovimiento.id]
                            ? Object.keys(alertasN[getMovimiento.id]).forEach(
                                (key, indice) =>
                                  alertasN[getMovimiento.id][key]
                                    .filter(
                                      (word) =>
                                        !word.alert.atendida &&
                                        word.alert.triggerName
                                          .toUpperCase()
                                          .includes(searchAlertas.toUpperCase())
                                    )
                                    .sort()
                                    .map((elemento, index) =>
                                      columna.push(
                                        <Table.Row>
                                          <Table.Col>
                                            {elemento.alert.id}
                                          </Table.Col>
                                          <Table.Col>
                                            {elemento.alert.triggerName}
                                          </Table.Col>
                                          <Table.Col>
                                            {getFuncionalidad(
                                              'Atender Alertas'
                                            ) && (
                                              <ModalLay
                                                tamanio={'60%'}
                                                title="Alertas Navieras"
                                                name={
                                                  <Button
                                                    pill
                                                    size="sm"
                                                    outline
                                                    color="primary"
                                                  >
                                                    <div
                                                      className={'fa ' + key}
                                                      style={{
                                                        color: elemento.color,
                                                      }}
                                                    ></div>
                                                  </Button>
                                                }
                                                formulario={AlertaNForm}
                                                datosForm={{
                                                  accion: 'modificar',
                                                  usuario: user,
                                                  alerta: elemento.alert,
                                                  getfuntion: getfuntion,
                                                  closeModal: hiddenmodal,
                                                  IncidenciasFrom: (
                                                    <MovimientoIncidencias
                                                      accion={'nuevo'}
                                                      idusuario={user.id}
                                                      idmovimiento={
                                                        getMovimiento.id
                                                      }
                                                      movimiento={getMovimiento}
                                                      incidencias={incidencias} //revisar
                                                      getfuntion={getfuntion}
                                                      stor={stor}
                                                      plataformaTipo={
                                                        plataformaTipo
                                                      }
                                                      closeModal={hiddenmodal}
                                                      movimientoFull={
                                                        movimientoFull
                                                          ? MovimientosFull[
                                                              movimientoFull
                                                            ]
                                                            ? MovimientosFull[
                                                                movimientoFull
                                                              ].movimiento
                                                            : null
                                                          : null
                                                      }
                                                      gpsReciente={
                                                        gpsReciente
                                                          ? gpsReciente[
                                                              getMovimiento.id
                                                            ]
                                                          : null
                                                      }
                                                      latitud={
                                                        elemento.alert.latitud
                                                      }
                                                      longitud={
                                                        elemento.alert.longitud
                                                      }
                                                    />
                                                  ),
                                                }}
                                              />
                                            )}
                                          </Table.Col>
                                        </Table.Row>
                                      )
                                    )
                              )
                            : null}
                        </Table.Body>
                      </Table>
                    ) : (
                      '  No hay alertas Navieras..!!'
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {plataformaTipo === 'Terrestre' &&
            getFuncionalidad('Atender Alertas') ? (
              <div>
                <div className="col-12 bg-primary text-light">
                  <h4>
                    Alertas Terrestres
                    <div
                      className="float-right col-1 right"
                      style={{ backgroundColor: 'rgba(6, 0, 0, 0.2)' }}
                      onClick={() => setAlertasCollase(!alertasCollase)}
                    >
                      <Icon
                        name={alertasCollase ? 'chevron-down' : 'chevron-up'}
                        className="float-right"
                      />
                    </div>
                  </h4>
                </div>
                <div className="panel panel-default" hidden={alertasCollase}>
                  <div
                    className="col-12 panel-resizable"
                    style={{ height: '75vh' }}
                  >
                    {getMovimiento.id &&
                    alertasT &&
                    alertasT[getMovimiento.id] ? (
                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter"
                        headerItems={[
                          { content: 'ID' },
                          { content: 'Tiggername' },
                          { content: 'Atender' },
                        ]}
                      >
                        <Table.Body>
                          {(alertT = [])}
                          {alertasT[getMovimiento.id]
                            ? Object.keys(alertasT[getMovimiento.id]).forEach(
                                (key, indice) =>
                                  //alertasT[getMovimiento.id][key].filter(word => word.alert.triggerName.toUpperCase().includes(searchAlertas.toUpperCase())).sort().map((elemento, index) =>
                                  alertasT[getMovimiento.id][key]
                                    .filter((word) => word.alert.atendida !== 1)
                                    .sort()
                                    .map((elemento, index) =>
                                      alertT.push(
                                        <Table.Row>
                                          <Table.Col>
                                            {elemento.alert.id}
                                          </Table.Col>
                                          <Table.Col>
                                            {elemento.alert.triggerName}
                                          </Table.Col>
                                          <Table.Col>
                                            <ModalLay
                                              tamanio={'60%'}
                                              title="Atender Alerta Terrestre"
                                              name={
                                                <Button
                                                  pill
                                                  size="sm"
                                                  outline
                                                  color="primary"
                                                >
                                                  <div
                                                    className={
                                                      'fa ' + elemento.icono
                                                    }
                                                    style={{
                                                      color: elemento.color,
                                                    }}
                                                  ></div>
                                                </Button>
                                              }
                                              formulario={AlertaTForm}
                                              datosForm={{
                                                accion: 'modificar',
                                                usuario: user,
                                                alerta: elemento.alert,
                                                getfuntion: getfuntion,
                                                entregas: entregas,
                                                closeModal: hiddenmodal,
                                                IncidenciasFrom: [193].includes(
                                                  getMovimiento.plataforma.id
                                                ) ? ( // traxion
                                                  <IncidenciasTraxion
                                                    accion={'nuevo'}
                                                    desde={'Panel'}
                                                    idusuario={user.id}
                                                    idmovimiento={
                                                      getMovimiento.id
                                                    }
                                                    idplataforma={
                                                      getMovimiento.plataforma
                                                        .id
                                                    }
                                                    movimiento={getMovimiento}
                                                    unidad={unidad}
                                                    //idEntrega={idEntrega}
                                                    idEntregaActiva={
                                                      idEntregaActiva
                                                    }
                                                    entregas={entregas}
                                                    getfuntion={getfuntion}
                                                    stor={stor}
                                                    plataformaTipo={
                                                      plataformaTipo
                                                    }
                                                    gpsReciente={
                                                      gpsReciente
                                                        ? gpsReciente[
                                                            getMovimiento.id
                                                          ]
                                                        : null
                                                    }
                                                    latitud={null}
                                                    longitud={null}
                                                    closeModal={hiddenmodal}
                                                  />
                                                ) : (
                                                  <MovimientoIncidenciasTerrestre
                                                    accion={'nuevo'}
                                                    desde={'Panel'}
                                                    idusuario={user.id}
                                                    idmovimiento={
                                                      getMovimiento.id
                                                    }
                                                    idplataforma={
                                                      getMovimiento.plataforma
                                                        .id
                                                    }
                                                    movimiento={getMovimiento}
                                                    getfuntion={getfuntion}
                                                    stor={stor}
                                                    //idEntrega={idEntrega}
                                                    idEntregaActiva={
                                                      idEntregaActiva
                                                    }
                                                    entregas={entregas}
                                                    plataformaTipo={
                                                      plataformaTipo
                                                    }
                                                    gpsReciente={
                                                      gpsReciente
                                                        ? gpsReciente[
                                                            getMovimiento.id
                                                          ]
                                                        : null
                                                    }
                                                    latitud={
                                                      elemento.alert.latitud
                                                    }
                                                    longitud={
                                                      elemento.alert.longitud
                                                    }
                                                    closeModal={hiddenmodal}
                                                  />
                                                ),
                                              }}
                                            />
                                          </Table.Col>
                                        </Table.Row>
                                      )
                                    )
                              )
                            : null}
                        </Table.Body>
                      </Table>
                    ) : (
                      '  Sin Alertas Terrestres..!!'
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {!plataformaTipo ? (
              <div>
                <div className="col-12 bg-primary text-light">
                  {' '}
                  <h4>
                    Alertas
                    <div
                      className="float-right col-1 right"
                      style={{ backgroundColor: 'rgba(6, 0, 0, 0.2)' }}
                    ></div>
                  </h4>{' '}
                </div>
                <h3>No hay alertas..!!</h3>
              </div>
            ) : null}
          </div>
          {/**
           * Incidencias
           */}
          <div
            className="col-12 bg-primary text-light"
            hidden={!getFuncionalidad('Incidencias Rapida')}
          >
            <h4>
              Incidencias
              <div
                className="float-right col-1 right"
                style={{ backgroundColor: 'rgba(6, 0, 0, 0.2)' }}
                onClick={() => setIncidenciasCollase(!incidenciasCollase)}
              >
                <Icon
                  name={incidenciasCollase ? 'chevron-down' : 'chevron-up'}
                  className="float-right"
                />
              </div>
            </h4>
          </div>
          <div className="panel panel-default" hidden={incidenciasCollase}>
            <div className="col-12 panel-resizable" style={{ height: '75vh' }}>
              <Grid.Row>
                <Grid.Col sm={8} lg={12}>
                  {getMovimiento.id ? (
                    <div>
                      <Grid.Row>
                        <Grid.Col sm={5} lg={5}>
                          {incidenciasNavieras.length > 0 ? (
                            <Form.Input
                              className="mb-3"
                              icon="search"
                              placeholder="Buscar Incidencias..."
                              position="append"
                              onChange={(e) =>
                                setSearchIncidencias(e.target.value)
                              }
                            />
                          ) : null}
                        </Grid.Col>

                        {plataformaTipo === 'Naviera' &&
                          getFuncionalidad('Incidencias Detallada') && (
                            <Grid.Col sm={2} lg={2}>
                              <ModalLay
                                tamanio={'90%'}
                                title={
                                  'Detalle de Incidencias del movimiento (' +
                                  getMovimiento.id +
                                  ')'
                                }
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                  </Button>
                                }
                                formulario={IncidenciasNListar}
                                datosForm={{
                                  incidencias: incidenciasNavieras,
                                }}
                              />
                            </Grid.Col>
                          )}

                        {plataformaTipo === 'Terrestre' &&
                          [193].includes(getMovimiento.plataforma.id) && ( //traxion
                            <Grid.Col sm={2} lg={2}>
                              <ModalLay
                                tamanio={'80%'}
                                title="Detalle de Incidencias"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                    Todas
                                  </Button>
                                }
                                formulario={IncidenciasTraxionExportar}
                              />
                            </Grid.Col>
                          )}
                        {plataformaTipo === 'Terrestre' &&
                          ![193].includes(getMovimiento.plataforma.id) && ( //193 traxion
                            <Grid.Col sm={2} lg={2}>
                              <ModalLay
                                tamanio={'80%'}
                                title="Detalle de Incidencias"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="paperclip" />
                                    Todas
                                  </Button>
                                }
                                formulario={
                                  IncidenciasTerrestresExportarActYFin
                                }
                              />
                            </Grid.Col>
                          )}
                        {plataformaTipo === 'Terrestre' && (
                          <Grid.Col sm={2} lg={2}>
                            <ModalLay
                              tamanio={'80%'}
                              title="Detalle de Incidencias"
                              name={
                                <Button pill size="sm" outline color="primary">
                                  <Icon name="paperclip" />
                                  Mov
                                </Button>
                              }
                              formulario={IncidenciasMovimientoView}
                              datosForm={{
                                plataforma: getMovimiento.plataforma.id,
                                incidenciasExcel: [193].includes(
                                  getMovimiento.plataforma.id
                                )
                                  ? incidenciasTraxionExcel
                                  : incidenciasTerrestre,
                                incidencias: incidenciasTerrestre,
                                searchIncidencias: searchIncidencias,
                                incidenciasTotales: incidencias,
                              }}
                            />
                          </Grid.Col>
                        )}

                        <Grid.Col sm={2} lg={2}>
                          {plataformaTipo === 'Naviera' &&
                            getFuncionalidad('Crear Incidencia') && (
                              <ModalLay
                                tamanio={800}
                                title="Nueva incidencia"
                                name={
                                  <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                  >
                                    <Icon name="plus" />
                                  </Button>
                                }
                                formulario={MovimientoIncidencias}
                                datosForm={{
                                  accion: 'nuevo',
                                  idusuario: user.id,
                                  idmovimiento: getMovimiento.id,
                                  movimiento: getMovimiento,
                                  getfuntion: getfuntion,
                                  stor: { privilegios_object },
                                  plataformaTipo: plataformaTipo,
                                  closeModal: hiddenmodal,
                                  movimientoFull: movimientoFull
                                    ? MovimientosFull[movimientoFull]
                                      ? MovimientosFull[movimientoFull]
                                          .movimiento
                                      : null
                                    : null,
                                  gpsReciente: gpsReciente
                                    ? gpsReciente[getMovimiento.id]
                                    : null,
                                  latitud: null,
                                  longitud: null,
                                }}
                              />
                            )}
                          {
                            //plataformaTipo === "Terrestre" && idEntregaActiva?
                          }
                          {plataformaTipo === 'Terrestre' &&
                          getFuncionalidad('Crear Incidencia') ? (
                            <ModalLay
                              tamanio={'70%'}
                              title="Nueva incidencia"
                              name={
                                <Button pill size="sm" outline color="primary">
                                  <Icon name="plus" />
                                </Button>
                              }
                              formulario={
                                [193].includes(getMovimiento.plataforma.id) //traxion
                                  ? IncidenciasTraxion
                                  : MovimientoIncidenciasTerrestre
                              }
                              datosForm={{
                                accion: 'nuevo',
                                desde: 'Panel',
                                idusuario: user.id,
                                idmovimiento: getMovimiento.id,
                                idplataforma: getMovimiento.plataforma.id,
                                movimiento: getMovimiento,
                                unidad: unidad,
                                idEntregaActiva: idEntregaActiva,
                                entregas: entregas,
                                getfuntion: getfuntion,
                                stor: { privilegios_object },
                                plataformaTipo: plataformaTipo,
                                gpsReciente: gpsReciente
                                  ? gpsReciente[getMovimiento.id]
                                  : null,
                                latitud: null,
                                longitud: null,
                                closeModal: hiddenmodal,
                              }}
                            />
                          ) : (
                            <div></div>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                    </div>
                  ) : null}
                </Grid.Col>
              </Grid.Row>
              {getMovimiento &&
              incidenciasNavieras.length > 0 &&
              plataformaTipo === 'Naviera' &&
              getFuncionalidad('Incidencias Rapida') ? (
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter bg-light"
                  headerItems={[
                    { content: 'ID' },
                    { content: 'Nombre' },
                    { content: 'Comentario' },
                    { content: 'Fecha' },
                  ]} //[{ content: "ID" }, { content: "Nombre" }]
                >
                  <Table.Body>
                    {plataformaTipo === 'Naviera'
                      ? incidenciasNavieras
                          .filter((word) =>
                            word.incidencia.nombre
                              .toUpperCase()
                              .includes(searchIncidencias.toUpperCase())
                          )
                          .sort(function (a, b) {
                            if (a['fechaCreado'] === null) {
                              return -1;
                            }
                            if (a['fechaCreado'] < b['fechaCreado']) {
                              return 1;
                            }
                            if (a['fechaCreado'] > b['fechaCreado']) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((elemento, index) => (
                            <Table.Row>
                              <Table.Col>{elemento.id}</Table.Col>
                              <Table.Col>
                                {elemento.incidencia.nombre}
                              </Table.Col>
                              <Table.Col>{elemento.comentario}</Table.Col>
                              <Table.Col>{elemento.fechaCreado}</Table.Col>
                            </Table.Row>
                          ))
                      : null}
                  </Table.Body>
                </Table>
              ) : null}
              {getMovimiento &&
              incidenciasTerrestre.length > 0 &&
              plataformaTipo === 'Terrestre' ? (
                ![193].includes(getMovimiento.plataforma.id) ? ( //193 es traxion
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter bg-light"
                    headerItems={[
                      { content: 'ID' },
                      { content: 'Incidencia' },
                      { content: 'Comentario' },
                      { content: 'Creado' },
                      { content: 'Atencion Fecha' },
                    ]}
                  >
                    <Table.Body>
                      {incidenciasTerrestre
                        .filter((word) =>
                          word.comentario
                            .toUpperCase()
                            .includes(searchIncidencias.toUpperCase())
                        )
                        .sort(function (a, b) {
                          if (a['creado'] === null) {
                            return -1;
                          }
                          /* if (a["fecha"] > b["fecha"]) {  return 1; }
                              if (a["fecha"] < b["fecha"]) {  return -1;}*/
                          // mi propuestao
                          if (a['creado'] < b['creado']) {
                            return 1;
                          }
                          if (a['creado'] > b['creado']) {
                            return -1;
                          }

                          /*  var c = new Date(a.date);
                              var d = new Date(b.date);
                              return c-d;*/

                          // return 0;
                        })
                        .map((elemento, index) => (
                          <Table.Row>
                            <Table.Col>
                              {elemento.idEntregaIncidencia}
                            </Table.Col>
                            <Table.Col>
                              {getIncidenciaNombre(elemento.idIncidencia)}
                            </Table.Col>
                            <Table.Col>{elemento.comentario}</Table.Col>
                            <Table.Col>{elemento.creado}</Table.Col>
                            <Table.Col>{elemento.atencionFecha}</Table.Col>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter bg-light"
                    headerItems={[
                      { content: 'ID' },
                      { content: 'Incidencia' },
                      { content: 'Comentario' },
                      { content: 'Creeado' },
                      { content: 'Editar' },
                    ]}
                  >
                    <Table.Body>
                      {incidenciasTerrestre
                        .filter((word) =>
                          word.comentario
                            .toUpperCase()
                            .includes(searchIncidencias.toUpperCase())
                        )
                        .sort(function (a, b) {
                          if (a['fecha'] === null) {
                            return -1;
                          }
                          /*if (a["creado"] > b["creado"]) {  return 1; }
                              if (a["creado"] < b["creado"]) {  return -1;}*/
                          //mi propuesta:
                          if (a['fecha'] < b['fecha']) {
                            return 1;
                          }
                          if (a['fecha'] > b['fecha']) {
                            return -1;
                          }

                          return 0;
                        })
                        .map((elemento, index) => (
                          <Table.Row>
                            <Table.Col>
                              {elemento.idEntregaIncidencia}
                            </Table.Col>
                            <Table.Col>{elemento.incidencia.nombre}</Table.Col>
                            <Table.Col>{elemento.comentario}</Table.Col>
                            <Table.Col>{elemento.fecha}</Table.Col>
                            <Table.Col></Table.Col>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                )
              ) : null}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          {getFuncionalidad('Arroba Azul') ? (
            <div>
              <h3>Nota</h3>
              <p>{getMovimiento.nota}</p>
            </div>
          ) : null}
          {casetas ? (
            <div>
              <h3>Casetas</h3>
              <p>{casetas}</p>
            </div>
          ) : (
            ''
          )}
        </Card.Footer>
        {imgEvidencias.length ? (
          <Card.Footer>
            <h3>Evidencias</h3>
            <div>
              {imgEvidencias.map((imagen, index) => (
                <div>
                  <div>
                    Imágen:{' '}
                    <a href={rutaImagen + imagen.nombreArchivo} target="_blank">
                      {imagen.nombreArchivo}
                    </a>
                    {imagen.fechaModificado
                      ? ' cargada con fecha ' +
                        imagen.fechaModificado.substring(0, 20)
                      : ''}
                  </div>
                  <div>
                    <a href={rutaImagen + imagen.nombreArchivo} target="_blank">
                      <img src={rutaImagen + imagen.nombreArchivo} />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </Card.Footer>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
};
export default Panel;
