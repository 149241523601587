import React, { Component } from "react";
import * as XLSX from "xlsx";
import "./excel.css";
import "./modal.css";
import { Crud_Catalogos, Crud_error } from "../Crud_Catalogos";
import { Dimmer } from "tabler-react";
import { NotificationManager } from "react-notifications";
import { Tab, Tabs, TabList, TabPanel } from "./tabs-generic/index";
import TablaCatalogo from "./tabla-generic/index";

var id = 0;
var terrores = 0;
let sinError = 0;
var dia = 0;
var mes = 0;
var anio = 0;
var hora = 0;
var minuto = 0;
var segundo = 0;

var fechas = 0;
var horas = 0;
var arrayErr = [];
let Errores = [];
let FilaErr = [];
let Cuadros = [];
let TabTemp = [];
let TabPanelTemp = [];

const fdatetime = "yyyy-mm-dd HH:mm:ss";
const fdate = "yyyy-mm-dd";
const fhora = "HH:mm:ss";
const nobis = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const sbiss = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

function myModalN(Muestra) {
  var x = document.getElementById("xlsModalN");
  x.style.display = Muestra;
}
function bisiesto(year) {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}
function serieMes(numero, año) {
  var cuenta = 0;
  var siguiente = 0;
  var index = 0;
  cuenta = numero;
  do {
    if (bisiesto(año)) {
      siguiente = sbiss[index + 1];
      cuenta = cuenta - sbiss[index];
      dia = cuenta;
    } else {
      siguiente = nobis[index + 1];
      cuenta = cuenta - nobis[index];
      dia = cuenta;
    }
    index = index + 1;
  } while (cuenta > siguiente);
  mes = index + 1;
  dia = cuenta - 1;
}
function serieAnio(valor) {
  var año = 1900;
  var sumadias = 0;
  var residuo = 0;
  do {
    if (bisiesto(año)) {
      año = año + 1;
      sumadias = sumadias + 366;
    } else {
      año = año + 1;
      sumadias = sumadias + 365;
    }
  } while (sumadias + 365 < valor);
  residuo = valor - sumadias;
  anio = año;
  serieMes(residuo, año);
}
function serieHoras(valor) {
  var temporal = 0;
  var residuo = 0;
  temporal = valor;

  temporal = temporal * 24;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  hora = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  minuto = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  segundo = parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogo: this.props.catalogo | "",

      //Catalogo Funcionalidades
      bloque: this.props.bloque | [],

      //Catalogo Funcionalidades
      categorias: this.props.categorias | [],

      //Tabla Movimientos
      idPlataforma: this.props.idPlataforma | [],
      idCliente: this.props.idCliente | [],
      idCorredor: this.props.idCorredor | [],
      idLocalidad: this.props.idLocalidad | [],
      idMovimientoTipo: this.props.idMovimientoTipo | [],
      idNaviera: this.props.idNaviera | [],
      idOperacionTipo: this.props.idOperacionTipo | [],
      idOtd: this.props.idOtd | [],
      idRuta: this.props.idRuta | [],
      idTransportista: this.props.idTransportista | [],
      operacionesEstatus: this.props.operacionesEstatus | [],

      //movimientos:this.props.movimientos							|[],
      //privilegios:this.props.privilegios							|[],

      //Variables
      accion: this.props.accion,
      validado: this.props.validado | false,

      //Variables de Datos
      data: this.props.data | [],
      dataOtd: this.props.dataOtd | [],
      dataNavieras: this.props.dataNavieras | [],
      dataPlataformas: this.props.dataPlataformas | [],
      dataOperacionesTipo: this.props.dataOperacionesTipo | [],
      dataOperacionesEstatus: this.props.dataOperacionesEstatus | [],
      dataRuta: this.props.dataRuta | [],
      dataMovimientoTipo: this.props.dataMovimientoTipo | [],
      dataTransportistas: this.props.dataTransportistas | [],
      dataCorredores: this.props.dataCorredores | [],
      dataCliente: this.props.dataCliente | [],
      dataContenedorTipo: this.props.dataContenedorTipo | [],
      dataNavieraMovimientos: this.props.dataNavieraMovimientos | [],
    };
    this.cita = 0;
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      plataformaTipo: 1,
    };
    this.localidades = {};
  }
  componentDidMount() {
    myModalN("none");
  }
  componentWillMount() {
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        Crud_Catalogos("localidades", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            let localidadesTemp = {};
            returnVal.map((l, index) => {
              localidadesTemp[l.id] = l;
              localidadesTemp[l.nombre] = l;
            });
            this.localidades = localidadesTemp;
          })
          .catch((err) => {});
        //Tabla navieraMovimientos
        //{ this.cargaIdOperacionesEstatus() };
        //{ this.cargaMovimientos() };
        break;
      default:
        //console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }
  }

  //Inicio Tabla Movimientos
  cargaIdOperacionesEstatus() {
    Crud_Catalogos("operacionesEstatus", "", "get", "", [], "", "", "", [])
      .then((returnVal) => {
        this.setState({ operacionesEstatus: returnVal });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          console.log(
            "Error al Cargar el Catalogo:" +
              this.state.catalogo +
              " verifique el Nombre del Catalogo"
          );
        }
      });
  }
  cargaMovimientos() {}
  //Fin Tabla Movimientos
  valObjeto(ObjValidar, j) {
    //{"plataforma":{"id": 2,"nombre":null},"cliente":{"id": 12,"nombre":null},"corredor":{"id": 70,"nombre":null},"movimientoTipo":{"id":1,"nombre":null},"naviera":{"id":8,"nombre":null},"operacionTipo":{"id": 3,"nombre":null},"otd":{"id":1,"nombre":null},"rutasEstatus":{"id":2,"nombre":null},"transportista":{"id":10,"nombre":null},"operacionesEstatus":{"id": 1,"nombre":null} }
    //Crud_Catalogos("validacionDatos/terrestre",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
    Crud_Catalogos(
      "validacionDatos/naviera",
      "",
      "post",
      "",
      ObjValidar,
      "",
      "",
      "",
      [],
      "",
      "",
      ""
    )
      .then((returnVal) => {
        this.state.data[j].idPlataforma = returnVal.plataforma;
        this.state.data[j].idCliente = returnVal.cliente;
        this.state.data[j].idCorredor = returnVal.corredor;
        console.log(ObjValidar, this.localidades[ObjValidar.localidad.nombre]);
        this.state.data[j].idMovimientoTipo = returnVal.movimientoTipo;
        this.state.data[j].idNaviera = returnVal.naviera;
        this.state.data[j].idOperacionTipo = returnVal.operacionTipo;
        this.state.data[j].idOtd = returnVal.otd;
        this.state.data[j].idRuta = returnVal.rutasEstatus;
        this.state.data[j].idTransportista = returnVal.transportista;
        this.state.data[j].idOperacionEstatus = returnVal.operacionEstatus;

        this.state.data[j].po = returnVal.po.po;
        

        this.state.data[j].id = returnVal?.id;

        let tempplataforma = returnVal.plataforma; //this.state.data[j].idPlataforma;
        if (
          tempplataforma.nombre === "" ||
          tempplataforma.nombre === null ||
          tempplataforma.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempplataforma.id === null || tempplataforma.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempcliente = returnVal.cliente; //this.state.data[j].idCliente;
        if (
          tempcliente.nombre === "" ||
          tempcliente.nombre === null ||
          tempcliente.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Cliente NO Está VINCULADO a la Plataforma -" +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Cliente NO Está VINCULADO a la Plataforma -" +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Cliente NO Está VINCULADO a la Plataforma -" +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempcliente.id === null || tempcliente.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Cliente NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Cliente NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Cliente NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempcorredor = returnVal.corredor; //this.state.data[j].idCorredor;
        if (
          tempcorredor.nombre === "" ||
          tempcorredor.nombre === null ||
          tempcorredor.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Corredor NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Corrredor NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Corredor NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        if (this.localidades[ObjValidar.localidad]) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) No existe la localidad" +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) No existe la localidad" +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) No existe la localidad" +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        if (tempcorredor.id === null || tempcorredor.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Corredor NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Corredor NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Corredor NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempmovimientotipo = returnVal.movimientoTipo; //this.state.data[j].idMovimientoTipo;
        if (
          tempmovimientotipo.nombre === "" ||
          tempmovimientotipo.nombre === null ||
          tempmovimientotipo.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempmovimientotipo.id === null || tempmovimientotipo.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempnaviera = returnVal.naviera; //this.state.data[j].idNaviera;
        if (
          tempnaviera.nombre === "" ||
          tempnaviera.nombre === null ||
          tempnaviera.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Naviera NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Naviera NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempnaviera.id === null || tempnaviera.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) La Naviera NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) La Naviera NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempoperaciontipo = returnVal.operacionTipo; //this.state.data[j].idOperacionTipo;
        if (
          tempoperaciontipo.nombre === "" ||
          tempoperaciontipo.nombre === null ||
          tempoperaciontipo.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempoperaciontipo.id === null || tempoperaciontipo.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempotd = returnVal.otd; //this.state.data[j].idOtd;
        if (
          tempotd.nombre === "" ||
          tempotd.nombre === null ||
          tempotd.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Otd NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Otd NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Otd NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempotd.id === null || tempotd.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El OTD NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El OTD NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El OTD NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let temprutasestatus = returnVal.rutasEstatus; //this.state.data[j].idRuta;
        if (
          temprutasestatus.nombre === "" ||
          temprutasestatus.nombre === null ||
          temprutasestatus.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (temprutasestatus.id === null || temprutasestatus.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let temptransportista = returnVal.transportista; //this.state.data[j].idTransportista;
        if (
          temptransportista.nombre === "" ||
          temptransportista.nombre === null ||
          temptransportista.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (temptransportista.id === null || temptransportista.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempoperacionEstatus = returnVal.operacionEstatus; //this.state.data[j].idTransportista;
        if (
          tempoperacionEstatus.nombre === "" ||
          tempoperacionEstatus.nombre === null ||
          tempoperacionEstatus.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempoperacionEstatus.id === null || tempoperacionEstatus.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
      })
      .catch((err) => {
        console.log("343 Error de validacionDatos: " + err);
      });
  }
  valYaExiste(Catalogo, Po, I, Campo) {
    let iDs = "";
    Crud_Catalogos(
      "navieraMovimientos/po/" + Po,
      "",
      "get",
      "",
      "",
      "",
      "",
      "",
      []
    )
      .then((returnVal) => {
        returnVal.map((ids) => {
          iDs = "id: " + ids.id;
          //console.log(ids.id);
          terrores = terrores + 1;
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          console.log(
            "Error al Cargar el Catalogo:" +
              this.state.catalogo +
              " verifique el Nombre del Catalogo"
          );
        }
      });
    console.log("No Existe el Catalogo Definido");
  }
  valCatalogoId(Catalogo, Id, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.id = Id;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.id = Id; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idLocalidad = this.localidades[Id];
        break;
      case "idMovimientoTipo":
        Campo.id = Id; //this.state.movimientosTipo[I].id;
        this.state.data[I].idMovimientoTipo = Campo;
        break;
      case "idNaviera":
        Campo.id = Id; //this.state.navieras[I].id;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.id = Id; //this.state.operacionesTipo[I].id;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.id = Id; //this.state.otd[I].id;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.id = Id; //this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.id = Id; //this.state.transportistas[I].id;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.id = Id; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo; //this.state.operacionesEstatus[k];
        break;

      case "po":
        Campo.po = Id; //this.state.transportistas[I].id;
        this.state.data[I].po = Id;
        break;
      case "citaProgramada":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        //horas = fechas - Number.parseFloat(Id);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas); //datos[num]= ""+anio+"-"+mes+"-"+dia+""
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].citaProgramada = Campo;
        break;
      case "citaColocacionGps":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        //horas = fechas - Number.parseFloat(Id);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas); //datos[num]= ""+anio+"-"+mes+"-"+dia+""
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].citaColocacionGps = Campo;
        break;
      case "arriboTerminalVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio = Campo;
        break;
      case "salidaTerminalLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaTerminalLleno = Campo;
        break;
      case "arriboClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteLleno = Campo;
        break;
      case "salidaClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteVacio = Campo;
        break;
      case "arriboTerminalVacio2":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio2 = Campo;
        break;
      case "retiroContenedor":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas); //datos[num]= ""+anio+"-"+mes+"-"+dia+""
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].retiroContenedor = Campo;
        break;
      case "arriboClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteVacio = Campo;
        break;
      case "salidaClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteLleno = Campo;
        break;
      case "arriboTerminalCargado":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalCargado = Campo;
        break;

      case "eta":
        //fechas = parseInt(Id, 10);
        //horas = Number.parseFloat(Id)-fechas;
        //serieHoras(horas);
        //Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].eta = Campo;
        break;
      case "delay":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].delay = Campo;
        break;
      case "wti6h":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].wti6h = Campo;
        break;
      case "transit":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].transit = Campo;
        break;
      case "ontime":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].ontime = Campo;
        break;

      default:
        console.log("No Existe el Catalogo Definido");
    }
  }
  getCatalogoId(Catalogo, Nombre, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.nombre = Nombre;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.nombre = Nombre; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.nombre = Nombre;
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.nombre = Nombre;
        this.state.data[I].idLocalidad = this.localidades[Nombre];
        break;
      case "idMovimientoTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idMovimientoTipo = Campo;
        break;
      case "idNaviera":
        Campo.nombre = Nombre;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.nombre = Nombre;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.nombre = Nombre; //Campo.id=this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.nombre = Nombre;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.nombre = Nombre; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo;
        break;

      case "po":
        Campo.po = Nombre; //this.state.transportistas[I].id;
        this.state.data[I].po = Nombre;
        break;
      default:
        NotificationManager.warning("No Existe el Catalogo Definido");
    }
  }

  validaArchivo() {
    //console.log("Inicia Validacion de Archivo Naviero");
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        var j = 0;
        this.state.data.map((datos) => {
          var plataforma = { id: "", nombre: "" };
          var cliente = { id: "", nombre: "" };
          var corredor = { id: "", nombre: "" };
          var localidad = { id: "", localidad: "" };
          var movimientoTipo = { id: "", nombre: "" };
          var naviera = { id: "", nombre: "" };
          var operacionTipo = { id: "", nombre: "" };
          var otd = { id: "", nombre: "" };
          var rutasEstatus = { id: "", nombre: "" }; //rutasEstatus
          var transportista = { id: "", nombre: "" };
          var operacionEstatus = { id: "", nombre: "" };
          var po = { po: "", existe: false };
          var objeto = {
            plataforma,
            cliente,
            corredor,
            movimientoTipo,
            naviera,
            operacionTipo,
            otd,
            rutasEstatus,
            transportista,
            operacionEstatus,
            po,
            localidad,
          };

          Object.keys(datos).forEach((num) => {
            switch (
              num //Inicia Switch num
            ) {
              case "idPlataforma":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                } else {
                  plataforma.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                }
                break;
              case "idCliente":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idCliente", datos[num], j, cliente);
                  }
                } else {
                  cliente.nombre = datos[num];
                  {
                    this.getCatalogoId("idCliente", datos[num], j, cliente);
                  }
                }
                break;
              case "idCorredor":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idCorredor", datos[num], j, corredor);
                  }
                } else {
                  corredor.nombre = datos[num];
                  {
                    this.getCatalogoId("idCorredor", datos[num], j, corredor);
                  }
                }
                break;
              case "idLocalidad":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idLocalidad", datos[num], j, localidad);
                  }
                } else {
                  localidad.nombre = datos[num];
                  this.getCatalogoId("idLocalidad", datos[num], j, localidad);
                }
                break;
              case "idMovimientoTipo":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idMovimientoTipo",
                      datos[num],
                      j,
                      movimientoTipo
                    );
                  }
                } else {
                  movimientoTipo.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idMovimientoTipo",
                      datos[num],
                      j,
                      movimientoTipo
                    );
                  }
                }
                break;
              case "idNaviera":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                } else {
                  naviera.nombre = datos[num];
                  {
                    this.getCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                }
                break;
              case "idOperacionTipo":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                } else {
                  operacionTipo.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                }
                break;
              case "idOtd":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idOtd", datos[num], j, otd);
                  }
                } else {
                  otd.nombre = datos[num];
                  {
                    this.getCatalogoId("idOtd", datos[num], j, otd);
                  }
                }
                break;
              case "idRuta":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                } else {
                  rutasEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                }
                break;
              case "idTransportista":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idTransportista",
                      datos[num],
                      j,
                      transportista
                    );
                  }
                } else {
                  transportista.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idTransportista",
                      datos[num],
                      j,
                      transportista
                    );
                  }
                }
                break;
              case "idOperacionEstatus":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                } else {
                  operacionEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                }
                break;
              case "citaProgramada":
                var citaProgramada = "";
                citaProgramada = datos[num]; //this.state.data[j].citaProgramada;
                {
                  this.valCatalogoId(
                    "citaProgramada",
                    datos[num],
                    j,
                    citaProgramada
                  );
                }
                if (
                  citaProgramada === 0 ||
                  citaProgramada === null ||
                  citaProgramada === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaProgramada:" +
                    citaProgramada +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La citaProgramada:" + citaProgramada + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaProgramada:" +
                        citaProgramada +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "citaColocacionGps":
                var citaColocacionGps = "";
                citaColocacionGps = datos[num];
                {
                  this.valCatalogoId(
                    "citaColocacionGps",
                    datos[num],
                    j,
                    citaColocacionGps
                  );
                }
                if (
                  citaColocacionGps === 0 ||
                  citaColocacionGps === null ||
                  citaColocacionGps === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaColocacionGps:" +
                    citaColocacionGps +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La citaColocacionGps:" + citaColocacionGps + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaColocacionGps:" +
                        citaColocacionGps +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio":
                var arriboTerminalVacio = "";
                arriboTerminalVacio = datos[num]; //this.state.data[j].arriboTerminalVacio;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio",
                    datos[num],
                    j,
                    arriboTerminalVacio
                  );
                }
                if (
                  arriboTerminalVacio === 0 ||
                  arriboTerminalVacio === null ||
                  arriboTerminalVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio:" +
                    arriboTerminalVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboTerminalVacio:" + arriboTerminalVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio:" +
                        arriboTerminalVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "salidaTerminalLleno":
                var salidaTerminalLleno = "";
                salidaTerminalLleno = datos[num]; //this.state.data[j].salidaTerminalLleno;
                {
                  this.valCatalogoId(
                    "salidaTerminalLleno",
                    datos[num],
                    j,
                    salidaTerminalLleno
                  );
                }
                if (
                  salidaTerminalLleno === 0 ||
                  salidaTerminalLleno === null ||
                  salidaTerminalLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La salidaTerminalLleno:" +
                    salidaTerminalLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La salidaTerminalLleno:" + salidaTerminalLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaTerminalLleno:" +
                        salidaTerminalLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboClienteLleno":
                var arriboClienteLleno = "";
                arriboClienteLleno = datos[num]; //this.state.data[j].arriboClienteLleno;
                {
                  this.valCatalogoId(
                    "arriboClienteLleno",
                    datos[num],
                    j,
                    arriboClienteLleno
                  );
                }
                if (
                  arriboClienteLleno === 0 ||
                  arriboClienteLleno === null ||
                  arriboClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La arriboClienteLleno:" +
                    arriboClienteLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboClienteLleno:" + arriboClienteLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteLleno:" +
                        arriboClienteLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "salidaClienteVacio":
                var salidaClienteVacio = "";
                salidaClienteVacio = datos[num]; //this.state.data[j].salidaClienteVacio;
                {
                  this.valCatalogoId(
                    "salidaClienteVacio",
                    datos[num],
                    j,
                    salidaClienteVacio
                  );
                }
                if (
                  salidaClienteVacio === 0 ||
                  salidaClienteVacio === null ||
                  salidaClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La salidaClienteVacio:" +
                    salidaClienteVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La salidaClienteVacio:" + salidaClienteVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteVacio:" +
                        salidaClienteVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio2":
                var arriboTerminalVacio2 = "";
                arriboTerminalVacio2 = datos[num]; //this.state.data[j].arriboTerminalVacio2;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio2",
                    datos[num],
                    j,
                    arriboTerminalVacio2
                  );
                }
                if (
                  arriboTerminalVacio2 === 0 ||
                  arriboTerminalVacio2 === null ||
                  arriboTerminalVacio2 === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio2:" +
                    arriboTerminalVacio2 +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboTerminalVacio2:" + arriboTerminalVacio2 + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio2:" +
                        arriboTerminalVacio2 +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "retiroContenedor":
                var retiroContenedor = "";
                retiroContenedor = datos[num]; //this.state.data[j].retiroContenedor;
                {
                  this.valCatalogoId(
                    "retiroContenedor",
                    datos[num],
                    j,
                    retiroContenedor
                  );
                }
                if (
                  retiroContenedor === 0 ||
                  retiroContenedor === null ||
                  retiroContenedor === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La retiroContenedor:" +
                    retiroContenedor +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La retiroContenedor:" + retiroContenedor + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La retiroContenedor:" +
                        retiroContenedor +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboClienteVacio":
                var arriboClienteVacio = "";
                arriboClienteVacio = datos[num]; //this.state.data[j].arriboClienteVacio;
                {
                  this.valCatalogoId(
                    "arriboClienteVacio",
                    datos[num],
                    j,
                    arriboClienteVacio
                  );
                }
                if (
                  arriboClienteVacio === 0 ||
                  arriboClienteVacio === null ||
                  arriboClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La arriboClienteVacio:" +
                    arriboClienteVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboClienteVacio:" + arriboClienteVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteVacio:" +
                        arriboClienteVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "salidaClienteLleno":
                var salidaClienteLleno = "";
                salidaClienteLleno = datos[num]; //this.state.data[j].salidaClienteLleno;
                {
                  this.valCatalogoId(
                    "salidaClienteLleno",
                    datos[num],
                    j,
                    salidaClienteLleno
                  );
                }
                if (
                  salidaClienteLleno === 0 ||
                  salidaClienteLleno === null ||
                  salidaClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La salidaClienteLleno:" +
                    salidaClienteLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La salidaClienteLleno:" + salidaClienteLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteLleno:" +
                        salidaClienteLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalCargado":
                var arriboTerminalCargado = "";
                arriboTerminalCargado = datos[num]; //this.state.data[j].arriboTerminalCargado;
                {
                  this.valCatalogoId(
                    "arriboTerminalCargado",
                    datos[num],
                    j,
                    arriboTerminalCargado
                  );
                }
                if (
                  arriboTerminalCargado === 0 ||
                  arriboTerminalCargado === null ||
                  arriboTerminalCargado === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La arriboTerminalCargado:" +
                    arriboTerminalCargado +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboTerminalCargado:" + arriboTerminalCargado + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalCargado:" +
                        arriboTerminalCargado +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "wti6h":
                var wti6h = "";
                wti6h = datos[num]; //this.state.data[j].retiroContenedor;
                {
                  this.valCatalogoId("wti6h", datos[num], j, wti6h);
                }
                if (wti6h === 0 || wti6h === null || wti6h === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "El wti6h:" +
                    wti6h +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("El wti6h:" + wti6h + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El wti6h:" +
                        wti6h +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "ontime":
                var ontime = "";
                ontime = datos[num]; //this.state.data[j].ontime;
                {
                  this.valCatalogoId("ontime", datos[num], j, ontime);
                }
                if (ontime === 0 || ontime === null || ontime === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "El ontime:" +
                    ontime +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("El ontime:" + ontime + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El ontime:" +
                        ontime +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "po":
                //po.po=datos[num];
                {
                  this.valCatalogoId("po", datos[num], j, po);
                }
                //{ this.valYaExiste("movimientos", datos[num], j, po) }
                break;
              default:
                break;
            } //Fin Switch num
          });
          this.valObjeto(objeto, j);

          //Validacion de Campos No Null
          var temporal = "";
          //Validacion del Campo corredor
          //temporal=this.state.data[j].idPlataforma;
          //if ((temporal==="")||(temporal===null)||(temporal===undefined)){
          //	terrores=terrores+1;
          //	FilaErr[j]="Error en la Fila " + (j);
          //	arrayErr[terrores]="La Plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j);
          //NotificationManager.warning("La Plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1) );
          //	Errores.push( <div>{"La Plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j) }</div> );
          //}
          //Fin Validacion del Campo corredor

          j = j + 1;
        });
        //  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
        //terrores=terrores+1;
        Errores.push(<div id={j}>{"Archivo Validado!"}</div>);
        break; //Fin Case Movimientos
      default:
        NotificationManager.warning =
          "No esta Defina una Carga Masiva para este Catalogo";
        break;
    }
    //this.setState({validado:true });
    //console.log(this.state.data);
    //console.log("Fin Validacion de Archivo: "+ terrores);
  }

  cargarDatos = (carga, errores) => {
    console.log("Cargar Datos Correctos e:" + errores);
    let w = 0;
    let errResponse = [];
    for (let i = 0; i < carga.length; i++) {
      let nDe = 0;
      let nEn = 0;
      if (errores == 0) {
        Crud_Catalogos(
          this.props.catalogo,
          this.catalogo,
          "post",
          "",
          carga[i],
          "",
          "",
          "",
          []
        )
          .then((returnVal) => {
            nEn = i + 1;
            nDe = carga.length;
            if (returnVal) {
              //console.log("914 Carga Masiva en Catalogo: " + this.props.catalogo," Id:"+returnVal.id + " i:" + nEn + " de " + nDe + " datos Masivos ");
              NotificationManager.success(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                " Id:" +
                  returnVal.id +
                  " i:" +
                  nEn +
                  " de " +
                  nDe +
                  " datos Masivos "
              );
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Nuevo Movimiento Masivo Naviero Post id=" + returnVal.id;
              //console.log("928 this.usuariosLogObject");
              //console.log(this.usuariosLogObject);
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
            }
            if (i === carga.length - 1) {
              this.props.crud(
                "get",
                [],
                "",
                [],
                "navieraMovimientos/usuario/" + user.id
              );
              //console.log("Carga Finalizada! ");
              NotificationManager.information(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Carga con " + nDe + " Registros Finalizada! "
              );
              myModalN("none");
            }
          })
          .catch((err) => {
            if (err > 0) {
              //console.log("944 sin Errores=0 Err: ");
              //console.log(err);
              nEn = i + 1;
              nDe = carga.length;
              //console.log("Carga Masiva en Catalogo: " + this.props.catalogo, "Error en la Fila: " + nEn + " de " + nDe + " Favor de Verificar Sus Datos!");
              NotificationManager.error(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Error en la Fila: " +
                  nEn +
                  " de " +
                  nDe +
                  " Favor de Verificar Sus Datos!"
              );
            }
            if (i === carga.length - 1) {
              this.props.crud(
                "get",
                [],
                "",
                [],
                "navieraMovimientos/usuario/" + user.id
              );
              NotificationManager.success(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Finalizada! "
              );
              myModalN("none");
            }
            errResponse.push({ datos: carga[i] });
            if (err.response) {
              console.log(err);
            } else {
              console.log(
                "Error al Cargar el Catalogo:" +
                  this.state.catalogo +
                  " verifique el Nombre del Catalogo"
              );
            }
          });
      } else {
        if (FilaErr[i] != "" || FilaErr[i] != null || FilaErr[i] != undefined) {
          //console.log("No se Ejecuto la Carga de la Fila:" + i);
        } else {
          Crud_Catalogos(
            this.props.catalogo,
            "",
            "post",
            "",
            carga[i],
            "",
            "",
            "",
            []
          )
            .then((returnVal) => {
              if (returnVal) {
                //console.log("914 Carga Masiva en Catalogo: " + this.props.catalogo," Id:"+returnVal.id + " i:" + nEn + " de " + nDe + " datos Masivos ");
                NotificationManager.success(
                  "Carga Masiva en Catalogo: " + this.props.catalogo,
                  "(TErr) Id:" +
                    returnVal.id +
                    " i:" +
                    nEn +
                    " de " +
                    nDe +
                    " datos Masivos "
                );
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  "Nuevo Movimiento Masivo, id=" +
                  returnVal.id +
                  "(" +
                  returnVal.booking +
                  ")";
                //console.log("928 this.usuariosLogObject");
                //console.log(this.usuariosLogObject);
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  this.hiddenmodal,
                  ""
                );
              }
              if (i === carga.length - 1) {
                //console.log("934 Errores>0 returnVal: ");
                //console.log(returnVal);
                this.props.crud(
                  "get",
                  [],
                  "",
                  [],
                  "navieraMovimientos/usuario/" + user.id
                );
                //console.log("938 Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
                NotificationManager.success(
                  "Carga Masiva en Catalogo: " + this.props.catalogo,
                  "Finalizada! "
                );
                myModalN("none");
              }
            })
            .catch((err) => {
              if (err) {
                //console.log("987 Errores>0 Err: ");
                //console.log(err);
                nEn = i + 1;
                nDe = carga.length;
                NotificationManager.danger(
                  "Carga Masiva en Catalogo: " + this.props.catalogo,
                  "Error en la Fila: " +
                    nEn +
                    " de " +
                    nDe +
                    " Favor de Verificar Sus Datos!"
                );
              }
              if (i === carga.length - 1) {
                //console.log("995 Errores>0 Err: ");
                //console.log(err);
                this.props.crud(
                  "get",
                  [],
                  "",
                  [],
                  "navieraMovimientos/usuario/" + user.id
                );
                //console.log("998 Carga Masiva en Catalogo: " + this.props.catalogo, "(TErr) Finalizada! ");
                NotificationManager.success(
                  "Carga Masiva en Catalogo: " + this.props.catalogo,
                  "(TErr) Finalizada! "
                );
                myModalN("none");
              }
              errResponse.push({ datos: carga[i] });
              if (err.response) {
                console.log(err);
              } else {
                console.log(
                  "Error al Cargar el Catalogo:" +
                    this.state.catalogo +
                    " verifique el Nombre del Catalogo"
                );
              }
            });
        }
      }
    }
  };
  onImportExcel = (file) => {
    //this.state.data= [];
    const { files } = file.target;
    //console.log("fileName");
    //console.log(files[0].name);
    Cuadros = [];
    TabTemp = [];
    TabPanelTemp = [];

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        for (const sheet in workbook.Sheets) {
          //console.log("996 Hoja: " + sheet);
          switch (sheet) {
            case "otd":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataOtd: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>otd</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataOtd}
                    />
                  </TabPanel>
                );
              }
              break;
            case "navieras":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataNavieras: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>navieras</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataNavieras}
                    />
                  </TabPanel>
                );
              }
              break;
            case "plataformas":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataPlataformas: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>plataformas</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataPlataformas}
                    />
                  </TabPanel>
                );
              }
              break;
            case "operacionesTipo":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataOperacionesTipo: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>operacionesTipo</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataOperacionesTipo}
                    />
                  </TabPanel>
                );
              }
              break;
            case "operacionesEstatus":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataOperacionesTipo: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>dataOperacionesEstatus</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataOperacionesEstatus}
                    />
                  </TabPanel>
                );
              }
              break;
            case "rutasEstatus":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataRuta: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>rutasEstatus</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataRuta}
                    />
                  </TabPanel>
                );
              }
              break;
            case "movimientosTipo":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataMovimientoTipo: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>movimientosTipo</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataMovimientoTipo}
                    />
                  </TabPanel>
                );
              }
              break;
            case "transportistas":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataTransportistas: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>transportistas</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataTransportistas}
                    />
                  </TabPanel>
                );
              }
              break;
            case "corredores":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataCorredores: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>corredores</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataCorredores}
                    />
                  </TabPanel>
                );
              }
              break;
            case "cliente":
              //console.log("Clientes");
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataCliente: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>cliente</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"cliente"}
                      contenido={this.state.dataCliente}
                    />
                  </TabPanel>
                );
              }
              break;
            case "contenedorTipo":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  dataContenedorTipo: XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet]
                  ),
                });
                TabTemp.push(<Tab>contenedorTipo</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"otd"}
                      contenido={this.state.dataContenedorTipo}
                    />
                  </TabPanel>
                );
              }
              break;
            case "navieraMovimientos":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"navieraMovimientos"}
                      contenido={this.state.data}
                    />
                  </TabPanel>
                );
              }
              {
                this.validaArchivo();
              }
              break;
            case "datos":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"navieraMovimientos"}
                      contenido={this.state.data}
                    />
                  </TabPanel>
                );
              }
              {
                this.validaArchivo();
              }
              break;

            default:
              console.log("Hoja No Válida!");
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        }
        Cuadros.push(
          <Tabs forceRenderTabPanel={true} className={"div1"}>
            <TabList>{TabTemp}</TabList>
            {TabPanelTemp}
          </Tabs>
        );

        this.setState({ validado: true });
        console.log("Carga de Archivo Exitosa！");
        id = 0;
        terrores = 0;
        sinError = 0;
      } catch (e) {
        console.log("Error! " + e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
    myModalN("block");
    document.getElementById("cerrar").focus();
  };

  render() {
    return (
      <div className="excelN" id="excelN">
        <a href="#miModalN">
          <button className="btn btn-primary">Importar</button>
        </a>
        <input
          type="file"
          id="file"
          className="file"
          accept=".xlsx, .xls"
          onChange={this.onImportExcel}
        />

        <div id="xlsModalN" className="xlsModalN">
          <div className="modal-contenidoN">
            <center>
              <h2>Confirme su Carga</h2>
            </center>
            <div className="container-350">
              <div className="scroll-col col-sm-12">
                {this.state.validado === false ? (
                  <div>
                    <br></br>Verificando Errores ..!!{" "}
                    <Dimmer active={true} loader></Dimmer> por favor Espere!
                  </div>
                ) : terrores == 0 ? (
                  <div>{Cuadros}</div>
                ) : (
                  Errores
                )}
              </div>
            </div>

            <br></br>
            <center>
              <button
                id="cerrar"
                className="azul"
                onClick={() => myModalN("none")}
              >
                Cerrar y Corregir Archivo de Excel
              </button>
              <spam> </spam>
              <button
                id="cargar"
                className="azul"
                onClick={() => this.cargarDatos(this.state.data, terrores)}
              >
                Cargar Datos Correctos
              </button>
            </center>
          </div>
        </div>
      </div>
    );
  }
}
export default ImportarN;
