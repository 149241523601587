//React
import React, { Fragment, useEffect, useState } from 'react';
//Componentes externos
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  SortingState,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table';
import { Button, Form, Table } from 'react-bootstrap';
import {
  faArrowUp,
  faArrowDown,
  faSync,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
//Componentes
import { CustomTableExportModal } from '../CustomTableExportModal/CustomTableExportModal';
import ModalLay from '../ModalLay/ModalLay';
//Estilos
import classes from './CustomTable.module.css';

/**
 * CustomTable Component
 * @description: Dibuja una tabla para paginarla, ordenarla y filtrarla.
 * @date 26/05/2023.
 * @param Props Recibe data (los datos de la tabla) y columns que es el formato de las columnas.
 * @returns JSX de la tabla.
 */
const CustomTable = ({
  title,
  data,
  columns,
  expandable,
  renderSubComponent,
  refetch,
}: {
  title: string,
  data: any[] | null | undefined;
  columns: ColumnDef<any>[];
  expandable?: (row: Row<any>) => boolean;
  renderSubComponent?: (props: { row: Row<any> }) => React.ReactElement;
  refetch?: () => void;
}) => {
  /* ----------------------------- useState's -------------------------------*/
  const [sorting, setSorting] = useState<SortingState>([]);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  /* --------------------------- useEffect's --------------------------------*/
  //Se encarga de verificar si se está volviendo a ver la pestaña
  useEffect(() => {
    updateLastUpdated();
  }, [data]);
  /* ------------------------------ Funciones -------------------------------*/
  //Función para actualizar la fecha de la última actualización
  const updateLastUpdated = () => {
    setLastUpdated(new Date());
  };
  /* ----------------------------- Hooks ------------------------------------*/
  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
    },
    // Pipeline
    getRowCanExpand: expandable,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
  });
  /* -------------------------------- return --------------------------------*/
  //Return princiapl
  return (
    <div className="card shadow-2-strong">
      <div>
        <h2 className={classes.subtitulo}>{title}</h2>
      </div>
      <div className={classes.containerTable}>
        <fieldset className="btn-group" role="group" aria-label="Ejemplo2">
          <label className="transparent-input thumbnail">
            <Button
              title="Última fecha de actualización"
              className={'btn btn-light mx-1'}
            >
              Última actualización:{' '}
              {lastUpdated ? lastUpdated.toLocaleString() : 'N/A'}
            </Button>
          </label>
          <label className="transparent-input thumbnail">
            <Button
              title="Recargar información"
              className={'btn btn-dark mx-1'}
              onClick={() => {
                if (refetch) refetch();
              }}
            >
              <FontAwesomeIcon icon={faSync} /> Recargar
            </Button>
          </label>
          <ModalLay
            tamanio={1200}
            title="Exportar información"
            formulario={CustomTableExportModal}
            name={
              <Button
                title="Exportar información a excel"
                className={'btn btn-success mx-1'}
              >
                <FontAwesomeIcon icon={faFileExcel} /> Exportar excel
              </Button>
            }
            datosForm={{
              data: table.getPrePaginationRowModel().rows,
              columns: table.getAllColumns(),
              title: title
            }}
          />
        </fieldset>
      </div>
      <div className="table-responsive">
        <Table hover className="rounded rounded-3 overflow-hidden">
          {/* Headers */}
          <thead className={classes.cabecera}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.alignMiddle}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={
                        header.id !== 'expander' ? classes.columna : undefined
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          <button
                            type="button"
                            className={`btn btn-link ${classes.titulo}`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <FontAwesomeIcon icon={faArrowUp} />,
                                desc: <FontAwesomeIcon icon={faArrowDown} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </span>
                          </button>
                          {/**Inputs para el filtrado de las columnas  */}
                          <div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Form.Control
                                  type="text"
                                  value={
                                    (header.column.getFilterValue() ??
                                      '') as string
                                  }
                                  onChange={e => header.column.setFilterValue(e.target.value)}
                                  placeholder={`Buscar...`}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {/* Cuerpo */}
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <tr key={row.id}>
                    {/** Renderiza la info de cada renglon */}
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={`${row.id} ${cell.id}`}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent ? renderSubComponent({ row }) : ''}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>

        {/** Coloca los inputs de la paginacion */}
        <div className="d-flex flex-row justify-content-around align-items-center">
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              className="btn btn-primary"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </Button>
          </div>

          <div className="d-flex flex-row align-items-center">
            <p>
              Página {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </p>
          </div>

          <div className="d-flex flex-row align-items-center">
            <span className="me-2">Ir a la página:</span>
            <Form.Control
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="w-25"
            />
          </div>
          <div className="d-flex flex-row align-items-center">
            <Form.Select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="mt-2 text-center">
          {table.getPrePaginationRowModel().rows.length} registros
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
