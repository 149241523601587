import React, { Component,useRef } from "react";
import * as XLSX from "xlsx";
import "./excel.css";
import "./modal.css";
import { Crud_Catalogos, Crud_error } from "../Crud_Catalogos";
import { Dimmer } from "tabler-react";
import { NotificationManager } from "react-notifications";
import { Tab, Tabs, TabList, TabPanel } from "./tabs-generic/index";
import TablaCatalogo from "./tabla-generic/index";
import Modal from 'react-modal';
import { LoadingScreen } from "../../components/LoadingScreen";
import { C } from "react-native-jssip";
import { valueToPercent } from "@mui/base";
import { Toast } from "react-bootstrap";
import { object } from "prop-types";

var id = 0;
var terrores = 0;
let sinError = 0;
var dia = 0;
var mes = 0;
var anio = 0;
var hora = 0;
var minuto = 0;
var segundo = 0;

var fechas = 0;
var horas = 0;
var arrayErr = [];
let Errores = [];
let FilaErr = [];
let Cuadros = [];
let TabTemp = [];
let TabPanelTemp = [];

const fdatetime = "yyyy-mm-dd HH:mm:ss";
const fdate = "yyyy-mm-dd";
const fhora = "HH:mm:ss";
const nobis = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const sbiss = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


 
function bisiesto(year) {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}


function serieMes(numero, año) {
  var cuenta = 0;
  var siguiente = 0;
  var index = 0;
  cuenta = numero;
  do {
    if (bisiesto(año)) {
      siguiente = sbiss[index + 1];
      cuenta = cuenta - sbiss[index];
      dia = cuenta;
    } else {
      siguiente = nobis[index + 1];
      cuenta = cuenta - nobis[index];
      dia = cuenta;
    }
    index = index + 1;
  } while (cuenta > siguiente);
  mes = index + 1;
  dia = cuenta - 1;
}
function serieAnio(valor) {
  var año = 1900;
  var sumadias = 0;
  var residuo = 0;
  do {
    if (bisiesto(año)) {
      año = año + 1;
      sumadias = sumadias + 366;
    } else {
      año = año + 1;
      sumadias = sumadias + 365;
    }
  } while (sumadias + 365 < valor);
  residuo = valor - sumadias;
  anio = año;
  serieMes(residuo, año);
}
function serieHoras(valor) {
  var temporal = 0;
  var residuo = 0;
  temporal = valor;

  temporal = temporal * 24;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  hora = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  minuto = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  segundo = parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarNJson extends Component {
  constructor(props) {
    super(props);
    this.state = {

      countData:0,
      isloading:false,
      modalIsOpen: false,
      catalogo: this.props.catalogo | "",
      movsClientesPendientes :[],
      movsTransportPendientes : [],
      movsDuplicados:[],

      //Catalogo Funcionalidades
      bloque: this.props.bloque | [],

      //Catalogo Funcionalidades
      categorias: this.props.categorias | [],

      //Tabla Movimientos
      idPlataforma: this.props.idPlataforma | [],
      idCliente: this.props.idCliente | [],
      idCorredor: this.props.idCorredor | [],
      idLocalidad: this.props.idLocalidad | [],
      idMovimientoTipo: this.props.idMovimientoTipo | [],
      idNaviera: this.props.idNaviera | [],
      idOperacionTipo: this.props.idOperacionTipo | [],
      idOtd: this.props.idOtd | [],
      idRuta: this.props.idRuta | [],
      idTransportista: this.props.idTransportista | [],
      operacionesEstatus: this.props.operacionesEstatus | [],

      //movimientos:this.props.movimientos							|[],
      //privilegios:this.props.privilegios							|[],

      //Variables
      accion: this.props.accion,
      finalCharge:true,
      validado: this.props.validado | false,

      //Variables de Datos
      dataOld:this.props.data | [],
      data: this.props.data | [],
      dataOtd: this.props.dataOtd | [],
      dataNavieras: this.props.dataNavieras | [],
      dataPlataformas: this.props.dataPlataformas | [],
      dataOperacionesTipo: this.props.dataOperacionesTipo | [],
      dataOperacionesEstatus: this.props.dataOperacionesEstatus | [],
      dataRuta: this.props.dataRuta | [],
      dataMovimientoTipo: this.props.dataMovimientoTipo | [],
      dataTransportistas: this.props.dataTransportistas | [],
      dataCorredores: this.props.dataCorredores | [],
      dataCliente: this.props.dataCliente | [],
      dataContenedorTipo: this.props.dataContenedorTipo | [],
      dataNavieraMovimientos: this.props.dataNavieraMovimientos | [],
    };
    this.cita = 0;
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      plataformaTipo: 1,
    };
    this.contador = 0;    
    this.localidades = {};
    this.localidadesMaersk = {};
    this.transportistasMaersk = {};
    this.openModal = this.openModal.bind(this);
    //this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    this.myModalN("none");
    
  }

  componentDidUpdate(){

  
    
  }
  componentWillMount() {
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        Crud_Catalogos("localidades", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            let localidadesTemp = {};
            returnVal.map((l, index) => {
              localidadesTemp[l.id] = l;
              localidadesTemp[l.nombre] = l;
            });
            this.localidades = localidadesTemp;
          })
          .catch((err) => {});

          Crud_Catalogos("localidades/maersk", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            let localidadesMaerskTemp = {};
            // returnVal.map((l, index) => {
            //   localidadesMaerskTemp[l.id] = l;
            //   localidadesMaerskTemp[l.nombreMaersk] = l;
            //   localidadesMaerskTemp[l.nombreOne] = l;
            // });
            this.localidadesMaersk = returnVal;
          })
          .catch((err) => {});

          Crud_Catalogos("transportistas/maersk", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            // let transportistasMaerskTemp = {};
            // returnVal.map((l, index) => {
            //   transportistasMaerskTemp[l.id] = l;
            //   transportistasMaerskTemp[l.nombreMaersk] = l;
            //   transportistasMaerskTemp[l.nombreOne] = l;
            // });
            this.transportistasMaersk = returnVal;
          })
          .catch((err) => {});
    
        break;
      default:
        //console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }

    if(this.state.validado){
      this.setState({isloading:false});
    }

   
  }


  //Inicio Tabla Movimientos
  cargaIdOperacionesEstatus() {
    Crud_Catalogos("operacionesEstatus", "", "get", "", [], "", "", "", [])
      .then((returnVal) => {
        this.setState({ operacionesEstatus: returnVal });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          console.log(
            "Error al Cargar el Catalogo:" +
              this.state.catalogo +
              " verifique el Nombre del Catalogo"
          );
        }
      });
  }
  cargaMovimientos() {}
  //Fin Tabla Movimientos
  valObjeto(ObjValidar, j) {
  
    console.log("enviar",j)
    //{"plataforma":{"id": 2,"nombre":null},"cliente":{"id": 12,"nombre":null},"corredor":{"id": 70,"nombre":null},"movimientoTipo":{"id":1,"nombre":null},"naviera":{"id":8,"nombre":null},"operacionTipo":{"id": 3,"nombre":null},"otd":{"id":1,"nombre":null},"rutasEstatus":{"id":2,"nombre":null},"transportista":{"id":10,"nombre":null},"operacionesEstatus":{"id": 1,"nombre":null} }
    //Crud_Catalogos("validacionDatos/terrestre",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
    Crud_Catalogos(
      "validacionDatos/naviera",
      "",
      "post",
      "",
      ObjValidar,
      "",
      "",
      "",
      [],
      "",
      "",
      ""
    )
      .then((returnVal) => {
        let total = j+1;

        if(returnVal.po.existe){  

          this.setState(prevState => ({
            movsDuplicados: [...prevState.movsDuplicados, this.state.dataOld[j].WO]
          }))

          console.log("POOOOOOOO Existe" + returnVal.po.po)

          this.state.data[j].id = returnVal.id;

         // return;
           
        
        }
        this.state.data[j].idPlataforma = returnVal.plataforma;
        this.state.data[j].idCliente = returnVal.cliente;
        this.state.data[j].idCorredor = returnVal.corredor;
        this.state.data[j].idMovimientoTipo = returnVal.movimientoTipo;
        this.state.data[j].idNaviera = returnVal.naviera;
        this.state.data[j].idOperacionTipo = returnVal.operacionTipo;
        this.state.data[j].idOtd = returnVal.otd;
        this.state.data[j].idRuta = returnVal.rutasEstatus;
        this.state.data[j].idTransportista = returnVal.transportista;
        this.state.data[j].idOperacionEstatus = returnVal.operacionEstatus;

        this.state.data[j].po = returnVal.po.po;

        

        let tempplataforma = returnVal.plataforma; //this.state.data[j].idPlataforma;
        if (
          tempplataforma.nombre === "" ||
          tempplataforma.nombre === null ||
          tempplataforma.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempplataforma.id === null || tempplataforma.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Plataforma No Existe! en la BD, Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempcliente = returnVal.cliente; //this.state.data[j].idCliente;
        if (
          tempcliente.nombre === "" ||
          tempcliente.nombre === null ||
          tempcliente.nombre === undefined
        ) {

          //console.log(tempcliente)
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Cliente NO Está VINCULADO a la Plataforma -" +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Cliente NO Está VINCULADO a la Plataforma -" +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Cliente NO Está VINCULADO a la Plataforma -" +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }else{

         
            if(tempcliente.nombre.includes("Pendiente")){

              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) El cliente NO Está VINCULADO a la Plataforma " +
                tempcliente.nombre+
                ", Verifique en Excel la Fila: " +
                (j + 1);
              NotificationManager.warning(
                "(N) El cliente NO Está VINCULADO a la Plataforma " +
                  tempplataforma.nombre +
                  ", Verifique en Excel la Fila: " +
                  (j + 1)
              );
              Errores.push(
                <div>
                  {"(N) El cliente NO Está VINCULADO a la Plataforma " +
                    ObjValidar.cliente.nombre +
                    ", Verifique en JSON la Fila con wo: " +
                    (this.state.dataOld[j].WO)}
                </div>
              );

            //   this.setState(prevState => ({
            //     movsClientesPendientes: [...prevState.movsClientesPendientes, this.state.dataOld[j].WO]
            //   }))
               
            }

        }
        if (tempcliente.id === null || tempcliente.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Cliente NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Cliente NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Cliente NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempcorredor = returnVal.corredor; //this.state.data[j].idCorredor;
        if (
          tempcorredor.nombre === "" ||
          tempcorredor.nombre === null ||
          tempcorredor.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Corredor NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en JSON el servicio con WO: " +
            (this.state.dataOld[j].WO);
          NotificationManager.warning(
            "(N) La Corrredor NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en JSON el servicio con WO: " +
              (this.state.dataOld[j].WO)
          );
          Errores.push(
            <div>
              {"(N) La Corredor NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ",Verifique en JSON el servicio con WO:  " +
                (this.state.dataOld[j].WO)}
            </div>
          );
        }

        let localidad ='';
        if(this.state.dataOld[j].Direction == "Export"){
          localidad =  this.state.dataOld[j]['Begin Location Name'];
        }else{
          localidad = this.state.dataOld[j].Region.replace("MEXICO VIA ","");
        }
    
            console.log(this.state.data[j].idLocalidad)
        if (!this.state.data[j].idLocalidad || !this.localidades[this.state.data[j].idLocalidad.nombre] ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) No se han encontrado coincidencias para la localidad " + +
            ", Verifique el Json con wo: " +
            (this.state.dataOld[j].WO);
          NotificationManager.warning(
            "(N) No se han encontrado coincidencias para la localidad " + localidad+
              ", Verifique en el Json con wo:: " +
              (this.state.dataOld[j].WO)
          );
          Errores.push(
            <div>
              {"(N) No se han encontrado coincidencias para la localidad " +localidad+
                ", Verifique en el Json con wo: " +
                (this.state.dataOld[j].WO)}
            </div>
          );
        }

        if (tempcorredor.id === null || tempcorredor.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Corredor NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en JSON la Fila con wo: " +
            (this.state.dataOld[j].WO);
          NotificationManager.warning(
            "(I) El Corredor NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en JSON la Fila con wo: " +
              (this.state.dataOld[j].WO)
          );
          Errores.push(
            <div>
              {"(I) El Corredor "+tempcorredor.nombre+ " NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (this.state.dataOld[j].WO)}
            </div>
          );
        }

        let tempmovimientotipo = returnVal.movimientoTipo; //this.state.data[j].idMovimientoTipo;
        if (
          tempmovimientotipo.nombre === "" ||
          tempmovimientotipo.nombre === null ||
          tempmovimientotipo.nombre === undefined
        ) {
          
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempmovimientotipo.id === null || tempmovimientotipo.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempnaviera = returnVal.naviera; //this.state.data[j].idNaviera;
        if (
          tempnaviera.nombre === "" ||
          tempnaviera.nombre === null ||
          tempnaviera.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Naviera NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Naviera NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempnaviera.id === null || tempnaviera.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) La Naviera NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) La Naviera NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempoperaciontipo = returnVal.operacionTipo; //this.state.data[j].idOperacionTipo;
        if (
          tempoperaciontipo.nombre === "" ||
          tempoperaciontipo.nombre === null ||
          tempoperaciontipo.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          // NotificationManager.warning(
          //   "(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
          //     tempplataforma.nombre +
          //     ", Verifique en Excel la Fila: " +
          //     (j + 1)
          // );
          Errores.push(
            <div>
              {"(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (this.state.dataOld[j].WO)}
            </div>
          );
        }
        if (tempoperaciontipo.id === null || tempoperaciontipo.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en JSON la Fila con wo: " +
            (this.state.dataOld[j].WO);

          NotificationManager.warning(
            "(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en JSON la Fila con wo: " +
              (this.state.dataOld[j].WO)
          );
          console.log(j)
          Errores.push(
            <div>
              {"(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (this.state.dataOld[j].WO)}
            </div>
          );

          console.log(Errores)
        }

        let tempotd = returnVal.otd; //this.state.data[j].idOtd;
        if (
          tempotd.nombre === "" ||
          tempotd.nombre === null ||
          tempotd.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Otd NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Otd NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Otd NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempotd.id === null || tempotd.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El OTD NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El OTD NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El OTD NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let temprutasestatus = returnVal.rutasEstatus; //this.state.data[j].idRuta;
        if (
          temprutasestatus.nombre === "" ||
          temprutasestatus.nombre === null ||
          temprutasestatus.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (temprutasestatus.id === null || temprutasestatus.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let temptransportista = returnVal.transportista; //this.state.data[j].idTransportista;
        if (
          temptransportista.nombre === "" ||
          temptransportista.nombre === null ||
          temptransportista.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }else{


          if(temptransportista.nombre.includes("Pendiente")){

          //   this.setState(prevState => ({
          //     movsTransportPendientes: [...prevState.movsTransportPendientes, this.state.dataOld[j].WO]
          //   }))



          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
            temptransportista.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                ObjValidar.transportista.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (this.state.dataOld[j].WO)}
            </div>
          );
             
          }
        }
        if (temptransportista.id === null || temptransportista.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }

        let tempoperacionEstatus = returnVal.operacionEstatus; //this.state.data[j].idTransportista;
        if (
          tempoperacionEstatus.nombre === "" ||
          tempoperacionEstatus.nombre === null ||
          tempoperacionEstatus.nombre === undefined
        ) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(N) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );
        }
        if (tempoperacionEstatus.id === null || tempoperacionEstatus.id === 0) {
          terrores = terrores + 1;
          FilaErr[j] = "Error en la Fila " + j;
          arrayErr[terrores] =
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
            tempplataforma.nombre +
            ", Verifique en Excel la Fila: " +
            (j + 1);
          NotificationManager.warning(
            "(I) El Transportista NO Está VINCULADO a la Plataforma " +
              tempplataforma.nombre +
              ", Verifique en Excel la Fila: " +
              (j + 1)
          );
          Errores.push(
            <div>
              {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma.nombre +
                ", Verifique en Excel la Fila: " +
                (j + 1)}
            </div>
          );

          
        }
        this.contador = this.contador +1;
        if(this.state.data.length == this.contador ){
                
          this.setState({validado:true})
          this.setState({isloading:false});
          this.myModalN('block')
        }
        
      })
      .catch((err) => {
        console.log("343 Error de validacionDatos: " + err);
      });

      console.log("termino", j)
     
      
  }
  
  valCatalogoId(Catalogo, Id, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.id = Id;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.id = Id; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idLocalidad = Campo;
        break;
      case "Direction":
        Campo.id = Id; //this.state.movimientosTipo[I].id;
        this.state.data[I].movimientosTipo = Campo;
        break;
      case "idNaviera":
        Campo.id = Id; //this.state.navieras[I].id;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.id = Id; //this.state.operacionesTipo[I].id;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.id = Id; //this.state.otd[I].id;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.id = Id; //this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.id = Id; //this.state.transportistas[I].id;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.id = Id; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo; //this.state.operacionesEstatus[k];
        break;

      case "po":
        Campo.po = Id; //this.state.transportistas[I].id;
        this.state.data[I].po = Id;
        break;
      case "citaProgramada":
        this.state.data[I].citaProgramada = Campo;
        break;
      case "citaColocacionGps":
        this.state.data[I].citaColocacionGps = Campo;
        break;
      case "arriboTerminalVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio = Campo;
        break;
      case "salidaTerminalLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaTerminalLleno = Campo;
        break;
      case "arriboClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteLleno = Campo;
        break;
      case "salidaClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteVacio = Campo;
        break;
      case "arriboTerminalVacio2":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio2 = Campo;
        break;
      case "retiroContenedor":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas); //datos[num]= ""+anio+"-"+mes+"-"+dia+""
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].retiroContenedor = Campo;
        break;
      case "arriboClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteVacio = Campo;
        break;
      case "salidaClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteLleno = Campo;
        break;
      case "arriboTerminalCargado":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalCargado = Campo;
        break;

      case "eta":
        //fechas = parseInt(Id, 10);
        //horas = Number.parseFloat(Id)-fechas;
        //serieHoras(horas);
        //Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].eta = Campo;
        break;
      case "delay":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].delay = Campo;
        break;
      case "wti6h":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].wti6h = Campo;
        break;
      case "transit":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].transit = Campo;
        break;
      case "ontime":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].ontime = Campo;
        break;

      default:
        console.log("No Existe el Catalogo Definido");
        break;
    }
  }
  getCatalogoId(Catalogo, Nombre, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.nombre = Nombre;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.nombre = Nombre; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.nombre = Nombre;
        console.log(Nombre)
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.nombre = Nombre;
        console.log(Nombre)
        this.state.data[I].idLocalidad = this.localidades[Nombre];
        break;
      case "idMovimientoTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idMovimientoTipo = Campo;
        break;
      case "idNaviera":
        Campo.nombre = Nombre;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.nombre = Nombre;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.nombre = Nombre; //Campo.id=this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.nombre = Nombre;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.nombre = Nombre; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo;
        break;

      case "po":
        Campo.po = Nombre; //this.state.transportistas[I].id;
        this.state.data[I].po = Nombre;
        break;
      default:
        NotificationManager.warning("No Existe el Catalogo Definido");
    }
  }

  validaArchivo() {

    this.setState({isloading:true});
    //console.log("Inicia Validacion de Archivo Naviero");
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        var j = 0;
        this.state.data.map((datos) => {
          var plataforma = { id: "", nombre: "" };
          var cliente = { id: "", nombre: "" };
          var corredor = { id: "", nombre: "" };
          var localidad = { id: "", localidad: "" };
          var movimientoTipo = { id: "", nombre: "" };
          var naviera = { id: "", nombre: "" };
          var operacionTipo = { id: "", nombre: "" };
          var otd = { id: "", nombre: "" };
          var rutasEstatus = { id: "", nombre: "" }; //rutasEstatus
          var transportista = { id: "", nombre: "" };
          var operacionEstatus = { id: "", nombre: "" };
          var po = { id: "", existe: false };
          var objeto = {
            plataforma,
            cliente,
            corredor,
            movimientoTipo,
            naviera,
            operacionTipo,
            otd,
            rutasEstatus,
            transportista,
            operacionEstatus,
            po,
            localidad,
          };

          datos["idPlataforma"] = datos["Plataforma"] ? datos["Plataforma"] : "MAERSK";
          datos["idOperacionEstatus"] = "SIN GPS";
          datos["idRuta"] = "**UNIDAD PROGRAMADA**";
          datos["idOtd"] = "Sin Código";
          datos["idNaviera"] = datos["Naviera"] ? datos["Naviera"] :  "Maersk Line";
          datos["idOperacionTipo"] = datos["OperationType"] ? datos["OperationType"] :  "FIRST MILE";
          datos["vip"] = true;
          datos["booking"] = datos["BL"].split("-")[0];
          datos["contenedor"] = datos["Container No"];
          datos["contenedorSize"] = datos["Size"];
          datos["po"] = datos["WO"];
          datos["destino"] = datos["End Location Name"];
          datos["Type"] == "HREF" ? datos["contenedorTipo"] = 2 : datos["contenedorTipo"] = 1

          if(datos['Order Deadline']){

            let dt  = datos['Order Deadline'].split(/\-|\s/)
            const tzoffset = (new Date()).getTimezoneOffset() * 60000;
            let dat = new Date(dt.slice(0,3).reverse().join('/')+' '+dt[3]);
                dat = new Date(dat - tzoffset);
            let getTime = dat.getTime() ;

            

            datos["Order Deadline"] =  dat.toISOString().replace('T', ' ');
            datos["Order Deadline"] = datos["Order Deadline"].replace(".000Z","")

            let dia = (24 * 60 * 60 * 1000)
            let res = getTime - dia;

            

            res = new Date(res);
            datos["citaColocacionGps"] =  res.toISOString().replace('T', ' ');
            datos["citaColocacionGps"] = datos["citaColocacionGps"].replace(".000Z","")
            
            //datos['Order Deadline'] = zeroPad(dat.getDate()) +"/"+zeroPad(dat.getMonth())+"/" +zeroPad(dat.getFullYear()) +" " + zeroPad(dat.getHours()) +":" + zeroPad(dat.getMinutes()) + ":00";
            console.log(datos["citaColocacionGps"].replace(".000Z",""))
            console.log(datos["Order Deadline"])
          }

          if(datos["Trucking Line"]){

            let foundTransportista = null;
 
            foundTransportista = this.transportistasMaersk.filter((trans)=> {
              return datos["Trucking Line"].includes(trans.nombreMaersk)
            })
          
            if(foundTransportista.length > 0){
              datos["Trucking Line"] = foundTransportista[0].nombreOne;
            }
            console.log(datos["Trucking Line"])
            
          }
         

          if(datos["Direction"] == "Export"){

            let localidad= '';

            if(datos["Begin Location Name"]){

              datos["Begin Location Name"] = this.localidadesMaersk.filter((localidad)=> {
                return datos["Begin Location Name"].includes(localidad.nombreMaersk)
              })
              localidad = datos["Begin Location Name"][0]?.nombreOne
            }
            datos["idLocalidad"] = localidad;
          }else{
            
             datos["idLocalidad"] = datos["Region"].replace("MEXICO VIA ","");

            let foundLocalidad = this.localidadesMaersk.filter((localidad)=> {
              return datos["idLocalidad"].includes(localidad.nombreMaersk)
            })
            console.log(foundLocalidad)
            datos["idLocalidad"] =foundLocalidad.length > 0 ? foundLocalidad[0].nombreOne : datos["idLocalidad"];
          }


          Object.keys(datos).forEach((num) => {
            
            switch (
              num //Inicia Switch num
            ) {
              case "idPlataforma":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                } else {
                  plataforma.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                }
                break;
              case "Customer Name":
                if (Number.isInteger(datos["Customer Name"])) {
                  {
                    this.valCatalogoId("idCliente", datos["Customer Name"], j, cliente);
                  }
                } else {
                  let nameCustomer = "";
                  if(datos["Customer Name"]){
                    nameCustomer = datos["Customer Name"].split(" ");
                  }
                  
                  cliente.nombre = nameCustomer[0] + " "+ datos["End Location Name"];
                  {
                    this.getCatalogoId("idCliente", cliente.nombre, j, cliente);
                  }
                }
                break;
              case "Region":
                if (Number.isInteger(datos["Region"])) {
                  {
                    this.valCatalogoId("idCorredor", datos["Region"], j, corredor);
                  }
                } else {
                  datos["Region"] = datos["Region"].replace("MEXICO VIA ","")
                 

                  if(datos['Region'] == "TIHL"){
                    datos['Region'] = "TILH"
                  }else

                  if(datos['Region'] && datos['Region'].includes("MEXICO CITY")){
                    console.log("Entro Region")
                    datos['Region'] = "PANTACO"
                  }

                  if(datos['Region'] && datos['Region'].includes("PROGRESO")){
                    console.log("Entro PROGRESO YUCATAN")
                    datos['Region'] = "PROGRESO YUCATAN"
                  }

                  

                  corredor.nombre = datos["Region"];
                  
                  this.getCatalogoId("idCorredor", datos["Region"], j, corredor);
                  

                 
                }
                break;
              case "idLocalidad":
                if (Number.isInteger(datos["idLocalidad"])) {
                  {
                    this.valCatalogoId("idLocalidad", datos["idLocalidad"], j, localidad);
                  }
                } else {
                  localidad.nombre = datos["idLocalidad"];
                  console.log(localidad)
                  this.getCatalogoId("idLocalidad", datos["idLocalidad"], j, localidad);
                }
                break;
              case "Direction":
                if (Number.isInteger(datos["Direction"])) {
                  {
                    this.valCatalogoId(
                      "idMovimientoTipo",
                      datos["Direction"],
                      j,
                      movimientoTipo
                    );
                  }
                } else {
                  if(datos["Direction"] == "Export"){
                    datos["Direction"] = "Exportación";
                  }else{
                    datos["Direction"] = "Importación";
                  }
                  movimientoTipo.nombre = datos["Direction"];
                  {
                    this.getCatalogoId(
                      "idMovimientoTipo",
                      datos["Direction"],
                      j,
                      movimientoTipo
                    );
                  }
                }
                break;
              case "idNaviera":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                } else {
                  naviera.nombre = datos[num];
                  {
                    this.getCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                }
                break;
              case "idOperacionTipo":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                } else {
                  operacionTipo.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                }
                break;
              case "idOtd":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idOtd", datos[num], j, otd);
                  }
                } else {
                  otd.nombre = datos[num];
                  {
                    this.getCatalogoId("idOtd", datos[num], j, otd);
                  }
                }
                break;
              case "idRuta":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                } else {
                  rutasEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                }
                break;
              case "Trucking Line":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idTransportista",
                      datos[num],
                      j,
                      transportista
                    );
                  }
                } else {
                  transportista.nombre = datos["Trucking Line"];
                  {
                    this.getCatalogoId(
                      "idTransportista",
                      datos["Trucking Line"],
                      j,
                      transportista
                    );
                  }
                }
                break;
              case "idOperacionEstatus":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                } else {
                  operacionEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                }
                break;
              case "Order Deadline":
                var citaProgramada = "";
                citaProgramada = datos["Order Deadline"]; 
               
               //this.state.data[j].citaProgramada;
                {
                  this.valCatalogoId(
                    "citaProgramada",
                    datos["Order Deadline"],
                    j,
                    citaProgramada
                  );
                }
                if (
                  citaProgramada === 0 ||
                  citaProgramada === null ||
                  citaProgramada === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaProgramada:" +
                    citaProgramada +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j );
                  //NotificationManager.warning("La citaProgramada:" + citaProgramada + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaProgramada:" +
                        citaProgramada +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "citaColocacionGps":
                var citaColocacionGps = "";
                citaColocacionGps = datos[num];
                {
                  this.valCatalogoId(
                    "citaColocacionGps",
                    datos[num],
                    j,
                    citaColocacionGps
                  );
                }
                if (
                  citaColocacionGps === 0 ||
                  citaColocacionGps === null ||
                  citaColocacionGps === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaColocacionGps:" +
                    citaColocacionGps +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La citaColocacionGps:" + citaColocacionGps + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaColocacionGps:" +
                        citaColocacionGps +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio":
                var arriboTerminalVacio = "";
                arriboTerminalVacio = datos[num]; //this.state.data[j].arriboTerminalVacio;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio",
                    datos[num],
                    j,
                    arriboTerminalVacio
                  );
                }
                if (
                  arriboTerminalVacio === 0 ||
                  arriboTerminalVacio === null ||
                  arriboTerminalVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio:" +
                    arriboTerminalVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboTerminalVacio:" + arriboTerminalVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio:" +
                        arriboTerminalVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "salidaTerminalLleno":
                var salidaTerminalLleno = "";
                salidaTerminalLleno = datos[num]; //this.state.data[j].salidaTerminalLleno;
                {
                  this.valCatalogoId(
                    "salidaTerminalLleno",
                    datos[num],
                    j,
                    salidaTerminalLleno
                  );
                }
                if (
                  salidaTerminalLleno === 0 ||
                  salidaTerminalLleno === null ||
                  salidaTerminalLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaTerminalLleno:" +
                    salidaTerminalLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaTerminalLleno:" + salidaTerminalLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaTerminalLleno:" +
                        salidaTerminalLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboClienteLleno":
                var arriboClienteLleno = "";
                arriboClienteLleno = datos[num]; //this.state.data[j].arriboClienteLleno;
                {
                  this.valCatalogoId(
                    "arriboClienteLleno",
                    datos[num],
                    j,
                    arriboClienteLleno
                  );
                }
                if (
                  arriboClienteLleno === 0 ||
                  arriboClienteLleno === null ||
                  arriboClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboClienteLleno:" +
                    arriboClienteLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboClienteLleno:" + arriboClienteLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteLleno:" +
                        arriboClienteLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "salidaClienteVacio":
                var salidaClienteVacio = "";
                salidaClienteVacio = datos[num]; //this.state.data[j].salidaClienteVacio;
                {
                  this.valCatalogoId(
                    "salidaClienteVacio",
                    datos[num],
                    j,
                    salidaClienteVacio
                  );
                }
                if (
                  salidaClienteVacio === 0 ||
                  salidaClienteVacio === null ||
                  salidaClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaClienteVacio:" +
                    salidaClienteVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaClienteVacio:" + salidaClienteVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteVacio:" +
                        salidaClienteVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio2":
                var arriboTerminalVacio2 = "";
                arriboTerminalVacio2 = datos[num]; //this.state.data[j].arriboTerminalVacio2;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio2",
                    datos[num],
                    j,
                    arriboTerminalVacio2
                  );
                }
                if (
                  arriboTerminalVacio2 === 0 ||
                  arriboTerminalVacio2 === null ||
                  arriboTerminalVacio2 === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio2:" +
                    arriboTerminalVacio2 +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboTerminalVacio2:" + arriboTerminalVacio2 + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio2:" +
                        arriboTerminalVacio2 +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "retiroContenedor":
                var retiroContenedor = "";
                retiroContenedor = datos[num]; //this.state.data[j].retiroContenedor;
                {
                  this.valCatalogoId(
                    "retiroContenedor",
                    datos[num],
                    j,
                    retiroContenedor
                  );
                }
                if (
                  retiroContenedor === 0 ||
                  retiroContenedor === null ||
                  retiroContenedor === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La retiroContenedor:" +
                    retiroContenedor +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La retiroContenedor:" + retiroContenedor + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La retiroContenedor:" +
                        retiroContenedor +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboClienteVacio":
                var arriboClienteVacio = "";
                arriboClienteVacio = datos[num]; //this.state.data[j].arriboClienteVacio;
                {
                  this.valCatalogoId(
                    "arriboClienteVacio",
                    datos[num],
                    j,
                    arriboClienteVacio
                  );
                }
                if (
                  arriboClienteVacio === 0 ||
                  arriboClienteVacio === null ||
                  arriboClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboClienteVacio:" +
                    arriboClienteVacio +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j );
                  //NotificationManager.warning("La arriboClienteVacio:" + arriboClienteVacio + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteVacio:" +
                        arriboClienteVacio +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "salidaClienteLleno":
                var salidaClienteLleno = "";
                salidaClienteLleno = datos[num]; //this.state.data[j].salidaClienteLleno;
                {
                  this.valCatalogoId(
                    "salidaClienteLleno",
                    datos[num],
                    j,
                    salidaClienteLleno
                  );
                }
                if (
                  salidaClienteLleno === 0 ||
                  salidaClienteLleno === null ||
                  salidaClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaClienteLleno:" +
                    salidaClienteLleno +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaClienteLleno:" + salidaClienteLleno + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteLleno:" +
                        salidaClienteLleno +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalCargado":
                var arriboTerminalCargado = "";
                arriboTerminalCargado = datos[num]; //this.state.data[j].arriboTerminalCargado;
                {
                  this.valCatalogoId(
                    "arriboTerminalCargado",
                    datos[num],
                    j,
                    arriboTerminalCargado
                  );
                }
                if (
                  arriboTerminalCargado === 0 ||
                  arriboTerminalCargado === null ||
                  arriboTerminalCargado === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalCargado:" +
                    arriboTerminalCargado +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboTerminalCargado:" + arriboTerminalCargado + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalCargado:" +
                        arriboTerminalCargado +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j )}
                    </div>
                  );
                }
                break;
              case "wti6h":
                var wti6h = "";
                wti6h = datos[num]; //this.state.data[j].retiroContenedor;
                {
                  this.valCatalogoId("wti6h", datos[num], j, wti6h);
                }
                if (wti6h === 0 || wti6h === null || wti6h === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j );
                  arrayErr[terrores] =
                    "El wti6h:" +
                    wti6h +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j );
                  //NotificationManager.warning("El wti6h:" + wti6h + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El wti6h:" +
                        wti6h +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j )}
                    </div>
                  );
                }
                break;
              case "ontime":
                var ontime = "";
                ontime = datos[num]; //this.state.data[j].ontime;
                {
                  this.valCatalogoId("ontime", datos[num], j, ontime);
                }
                if (ontime === 0 || ontime === null || ontime === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j );
                  arrayErr[terrores] =
                    "El ontime:" +
                    ontime +
                    " No Puede ser Null!, Verifique en Excel la Fila: " +
                    (j );
                  //NotificationManager.warning("El ontime:" + ontime + " No Puede ser Null!, Verifique en Excel la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El ontime:" +
                        ontime +
                        " No Puede ser Null!, Verifique en Excel la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "po":
                //po.po=datos[num];
                  this.valCatalogoId("po", datos["po"], j, po);
                break;
              default:
                break;
            } //Fin Switch num
          });

          console.log("antes de enviar" ,j)
         
          this.valObjeto(objeto, j);

          
          j++;


          
        });

        
        //  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
        //terrores=terrores+1;    
      
        Errores.push(<div id={j}>{"Archivo Validado!"}</div>);
        break; //Fin Case Movimientos
      default:
        NotificationManager.warning =
          "No esta Defina una Carga Masiva para este Catalogo";
        break;
    }


    
    this.setState({validado:true });
    //console.log(this.state.data);
    //console.log("Fin Validacion de Archivo: "+ terrores);
  }


  senData = (carga,errores) =>{
    this.myModalN('none')
    this.loader(true)
    this.cargarDatos(carga,errores)
    this.contador = 0;
  }

  cargarDatos = (carga, errores) => {

    
    console.log("Cargar Datos Correctos e:" + errores);
    let w = 0;
    let errResponse = [];
    let cargados= 0;
    let duplicados = 0;
    

    // if(this.state.validado){
      
    //   console.log("Lista de Clientes Movs pendientes")
    //   console.log(this.state.movsClientesPendientes)
    //   console.log("Lista de Transportistas Movs pendientes")
    //   console.log(this.state.movsTransportPendientes)
    //   console.log("Lista de MOvs duplicados")
    //   console.log(this.state.movsDuplicados)
    // }


    for (let i = 0; i < carga.length; i++) {
      let nDe = 0;
      let nEn = 0;
      if (errores == 0) {

        let dup = this.state.movsDuplicados.filter((movDup) => {
          return movDup == carga[i].po; 
        })

        console.log(dup)
        console.log(this.catalogo)

        if(dup.length > 0){
          duplicados++;
          //continue;
        } 

        Crud_Catalogos(
          this.props.catalogo,
          this.catalogo,
          "post",
          "",
          carga[i],
          "",
          "",
          "",
          []
        )
          .then((returnVal) => {
            cargados++; 
            nEn = i + 1;
            nDe = carga.length;
            console.log(returnVal)
            if (returnVal) {
              //console.log("914 Carga Masiva en Catalogo: " + this.props.catalogo," Id:"+returnVal.id + " i:" + nEn + " de " + nDe + " datos Masivos ");
              // NotificationManager.success(
              //   "Carga Masiva en Catalogo: " + this.props.catalogo,
              //   " Id:" +
              //     returnVal.id +
              //     " i:" +
              //     nEn +
              //     " de " +
              //     nDe +
              //     " datos Masivos "
              // );
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Nuevo Movimiento Masivo Naviero Post id=" + returnVal.id;
              //console.log("928 this.usuariosLogObject");
              //console.log(this.usuariosLogObject);
              Crud_Catalogos(
                "usuariosLog",
                this.props.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
           

              // NotificationManager.success(
              //   "Carga Masiva en Catalogos: " + this.props.catalogo,
              //   "Carga con " + nDe + " Registros Finalizada! "
              // );


             
            }

            if (i === carga.length - 1) {
            this.loader(false)
            if(this.state.movsDuplicados.length > 0){
              data+= "Se han encontrador servicios duplicados: \n" + JSON.stringify(this.state.movsDuplicados) +"\n";
            }

            if(this.state.movsClientesPendientes.length > 0){
              data += ("Se encontraron los siguientes servicios con clientes pendientes : \n"+ JSON.stringify(this.state.movsClientesPendientes) + " \n"
              )
            }

            if(this.state.movsTransportPendientes.length > 0 ){
              data+= "Se han encontrador servicios con transportistas pendientes: \n" + JSON.stringify(this.state.movsTransportPendientes) +" \n";
            }
    
            console.log(data)
            this.TextFile(data);
            
            NotificationManager.success(
              "Se han cargado: " + parseInt(nEn)  +" de " + carga.length + " movimientos" +
              " se a descargado un archivo con los servicios pendientes de cliente ,transportista y duplicados",
            "",
            12000
            );

            }
              
               
              
            
          })
          .catch((err) => {
            console.log(err)
            if (err > 0) {
              //console.log("944 sin Errores=0 Err: ");
              //console.log(err);
              nEn = i + 1;
              nDe = carga.length;
              //console.log("Carga Masiva en Catalogo: " + this.props.catalogo, "Error en la Fila: " + nEn + " de " + nDe + " Favor de Verificar Sus Datos!");
              NotificationManager.error(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Error en la Fila: " +
                  nEn +
                  " de " +
                  nDe +
                  " Favor de Verificar Sus Datos!"
              );
            }
            if (i === carga.length - 1) {
              this.props.crud(
                "get",
                [],
                "",
                [],
                "navieraMovimientos/usuario/" + user.id
              );
              NotificationManager.success(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Finalizada! "
              );
              this.myModalN("none");
            }
            errResponse.push({ datos: carga[i] });
            if (err.response) {
              console.log(err);
            } else {
              console.log(
                "Error al Cargar el Catalogo:" +
                  this.state.catalogo +
                  " verifique el Nombre del Catalogo"
              );
            }
          });
      }
    }

    let data = "";

    if(errores != 0){
      NotificationManager.warning(
        "Corriga los errores antes de continuar",
      ""
      );
      Errores = []
      this.loader(false);
      return;
    }

    // if(duplicados > 0 && duplicados == parseInt(carga.length - 1)){
    //   let data = "Se han encontrador servicios duplicados: " + JSON.stringify(this.state.movsDuplicados);
    //   this.TextFile(data);
    //           NotificationManager.success(
    //             "Se han cargado: " + (parseInt(carga.length) - duplicados)  +" de " + carga.length + 
    //             " se a descargado un archivo con los servicios duplicados",
    //           "",
    //           9000
    //           );

    //           this.loader(false)
    // }
   
    
  };


  UploadProgressBar = ({ progress }) => (
    <div className="py-5">
      <div className="w-full bg-gray-200 rounded-full">
        <div
          className={`${
            progress === 0 ?
              'invisible'
              : ''
          } bg-indigo-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`}
          style={{ width: progress + "%" }}>
          {progress}%
        </div>
      </div>
    </div>
  );

  myModalN = (Muestra) => {
    // var x = document.getElementById("xlsModalNJson");
    // x.style.display = Muestra;
    if(Muestra=="block"){
      this.openModal()
    }
    if(Muestra == 'none'){
      console.log(this.refs.fileUpload?.files[0])
     this.closeModal()
    }
  }


  loader = (estado)=>{
    this.setState({isloading:estado});
  }

  openModal = () => {
    //this.props.Fclose();
    this.setState({modalIsOpen: true});
  }
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }
  handleModalCloseRequest = () => {
    this.setState({modalIsOpen: false});
  }

  TextFile = (info) => {
    const element = document.createElement("a");
    const file = new Blob([info], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "errores.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  onImportExcel = (file) => {
    console.log(file)

  

    this.loader(true);

    //this.state.data= [];
    const { files } = file.target;
    
    //console.log("fileName");
    //console.log(files[0].name);
    Cuadros = [];
    TabTemp = [];
    TabPanelTemp = [];

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {

        const { result } = event.target;
        const data  = (JSON.parse(result))
        const dataOld  = (JSON.parse(result))
        const workbook = XLSX.read(result, { type: "binary" });
       
        const sheet = "navieraMovimientos";

          switch (sheet) {
            case "navieraMovimientos":
              if (data.length > 0) {

                this.setState({movsClientesPendientes:[]})
                this.setState({movsTransportPendientes:[]})
                this.setState({movsDuplicados:[]})
                this.setState({
                  data:data,
                });
                this.setState({
                  dataOld:dataOld,
                });
                file.target.value = '';

                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"navieraMovimientos"}
                      contenido={this.state.data}
                    />
                  </TabPanel>
                );
              }
              {
                this.validaArchivo();
              }
              break;
            case "datos":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"navieraMovimientos"}
                      contenido={this.state.data}
                    />
                  </TabPanel>
                );
              }
              {
                this.validaArchivo();
              }
              break;

            default:
              console.log("Hoja No Válida!");
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        
        Cuadros.push(
          <Tabs forceRenderTabPanel={true} className={"div1"}>
            <TabList>{TabTemp}</TabList>
            {TabPanelTemp}
          </Tabs>
        );

        //this.setState({ validado: true });
        
        console.log("Carga de Archivo Exitosa！");
        id = 0;
        terrores = 0;
        sinError = 0;
      } catch (e) {
        NotificationManager.error(
          "Hay un error en el formato del JSON : " + e,
          " ",
          10000
        );
        
        this.loader(false)
        return;
        //console.log("Error! " + e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
    //this.myModalN("block");
   
    document.getElementById("cerrar").focus();
  };

  render() {

    
    return (

      
      <div className="excelN" id="excelN">

      {this.state.isloading ? (
				<LoadingScreen
					loading={this.state.isloading}
				/>
			):null}
        <a href="#miModalN">
          <button className="btn btn-primary">Importar JSON</button>
        </a>
        <input
          type="file"
          id="file"
          className="file"
          accept=".json"
          onChange={this.onImportExcel}
        />
        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div id="xlsModalNJson" className="xlsModalNJson">
          <div className="modal-contenidoNJson">
            <center>
              <h2>Confirme su Carga</h2>
            </center>
            <div className="container-350">
              <div className="scroll-col col-sm-12">
                {this.state.validado === false ? (
                  <div>
                    <br></br>Verificando Errores ..!!{" "}
                    <Dimmer active={true} loader></Dimmer> por favor Espere!
                  </div>
                ) : terrores == 0 ? (
                  <div>{Cuadros}</div>
                ) : (
                  
                  <div className="erroresJson">{Errores}</div>
                  
                )}
              </div>
            </div>

            <br></br>
            <center>
              <button
                id="cerrar"
                className="azul"
                onClick={() => {this.myModalN('none'); Errores = []; this.contador = 0;}}
              >
                Cerrar y Corregir Archivo de Excel
              </button>
              <spam> </spam>
              <button
                id="cargar"
                className="azul"
                onClick={() =>  this.senData(this.state.data, terrores)}
              >
                Cargar Datos Correctos
              </button>
            </center>
          </div>
        </div>
        </Modal>

        
      </div>
      
    );
  }
}
export default ImportarNJson;
